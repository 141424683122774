import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledIconLabelRight = styled.div`
    text-align: left;
    font-weight: bold;
    font-size: 0.7rem;
    padding: 5px;

    display: flex;
    align-items: center;
    justify-content: start;
    position: relative;

    label {
      padding-left: 10px;
    }

    .iconImage{
      width: 40px;
      min-width: 40px;
      height: 40px;
      border-radius: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .alert-div {
      color: red;
      font-size: 20px!important;
      position: absolute;
      top: 15px;
      left: -4px;
    }
`
const IconLabelRight = ({ src, className, labelClassName, classNameDivImage, label, isAlert, ...rest }) => (
  <StyledIconLabelRight className={`${className} icon-label-list-vision`} >
    {isAlert &&
      <div className={`alert-div`}><span>!</span></div>
    }
    <div className={`iconImage ${classNameDivImage}`} style={{ backgroundColor: classNameDivImage }}>
      <img src={`${src}`} {...rest} />
    </div>
    <label className={`${labelClassName}`}>{`${label}`}</label>
  </StyledIconLabelRight>
)

IconLabelRight.defaultProps = {
  className: '',
  labelClassName: '',
  classNameDivImage: '',
  isAlert: false,
}

IconLabelRight.propTypes = {
  src: PropTypes.string.isRequired,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  classNameDivImage: PropTypes.string,
  label: PropTypes.string,
  isAlert: PropTypes.bool,
}

export default IconLabelRight
