import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'components/uikit-adapter'
import Fetch from 'utils/fetch'
import SessionUser from 'utils/user-storage'
import intl from 'react-intl-universal'
import './pedidos.css'
import { Switcher } from 'components/toggle'


const PedidoForm = ({ handleClose, handleToast }) => {

  const [config, setUserConfig] = useState({})
  const [automaticAcceptance, setAutomaticAcceptance] = useState(false)

  const obterDadosConfiguracoes = async () => {
    const currentUser = SessionUser.get().Id
    const { data } = await Fetch.get(`/usuario/consultarConfiguracoes/${currentUser}`)

    setUserConfig(data)

    if (data?.UsaAceiteAutomaticoVolume) {
      setAutomaticAcceptance(data.UsaAceiteAutomaticoVolume)
    }
  }

  useEffect(() => {
    obterDadosConfiguracoes()
  }, [])


  const save = () => {
    const newConfig = {
      ...config,
      UsaAceiteAutomaticoVolume: automaticAcceptance
    }

    Fetch.post(`/usuario/salvarConfiguracoes`, newConfig)
      .then(() => {
        handleToast(intl.get("userSettings.accVolumnMessage"))
        handleClose()
      })
  }

  return (
    <>
      <Switcher
        name="aceiteAutomatico"
        label={intl.get('commons.accVolumn')}
        value={automaticAcceptance}
        onChange={() => setAutomaticAcceptance(!automaticAcceptance)}
      />
      {automaticAcceptance &&
        <div style={{ color: 'red', marginTop: '8px'}}>
          * {intl.get('commons.accVolumnHint')}
        </div>
      }
      <div className='line-buttons-aceite'>
        <Button
          type="default"
          value={intl.get('geral.buttonsDefault.cancel')}
          onClick={handleClose}
          className="button"
        />
        <Button
          type="primary"
          value={intl.get('geral.buttonsDefault.save')}
          className="button"
          onClick={save}
        />
      </div>
    </>
  )
}

PedidoForm.propTypes = {
  handleShowFeedback: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired
}

export default PedidoForm
