import React, { useState } from 'react'
import SuggestionsListHeader from "components/suggestions-list/suggestions-list-header";
import { Row, Col } from "react-flexbox-grid";
import moment from 'moment'
import intl from 'react-intl-universal'

import * as S from './styled'
import { formatNumber } from 'utils/format';

const EstoqueCompartilhado = ({ title, data, show }) => {
  const [isItemExpanded, setIsItemExpanded] = useState(false);
  const handleChangeExpandedItem = () => setIsItemExpanded(!isItemExpanded);

  return (
    <React.Fragment>
      {show && data.Stocks.length > 0 &&
        <S.Wrapper itemExpanded={isItemExpanded}>
          <SuggestionsListHeader
            isItem
            hasIcon
            isOrder={true}
            itemsExpanded={isItemExpanded}
            checked={false}
            expandAllItems={handleChangeExpandedItem}
            hideMargin
          >
            <S.TitlesWrapper>
              <span className='stock-title'>{title}</span>
              <span className='stock-content'>{formatNumber(data.TotalStock)}</span>
            </S.TitlesWrapper>

          </SuggestionsListHeader>
          <S.ContentWrapper itemExpanded={isItemExpanded}>
            <Row className="content-row">
              {data.Stocks.map((stock, i) => (
                <React.Fragment key={i}>
                  <Col className='content-col' xs={8}>
                    <S.InfosWrapper>
                      <span className='supplier-title' title={stock.Name}>{stock.Name}</span>
                      <span className='last-update'>
                        {intl.get('commons.lastUpdate')}: {moment(stock.LastUpdate).format("[DM]")}
                        {` ${intl.get('commons.at')} `}
                        {moment(stock.LastUpdate).format("HH:mm")}
                      </span>
                    </S.InfosWrapper>
                  </Col>
                  <Col className='content-col' xs={4}>
                    <S.InfosWrapper>
                      <span className='stock-title'>{intl.get('commons.stock')}</span>
                      <span className='stock-content'>{formatNumber(stock.Stock)} {stock.UnitOfMeasurement}</span>
                    </S.InfosWrapper>
                  </Col>
                </React.Fragment>
              ))}
            </Row>
          </S.ContentWrapper>
        </S.Wrapper>
      }
    </React.Fragment>
  )
}

export default EstoqueCompartilhado
