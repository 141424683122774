import React, { createContext, useContext, useState } from 'react'
import { getData, getItemForComposition, getReleased } from '../load-composition-building.service';
import { getPropUpdate, filterSelectedCards } from '../utils';
import { builderLoadOrdersComposition } from '../utils/builder';
import { validatedReturnLoadOrigin } from '../utils/validate';
import { buildOrdersMetaDataDto, filterDefault, getParamsForRequestByCardType, normalizeOrdersMetadata, normalizeOrdersMetadataByNegotiation, setIdCardSupplierSuggestions } from './utils';
import { useQuery } from 'hooks/useQuery'
import { getLoadCompositionAndNegotiations } from 'services/load-composition.service';
import { SituacaoComposicaoCarga } from 'models/composicaoCarga/situacaoComposicaoCarga';
import { normalizeOrdersByNegotiation } from 'pages/load-composition/components/modalLoadInNegotiation/utils';
import { usesEmergencyNegotiation } from 'utils/validations-general-settings';

const LoadCompositionBuildingContext = createContext();

const LoadCompositionBuildingProvider = ({ children, params, history }) => {
  const query = useQuery();
  const hasInitialItemForLoadComposition = (query.get('orderId') || query.get('suggestionId'));

  const [pushPath] = useState(query.get('redirectLink') || "/loadComposition");
  const [loads, setLoads] = useState([]);
  const [loadComposition, setLoadComposition] = useState({});
  const [selectedLoads, setSelectedLoads] = useState([]);
  const [loadsAwaitingComposition, setLoadsAwaitingComposition] = useState([]);
  const [filter, setFilter] = useState(filterDefault);
  const [isEditDateMode, setIsEditDateMode] = useState(hasInitialItemForLoadComposition);
  const [isLoading, setIsLoading] = useState(false)
  const [isFullTruckLoad] = useState(query.get('fullTruckLoad'));
  const [breweryNote, setBreweryNote] = useState('');
  const [isEmergencyNegotiation, setIsEmergencyNegotiation] = useState(false);
  const [initialMetadata, setInitialMetadata] = useState({})
  const [ordersMetadata, setOrdersMetadata] = useState({
    AdicaoPedidos: [],
    AlteracaoPedidos: [],
    RemocaoPedidos: [],
    AdicaoSugestoes: []
  });

  const addSelectedLoadsInOrdersMetadata = (_load) => {
    const isOrder = !!_load.IdPedido;

    if (isOrder) {
      const removeOrder = ordersMetadata.RemocaoPedidos.filter((x) => x !== _load.IdPedido);
      const addOrder = [...ordersMetadata.AdicaoPedidos, _load.IdPedido]
      setOrdersMetadata(prevData => ({ ...prevData, AdicaoPedidos: addOrder, RemocaoPedidos: removeOrder }));
    } else {
      const addSuggestion = [...ordersMetadata.AdicaoSugestoes, _load]
      setOrdersMetadata(prevData => ({ ...prevData, AdicaoSugestoes: addSuggestion }));
    }
  }

  const removeSelectedLoadsInOrdersMetadata = (id) => {
    const isOrder = id.includes("P");
    const _id = Number(id.slice(0, -1));
    if (isOrder) {
      const removeOrder = [...ordersMetadata.RemocaoPedidos];
      if (loadComposition.Pedidos.includes(_id))
        removeOrder.push(_id);
      const addOrder = ordersMetadata.AdicaoPedidos.filter((x) => x !== _id);
      setOrdersMetadata(prevData => ({ ...prevData, AdicaoPedidos: addOrder, RemocaoPedidos: removeOrder }));
    } else {
      let suggestions;
      if (ordersMetadata.AdicaoSugestoes.find(x => x.IdSugestao == _id)) {
        suggestions = ordersMetadata.AdicaoSugestoes.filter(x => x.IdSugestao !== _id);
      } else {
        suggestions = ordersMetadata.AdicaoSugestoes.filter(x => x.IdCard !== id);
      }
      setOrdersMetadata(prevData => ({ ...prevData, AdicaoSugestoes: suggestions }));
    }
  }

  const handleSetLoads = (_loads, _selectedLoads = selectedLoads) => setLoads(filterSelectedCards(_loads, _selectedLoads));

  const addSelectedLoads = (id) => {
    const load = loads.find(x => x.IdCard === id);
    const _loads = loads.filter((x) => x.IdCard !== id);
    if (selectedLoads.length === 0) {
      handleReloadDataCards({ idFornecedor: load.IdFornecedor }, [load]);
    } else {
      handleSetLoads(_loads, selectedLoads);
    }
    setSelectedLoads(prevLoads => ([...prevLoads, load]))
    addSelectedLoadsInOrdersMetadata(load);
  }

  const removeSelectedLoads = (id) => {
    const _selectedLoad = selectedLoads.find(x => x.IdCard === id);
    const _selectedLoads = selectedLoads.filter((x) => x.IdCard !== id);
    if (isEmergencyNegotiation) {
      removeSelectedLoadsInOrdersMetadata(id);
    }

    if (!_selectedLoad) {
      handleReloadDataCards({}, _selectedLoads)
    }
    else if (selectedLoads.length === 1) {
      handleReloadDataCards({ idFornecedor: isEmergencyNegotiation ? _selectedLoad.IdFornecedor : null }, _selectedLoads)
      setSelectedLoads([])
    } else {
      const isValid = validatedReturnLoadOrigin(filter, _selectedLoad);
      if (isValid) {
        setSelectedLoads([..._selectedLoads])
        handleSetLoads([...loads, ...selectedLoads], _selectedLoads)
      } else {
        setSelectedLoads([..._selectedLoads])
      }
    }
  }

  const proccessPagination = (_data) => {
    setFilter(prevState => ({
      ...prevState, pagination: { page: _data.Page, total: _data.Total, take: _data.Take }
    }))
  };

  const fetchLoads = async (_filter, isReleased) => {
    setIsLoading(true);
    let data = [];
    const _data = isReleased ? await getReleased(_filter) : await getData(_filter);
    const prop = getPropUpdate(isReleased);
    data = _data[prop];
    proccessPagination(_data);
    setIsLoading(false);
    return data;
  }

  const handlerOrdersComposition = async (_loadComposition) => {
    const dtoFilter = builderLoadOrdersComposition(params, filter)
    let _data = await fetchLoads(dtoFilter, true);
    if (_loadComposition.OrdersNegotiations) {
      const ordersNegotiations = setIdCardSupplierSuggestions(_loadComposition.OrdersNegotiations);
      const _ordersMetadata = buildOrdersMetaDataDto(ordersNegotiations);
      setOrdersMetadata(_ordersMetadata)
      setInitialMetadata(normalizeOrdersMetadataByNegotiation(ordersNegotiations))
      _data = normalizeOrdersByNegotiation(_data, ordersNegotiations);
      _data = _data.filter(x => !x.IsDeleted);
    } else {
      setOrdersMetadata(prevState => ({
        ...prevState,
        AlteracaoPedidos: _data
      }));
      setInitialMetadata(normalizeOrdersMetadata(_data))
    }
    setSelectedLoads([..._data]);
  }

  const handleChangePage = (page) => {
    const _filter = { pagination: { ...filter.pagination, page: page } }
    handleReloadDataCards(_filter)
  }

  const handleReloadDataCards = async (_filter, _selectedLoads) => {
    const newFilter = { ...filter, pagination: filterDefault.pagination, ..._filter }
    setFilter(newFilter);
    const { dto, isReleased } = getParamsForRequestByCardType(newFilter, params);
    const data = await fetchLoads(dto, isReleased);
    handleSetLoads(data, _selectedLoads);
  }

  const loadPage = async () => {
    let _filter = {};
    if (params.composicaoId) {
      _filter = { idFornecedor: params.fornecedorId };
      const _loadComposition = await getLoadCompositionAndNegotiations(params.composicaoId)
      const isItAlreadyAccepted = [
        SituacaoComposicaoCarga.Aceito,
        SituacaoComposicaoCarga.NegociacaoEmergencial
      ].includes(_loadComposition.SituacaoValor);
      setLoadComposition(_loadComposition)
      setBreweryNote(_loadComposition.BreweryNote)
      setIsEmergencyNegotiation(isItAlreadyAccepted && usesEmergencyNegotiation());
      handlerOrdersComposition(_loadComposition);
    }
    if (!isEditDateMode) {
      handleReloadDataCards(_filter);
    }
    if (hasInitialItemForLoadComposition) {
      history.replace(window.location.pathname);

      const dataInitialItem = await getItemForComposition(query.get('orderId'), query.get('suggestionId'), params);
      setFilter(prevState => ({ ...prevState, idFornecedor: dataInitialItem[0]?.IdFornecedor }))
      setSelectedLoads(dataInitialItem)
    }
  }

  const replaceRoute = (_route) => {
    history.replace(_route)
  }

  return (
    <LoadCompositionBuildingContext.Provider
      value={{
        addSelectedLoads,
        selectedLoads,
        loads,
        setLoads,
        removeSelectedLoads,
        setSelectedLoads,
        loadsAwaitingComposition,
        setLoadsAwaitingComposition,
        filter,
        setFilter,
        isEditDateMode,
        setIsEditDateMode,
        handleReloadDataCards,
        handleChangePage,
        params,
        isLoading,
        loadPage,
        isFullTruckLoad,
        pushPath,
        replaceRoute,
        breweryNote,
        setBreweryNote,
        ordersMetadata,
        setOrdersMetadata,
        isEmergencyNegotiation,
        setIsEmergencyNegotiation,
        loadComposition,
        initialMetadata
      }}
    >
      {children}
    </LoadCompositionBuildingContext.Provider>
  )
}

export const useLoadCompositionBuildingContext = () => useContext(LoadCompositionBuildingContext)
export { LoadCompositionBuildingContext, LoadCompositionBuildingProvider }
