import React, { Component } from 'react'
import { Row, Col } from "react-flexbox-grid"
import { Button } from 'components/uikit-adapter/index'
import { withRouter } from 'react-router-dom'
import Session from 'utils/user-storage'
import intl from 'react-intl-universal'
import './aguardando-ciencia.css'

class AguardandoCiencia extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        let {
            ObservacaoAceiteOver
        } = this.props.data;
        return (
            <div className='event-action'>
                    <Row>
                        <Col xl={12}>
                            <div className='status-timeline'>
                                <span className='reticencias'>Obs.: {ObservacaoAceiteOver}</span>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}>
                            {this.props.data.Atual &&
                                Session.get().PerfilAcesso != 3 &&
                                Session.get().PerfilAcesso != 5 &&
                                <Button
                                    value={intl.get('stocks.timeline.aware')}
                                    type="primary"
                                    className="button"
                                    onClick={this.props.registrarCiencia}
                                />
                            }
                        </Col>
                    </Row>
            </div>
        )
    }
}

export default withRouter(AguardandoCiencia)
