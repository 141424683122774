import { PureComponent } from 'react'
import moment from 'moment'
import intl from 'react-intl-universal'
import { Layout } from '@hbsis.uikit/react'
import { Button } from 'components/uikit-adapter'
import StorePersist from 'utils/store-persist'
import PropTypes from 'prop-types'
import SnackBar from 'material-ui/Snackbar'
import Header from 'components/header'
import Filter from './filter'
import Loading from 'components/center-loading'
import Pagination from 'components/pagination'
import ExpandableTableOptimization from 'components/expandable-table-optimization'
import Fetch from 'utils/fetch'
import { Labels } from './config-table'
import { convertToQueryParameters } from 'utils/custom-functions'
import { HistoryIcon, OptimizationIcon, SendTransportIcon } from 'components/icons/icn-index.icon'
import ColorPickers from '../../colorpickers/index'
import PriorityForm from './prioridade-form/index';
import ModalErroShipment from './modal-erro-shipment/index'

import IconEmpty from 'images/iconEmpty.icon'

import StyledOptimization, { CodigoLink } from './styledOptimization'
import { formatNumber } from 'utils/format'
import { IconFilterActive } from 'components/icons/icn-filter-active.icon'
import { IconFilterInactive } from 'components/icons/icn-filter-inactive.icon'

const SYSTEM_PLANNING_REGULAR = 0
const SHIPMENT_PRIORITY_REGULAR = 1

const SYSTEM_PLANNING_TYPES = [
  { Key: 0, Value: 'Regular' },
  { Key: 1, Value: 'Urgente' },
]

const SHIPMENT_PRIORITY_TYPES = [
  { Key: 0, Value: '1' },
  { Key: 1, Value: '2' },
  { Key: 2, Value: '3' },
  { Key: 3, Value: '4' },
]

const SHIPMENT_PRIORITY_LEGENDS = [
  { Title: '1', Description: intl.get('loadOptimization.shipmentPriorityLegends.descriptionOne') },
  { Title: '2', Description: intl.get('loadOptimization.shipmentPriorityLegends.descriptionTwo') },
  { Title: '3', Description: intl.get('loadOptimization.shipmentPriorityLegends.descriptionThree') },
  { Title: '4', Description: intl.get('loadOptimization.shipmentPriorityLegends.descriptionFour') },
]

class Optimization extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      requestCount: 0,
      total: 0,
      currentPage: 0,
      qtRegsPerPage: 50,
      showHistory: false,
      otimizacoes: [],
      selectedItems: [],
      idsSelectedItems: [],
      selectedItem: null,
      allChecked: false,
      showFeedback: false,
      messageFeedback: '',
      filters: {
        CodigoPedidoVolume: '',
        IdOrigem: '',
        IdUnidadeNegocio: '',
        NumeroMaterial: '',
        IdStatus: '',
        IdEtapa: '',
        IdFamilia: '',
        IdFamiliaRotulada: '',
        DataColetaDe: '',
        DataColetaAte: '',
        DataEntregaDe: '',
        DataEntregaAte: '',
        DataAtualizacaoDe: moment().format("YYYY-MM-DD"),
        DataAtualizacaoAte: moment().format("YYYY-MM-DD"),
      },
      showFilter: false,
      showModalPrioridade: false,
      showModalErroShipment: false,
      errosShipments: [],
      systemPlanning: SYSTEM_PLANNING_REGULAR,
      systemPlanningTypes: SYSTEM_PLANNING_TYPES,
      shipmentPriority: SHIPMENT_PRIORITY_REGULAR,
      shipmentPriorityTypes: SHIPMENT_PRIORITY_TYPES,
    }
  }

  componentDidMount() {
    const filters = StorePersist.getValuesJSON(this.state.showHistory ? 'filtersOptimizationHistory' : 'filtersOptimization')

    if (filters) {
      this.setState({
        filters
      })
    }

    this.buscarOtimizacoes(0)
  }

  buscarOtimizacoes = async (page) => {
    this.handleFetch();

    const { qtRegsPerPage, currentPage, filters, showHistory } = this.state;
    const nextPage = page == undefined ? currentPage : page;

    let model = {};

    model = {
      countPage: qtRegsPerPage,
      page: nextPage,
      codigoPedidoVolume: filters.CodigoPedidoVolume,
      idFornecedor: filters.IdOrigem,
      idUnidadeNegocio: filters.IdUnidadeNegocio,
      numeroMaterial: filters.NumeroMaterial,
      idStatus: filters.IdStatus,
      idEtapa: filters.IdEtapa,
      idFamilia: filters.IdFamilia,
      idFamiliaRotulada: filters.IdFamiliaRotulada,
      dataColetaDe: filters.DataColetaDe,
      dataColetaAte: filters.DataColetaAte,
      dataEntregaDe: filters.DataEntregaDe,
      dataEntregaAte: filters.DataEntregaAte,
      dataAtualizacaoDe: filters.DataAtualizacaoDe,
      dataAtualizacaoAte: filters.DataAtualizacaoAte,
      visaoHistorico: showHistory
    }

    try {
      const response = await Fetch.get(`volumeoptimization/consultarOtimizacaoDeVolumePorPaginacao?${convertToQueryParameters(model)}`)
      this.proccessData(response.data.Data)
      this.setState({
        currentPage: nextPage,
        total: response.data.TotalRecordCount
      }, () => this.handleSelectAllItens(false))
    } catch (e) {
      this.showFeedback(intl.get('loadOptimization.thereErrorLoadingData'))
    }
    this.handleFetch(false)
  }

  proccessData = (data) => {
    this.setState({ otimizacoes: data })
  }

  handleFetch = (isFetching = true) => {
    this.setState(prevState => ({
      requestCount: isFetching ? prevState.requestCount + 1 : prevState.requestCount - 1,
    }))
  }

  showFeedback = message => {
    this.setState({
      showFeedback: true,
      messageFeedback: message
    })
  }

  getDateFormatted = (_value) => _value ? moment(_value).format("L") : <div className='center-align'>-</div>;

  getNumberFormatted = (_value) =>  _value ? formatNumber(_value) : <div className='center-align'>-</div>;

  getItemFormatted = (_value) => _value || <div className='center-align'>-</div>;

  handleResolveValue = (key, item) => {
    if (item) {
      if (key === 'DataColeta' || key === 'DataEntrega' || key === 'UltimaAtualizacao') {
        return this.getDateFormatted(item[key]);
      }

      if (key === 'SaldoPendente' || key === 'SaldoCancelado' || key === 'Quantidade') {
        return this.getNumberFormatted(item[key]);
      }

      if (key == 'Id' && item.UrlVisaoEstoqueDetalhe) {
        return (
          <CodigoLink
            className="column-codigo-link"
            onClick={() => this.props.history.push(item.UrlVisaoEstoqueDetalhe)}
          >
            {item[key]}
          </CodigoLink>
        )
      }

      if (key == 'SituacaoDesc' && item['ErrosEnvioShipment'] && item['ErrosEnvioShipment'].length > 0) {
        return (
          <CodigoLink
            className="column-codigo-link column-alert-erro"
            onClick={() => this.openModalErroShipment(item['ErrosEnvioShipment'])}
          >
            {item[key]}
          </CodigoLink>
        )
      }

      return this.getItemFormatted(item[key]);
    }
  };

  openModalErroShipment = (errors) => {
    this.setState({
      errosShipments: errors,
      showModalErroShipment: true
    })
  }

  handleResolveTitle = (key, item) => {
    if (!!item) {
      if (key === 'Material' || key === 'Fornecedor') { return item[key]; }
    }
  }

  closeFeedback = () => { this.setState({ showFeedback: false }) }

  showCheckbox = () => !this.state.showHistory;

  handleSelectItem = (selected, item) => {
    const { selectedItems, idsSelectedItems } = this.state;
    let items = [...selectedItems];
    let idsItens = [...idsSelectedItems];

    item.checked = selected;

    if (selected) {
      items.push(item);
      idsItens.push(item.Id);
    } else {
      items = items.filter(i => i.Id !== item.Id);
      idsItens = idsItens.filter(i => i !== item.Id);
    }

    this.setState({
      selectedItems: items,
      idsSelectedItems: idsItens
    })
  }

  handleSelectAllItens = (checked) => {
    const { otimizacoes } = this.state;
    let items = [...otimizacoes];
    items.forEach((item) => { item.checked = checked })
    const idsItens = items.filter(item => item.checked).map(item => item.Id)

    this.setState({
      selectedItems: items,
      idsSelectedItems: idsItens,
      allChecked: checked,
    })
  }

  searchFilters = (filters) => {
    StorePersist.setValuesJSON(this.state.showHistory ? 'filtersOptimizationHistory' : 'filtersOptimization', filters);
    this.showFilter()
    this.setState({
      filters: filters,
      otimizacoes: [],
    }, () => { this.buscarOtimizacoes(0) })
  }

  verifyFilter = () => {
    const { showHistory } = this.state
    const {
      IdOrigem,
      IdUnidadeNegocio,
      CodigoPedidoVolume,
      DataColetaDe,
      DataColetaAte,
      DataEntregaDe,
      DataEntregaAte,
      IdStatus,
      IdEtapa,
      NumeroMaterial,
      IdFamilia,
      IdFamiliaRotulada,
      DataAtualizacaoDe,
      DataAtualizacaoAte,
    } = this.state.filters

    return IdOrigem > 0
      || IdUnidadeNegocio > 0
      || CodigoPedidoVolume
      || DataColetaDe
      || DataColetaAte
      || DataEntregaDe
      || DataEntregaAte
      || (showHistory && IdStatus && IdStatus > 0)
      || (IdEtapa && IdEtapa > 0)
      || NumeroMaterial
      || (IdFamilia && IdFamilia > 0)
      || (IdFamiliaRotulada && IdFamiliaRotulada > 0)
      || (showHistory && DataAtualizacaoDe)
      || (showHistory && DataAtualizacaoAte)
  }

  showFilter = () => {
    this.setState(prevState => ({
      showFilter: !prevState.showFilter,
    }))
  }

  showHistory = () => {
    this.setState(prevState => ({
      showHistory: !prevState.showHistory,
    }), () => { this.buscarOtimizacoes(0) })
  }

  changeValue = (name, value) => {
    this.setState({
      [name]: value
    })
  }

  sendTransport = () => {
    try {
      if (!(Array.isArray(this.state.idsSelectedItems) && this.state.idsSelectedItems.length))
        this.showFeedback(intl.get('loadOptimization.selectLeastOneVolumeSend'))
      else
        this.openCloseSolicitacaoPrioridade(true)
    } catch (err) {
      console.log("err", err)
    }
  }

  openCloseSolicitacaoPrioridade = (value) => {
    this.setState({
      showModalPrioridade: value
    })
  }

  openCloseModalErroShipment = (value) => {
    this.setState({
      showModalErroShipment: value
    })
  }

  enviarParaTransporte = async () => {
    this.handleFetch();

    const model = {
      IdVolumes: this.state.idsSelectedItems,
      SystemPlanning: this.state.systemPlanning,
      ShipmentPriority: this.state.shipmentPriority
    }

    await Fetch.post(`volumeoptimization/enviarParaTransporte`, model);
    this.handleFetch(false);
    this.openCloseSolicitacaoPrioridade(false)
    this.buscarOtimizacoes(0);
  }

  render() {

    const {
      showHistory,
      otimizacoes,
      messageFeedback,
      requestCount,
      showFeedback,
      currentPage,
      qtRegsPerPage,
      total,
      filters,
      showFilter,
      showModalPrioridade,
      showModalErroShipment,
      systemPlanning,
      systemPlanningTypes,
      shipmentPriority,
      shipmentPriorityTypes,
      allChecked,
      errosShipments
    } = this.state

    return (
      <StyledOptimization>
        <Loading isLoading={requestCount > 0} />
        <Header title={intl.get('transports.otimizationCharge.header.title')} titleWidth={450}>
          <div className='buttons-header-container'>
            {!showHistory &&
              <div className='filterContainer' title={intl.get('transports.otimizationCharge.actions.sendToTransport')}>
                <Button
                  type="default"
                  value={SendTransportIcon({ width: '30px', height: '30px', color: ColorPickers.collorButtom })}
                  className="button-filter-vision"
                  onClick={this.sendTransport}
                  visible={showHistory}
                />
              </div>
            }
            {!showHistory &&
              <div className='filterContainer' title={intl.get('transports.otimizationCharge.actions.alternateToHistoric')}>
                <Button
                  type="default"
                  value={<HistoryIcon style={{ width: 30, height: 30 }} />}
                  className="button-filter-vision"
                  onClick={this.showHistory}
                />
              </div>
            }
            {showHistory &&
              <div className='filterContainer' title={intl.get('transports.otimizationCharge.actions.alternateToOtimization')}>
                <Button
                  type="default"
                  value={<OptimizationIcon style={{ width: 100, height: 100 }} />}
                  className="button-filter-vision"
                  onClick={this.showHistory}
                />
              </div>
            }

            <div className='filterContainer'>
              <Button
                onClick={this.showFilter}
                type='default'
                buttonText={intl.get('filters.filter')}
                buttonIcon={this.verifyFilter() ? <IconFilterActive /> : <IconFilterInactive />}
                className={"button-filter"}
              />
            </div>
          </div>
        </Header>

        <Layout.Sidebar width="450px"
          visible={this.state.showFilter}
          triggerClose={this.showFilter}
          background="var(--color-contrast-white)"
          offsetTop="96px"
          side="right"
          block
          float
          icon={<IconEmpty />}
        >
          <Filter
            showFilter={showFilter}
            useFilterDefault={this.state.useFilterDefault}
            initialData={filters}
            handleClose={this.searchFilters}
            triggerClose={this.changeShowFilter}
            showHistory={showHistory}
          />
        </Layout.Sidebar>

        <div className='content'>
          {(otimizacoes.length && requestCount == 0) ? (
            <div className='content-internal'>
              <ExpandableTableOptimization
                data={otimizacoes}
                labelsList={Labels}
                labelKey='Label'
                childrenKey='Pedidos'
                onClickItem={this.handleClick}
                onSelectItem={this.handleSelectItem}
                allChecked={allChecked}
                onSelectAllItens={this.handleSelectAllItens}
                resolveValue={this.handleResolveValue}
                resolveTitle={this.handleResolveTitle}
                showCheckbox={this.showCheckbox}
              />
              <Pagination
                page={currentPage}
                pageSize={qtRegsPerPage}
                amount={total}
                refreshSearch={this.buscarOtimizacoes}
              />
            </div>
          )
            : (<div>{intl.get('loadOptimization.noRecordsFound')}</div>)}
        </div>

        {showModalPrioridade &&
          <PriorityForm
            open={showModalPrioridade}
            systemPlanning={systemPlanning}
            systemPlanningTypes={systemPlanningTypes}
            shipmentPriority={shipmentPriority}
            shipmentPriorityTypes={shipmentPriorityTypes}
            shipmentPriorityLegends={SHIPMENT_PRIORITY_LEGENDS}
            changeValue={this.changeValue}
            handleSend={this.enviarParaTransporte}
            handleClose={this.openCloseSolicitacaoPrioridade}
          />
        }

        {showModalErroShipment &&
          <ModalErroShipment
            open={showModalErroShipment}
            errors={errosShipments}
            handleClose={this.openCloseModalErroShipment}
          />
        }

        <SnackBar
          message={messageFeedback}
          open={showFeedback}
          autoHideDuration={3000}
          onRequestClose={this.closeFeedback}
        />
      </StyledOptimization>
    )
  }
}

Optimization.propTypes = {
  subtitle: PropTypes.string,
  handleClose: PropTypes.func,
  myCenter: PropTypes.bool,
  suggestion: PropTypes.bool,
  suggestionClick: PropTypes.func,
  myCenterClick: PropTypes.func,
  handleCloseReload: PropTypes.func
}

export default Optimization;
