import React from 'react'
import PropTypes from 'prop-types'
import StyledCardHeader from './styled-card-header'
import cx from 'classnames'
import { TextSpan } from 'components/text-label'
import { IconCalendarSchedule } from 'components/icons/icn-index.icon'
import StyleNotication from './styled-card-notification'

export const CardContent = ({
  showIcon,
  colorStatus,
  colorStatusArrow,
  icon,
  title,
  subtitle,
  programado,
  customOptions
}) => (
  <React.Fragment>
    {showIcon &&
      <StyledCardHeader
        backgroundColor={colorStatus}
        className="imageStatus"
      >
        <img
          src={icon}
          alt="Ícone"
          width="35px"
          color="white"
        />
        <div className="arrowStatus" style={{ borderColor: `transparent transparent ${colorStatusArrow} transparent` }} />
      </StyledCardHeader>
    }
    <StyledCardHeader
      className={cx({ headerDesc: showIcon })}
      titleColor={colorStatus}
    >
      <span className={`cardTitle ${title}`}>{title}</span>
      <TextSpan className="cardSubTitle">
        {subtitle}
      </TextSpan>
    </StyledCardHeader>
    {customOptions}
    {
      programado &&
      <div className="notificationIcon-container">
        <div className="notificationIcon">
          <StyleNotication>
            <IconCalendarSchedule width={'25px'} height={'25px'}/>
          </StyleNotication> 
        </div>
      </div>
    }
  </React.Fragment>
)

CardContent.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  showIcon: PropTypes.bool,
  programado: PropTypes.bool,
  status: PropTypes.number,
  customOptions: PropTypes.element,
}

CardContent.defaultProps = {
  title: '',
  subtitle: '',
  showIcon: true,
}
