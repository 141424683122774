import { parseFloatByLocale } from "locales/utils"

export const calculateQuantityPerTruck = (value, fullTruckQuantity, lotSize) => {
  const total = (parseFloatByLocale(value) * formatFloat(lotSize))
  const trucksQuantity = (total / formatFloat(fullTruckQuantity))
  return {
    QuantityPallets: value,
    Total: formatFloat(total),
    TrucksQuantity: isNaN(trucksQuantity) ? 0 : formatFloat(trucksQuantity)
  }
}

export const calculatePalletsQuantityByTotal = (value, fullTruckQuantity, lotSize) => {
  const _value = parseFloatByLocale(value);
  const quantity = (_value / formatFloat(lotSize))
  const trucksQuantity = (_value / formatFloat(fullTruckQuantity))
  return {
    QuantityPallets: isNaN(quantity) ? 0 : formatFloat(quantity),
    Total: value,
    TrucksQuantity: isNaN(trucksQuantity) ? 0 : formatFloat(trucksQuantity)
  }
}

const formatFloat = (value) => parseFloat(parseFloat(value).toFixed(3))

export const generateRandomNumber = () => {
  const crypto = window.crypto || window.msCrypto;
  const array = new Uint32Array(1);
  crypto.getRandomValues(array);
  return array[0];
}