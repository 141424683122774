import React from 'react'
import intl from 'react-intl-universal'
import { Button } from "components/uikit-adapter/index";
import { Vector } from '../bottles-dropdown/vector'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import AlertVector from 'images/AlertVectorRed.svg'
import * as S from './styled'

export const ModalConfirm = ({ open, close, text, title, confirm, warning }) => (
  <Dialog
    open={open}
    onClose={close}
    style={{ minHeight: '800px' }}
  >
    <DialogTitle>
      <S.Title>{title}</S.Title>
    </DialogTitle>
    <DialogContent style={{ borderBottom: 'solid 1px var(--color-neutral-200)', borderTop: 'solid 1px var(--color-neutral-200)' }}>
      <DialogContentText style={{ margin: '10px' }}>
        <S.Text>{text}</S.Text>
        {warning &&
          <S.Alert>
            <Vector width="16px" heigth="16px" vector={AlertVector} />
            {warning}
          </S.Alert>}
      </DialogContentText>
    </DialogContent>
    <DialogActions style={{ width: '350px', alignSelf: 'center' }}>
      <Button
        value={intl.get("geral.buttonsDefault.cancel")}
        onClick={close}
        type="default"
      />
      <Button
        value={intl.get("geral.buttonsDefault.confirm")}
        onClick={confirm}
        type="primary"
      />
    </DialogActions>
  </Dialog>
)