import React from "react";
import PropTypes from "prop-types";
import { Button } from 'components/uikit-adapter';
import AceiteVolumeParcialFornecedor from "../aceite-volume-parcial-fornecedor";
import RecusaFornecedor from "./../recusa-fornecedor"
import intl from 'react-intl-universal'

import "./aceite-volume-fornecedor.css";

class AceiteVolumeFornecedor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      disabled: false,
      showAceiteParcial: false,
      showRecusa: false,
    };
  }

  showAceiteParcial = () => { this.setState({ showAceiteParcial: true }) }

  hideAceiteParcial = () => { this.setState({ showAceiteParcial: false }) }

  showRecusa = () => { this.setState({ showRecusa: true }) }

  hideRecusa = () => { this.setState({ showRecusa: false }) }

  render() {
    return (
      <div className="negotiation-line-volume">
          {this.state.showAceiteParcial && (
            <AceiteVolumeParcialFornecedor
              quantidadeProposta={this.props.quantidade}
              handleCancel={this.hideAceiteParcial}
              idPedido={this.props.idPedido}
              idFamiliaRotuladaCentro={this.props.idFamiliaRotuladaCentro}
              data={this.props.data}
              refreshTimeLine={this.props.refreshTimeLine}
              startLoading={this.props.startLoading}
              stopLoading={this.props.stopLoading}
            />
          )}

          {this.state.showRecusa && (
            <RecusaFornecedor
              handleCancel={this.hideRecusa}
              idPedido={this.props.idPedido}
              recusa={this.props.recusa}
            />
          )}

          {(!this.state.showAceiteParcial &&  !this.state.showRecusa)
           && (
            <div className="event-action-volume">
                <Button
                  value={intl.get('stocks.timeline.refuse')}
                  onClick={this.showRecusa}
                  type="danger"
                  className="button"
                  disabled={this.disabled}
                />
                <Button
                  value={intl.get('stocks.timeline.acceptPartial')}
                  onClick={this.showAceiteParcial}
                  type="secondary"
                  className="button"
                  disabled={this.disabled}
                />
                <Button
                  value={intl.get('stocks.timeline.accept')}
                  onClick={() => this.props.aceiteTotal(this.props.idPedido)}
                  type="primary"
                  className="button"
                  disabled={this.disabled}
                />
            </div>
          )}
      </div>
    );
  }
}

AceiteVolumeFornecedor.propTypes = {
  idPedido: PropTypes.number,
  aceiteTotal: PropTypes.func.isRequired,
  recusa: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  quantidade: PropTypes.number.isRequired
}

export default AceiteVolumeFornecedor;
