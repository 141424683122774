import { Component } from 'react'
import Loading from 'components/center-loading'
import HeaderIcon from 'components/header-icon'
import { Button, SelectBox, SnackBar } from 'components/uikit-adapter/index'
import { Row, Col } from 'react-flexbox-grid'
import GradeList from './list'
import { FactoryIcon, GradeIcon } from 'components/icons/icn-index.icon'
import StorePersist from 'utils/store-persist'
import { FILTERS_DEFAULT } from '../../constants'
import {
  searchBusinessUnit,
} from './grade-content.service'
import GradeContentStyled from './grade-content.styled'
import intl from 'react-intl-universal'
import { profileAdminOrMasterData } from "services/permissions/getPermissions";
import PropTypes from 'prop-types';

class GradeContent extends Component {

  constructor() {
    super()

    this.state = {
      requestCount: 0,
      showFeedback: false,
      messageFeedBack: '',
      listaUnidadeNegocio: [],
      listaGrade: [],
      weekdays: ['Segunda', 'Terca', 'Quarta', 'Quinta', 'Sexta', 'Sabado', 'Domingo'],
      filters: {
        ...FILTERS_DEFAULT
      }
    }
  }

  async componentDidMount() {
    let filters;
    try {
      filters = await StorePersist.getValuesJSON(this.props.filters)
    } catch (e) { }

    if (!filters)
      filters = FILTERS_DEFAULT

    this.setState({
      filters
    }, () => { this.searchBusinessUnit() })
  }

  handleFetch = (isFetching = true) => {
    this.setState(prevState => ({
      requestCount: isFetching ? prevState.requestCount + 1 : prevState.requestCount - 1,
    }))
  }

  changeUnidadeNegocio = (idUnidadeNegocio) => {
    const { filters } = this.state;
    const _filters = {
      ...filters,
      IdUnidadeNegocio: idUnidadeNegocio
    }

    this.setState({
      filters: _filters
    }, () => { this.searchGrade(idUnidadeNegocio) })

    StorePersist.setValuesJSON(this.props.filters, _filters);
  }

  searchBusinessUnit = async () => {
    try {
      this.handleFetch()
      const data = await searchBusinessUnit()
      await this.processData('listaUnidadeNegocio', data)
      this.changeUnidadeNegocio(this.getIdUnidadeNegocio())
    } catch (error) {
      this.proccessError(error);
    } finally {
      this.handleFetch(false);
    }
  }

  getIdUnidadeNegocio = () => {
    if (this.state.filters.IdUnidadeNegocio && this.state.filters.IdUnidadeNegocio > 0) {
      return this.state.filters.IdUnidadeNegocio
    }
    else if (this.state.listaUnidadeNegocio[0] && this.state.listaUnidadeNegocio[0].IdUnidadeNegocio) {
      return this.state.listaUnidadeNegocio[0].IdUnidadeNegocio
    }
    else {
      return 0
    }
  }

  processData = (property, data) => new Promise(resolve =>
    this.setState({
      [property]: data
    }, resolve)
  )

  searchGrade = async (idUnidadeNegocio) => {
    try {
      this.handleFetch()
      const data = await this.props.searchGrade(idUnidadeNegocio);
      this.setState({listaGrade: []})
      this.processListaGrade(data);
    } catch (error) {
      this.proccessError(error);
    } finally {
      this.handleFetch(false);
    }
  }

  hasValue = (prop, grade) => grade[prop]
  processListaGrade = (data) => {
    const listaGrade = data.map((grade) => ({
      Id: grade.Id,
      IdFamilia: grade.IdFamilia,
      DescricaoFamilia: grade.DescricaoFamilia,
      IdOrigem: grade.IdOrigem,
      DescricaoOrigem: grade.DescricaoOrigem,
      IdUnidadeNegocio: grade.IdUnidadeNegocio,
      Segunda: this.hasValue('Segunda', grade),
      Terca: this.hasValue('Terca', grade),
      Quarta: this.hasValue('Quarta', grade),
      Quinta: this.hasValue('Quinta', grade),
      Sexta: this.hasValue('Sexta', grade),
      Sabado: this.hasValue('Sabado', grade),
      Domingo: this.hasValue('Domingo', grade),
    }))

    this.setState({ listaGrade: [...listaGrade] })
  }

  salvarGrade = async () => {
    const { filters, listaGrade } = this.state;
    const gradesPreenchidas = this.verificarGradesPreenchida(listaGrade)

    if(!Array.isArray(gradesPreenchidas)) {
      return this.showFeedback(intl.get('master-data.feedback.thereAreInconsistentFields'))
    }

    try {
      this.handleFetch()
      await this.props.saveGrade(filters.IdUnidadeNegocio, { Grids: gradesPreenchidas })
      this.showFeedback(this.props.saveMessageSucess)
      this.refreshPage()
    } catch (error) {
      this.proccessError(error);
    } finally {
      this.handleFetch(false);
    }
  }

  refreshPage = () => {
    this.searchGrade(this.state.filters.IdUnidadeNegocio)
  }

  verificaCamposNaoPreenchidos = (grade) => {
    const days = this.state.weekdays.map((day) => grade[day])
    return days.filter((d) => d === null).length
  }

  verificarGradesPreenchida = (grades) => {
    const gradesPreenchidas = []
    let gradesNaoPreenchidas = 0
    grades.map((grade) => {
      const days = this.state.weekdays.filter((day) => grade[day] !== null)
      if (days.length === 7) {
        gradesPreenchidas.push(grade)
      }
      if (days.length > 0 && days.length < 7) {
       gradesNaoPreenchidas++
      }
    })
    return gradesNaoPreenchidas === 0 ? gradesPreenchidas : gradesNaoPreenchidas
  }

  handlerUpdateList = (prop, value, gradeParam) => {
    const index = this.props.findIndexUpdateList(this.state.listaGrade, gradeParam);
    
    const grade = {
      ...gradeParam,
      [prop]: value === '' ? null : value
    }

    const novaGrade = [{
      ...grade,
      camposNaoPreenchidos: this.verificaCamposNaoPreenchidos(grade),
    }]

    this.setState(prevState => ({
      listaGrade: [
        ...prevState.listaGrade.slice(0, index),
        ...novaGrade,
        ...prevState.listaGrade.slice(index + 1)
      ]
    }))
  }

  proccessError = (exception) => {
    this.showFeedback(!!exception.response ? exception.response.Message : '')
  }

  showFeedback = (message) => {
    this.setState({
      showFeedback: true,
      messageFeedBack: message
    })
  }

  closeFeedback = () => {
    this.setState({
      showFeedback: false
    })
  }

  render() {
    let {
      requestCount,
      showFeedback,
      messageFeedBack,
      listaGrade,
      listaUnidadeNegocio,
      filters
    } = this.state

    return (
      <GradeContentStyled>
        <Loading isLoading={requestCount > 0} />

        <Row>
          <Col xs={8} lg={8} md={8}>
            <HeaderIcon
              title={this.props.title}
              subtitle={this.props.subTitle}
              icon={<GradeIcon width='45px' height='45px' color='var(--color-brand-500)' />}>
            </HeaderIcon>
          </Col>

          <Col xs={1} lg={1} md={1} className="head-buttons-icon">
            <FactoryIcon
              width='33px'
              height='33px'
              className='icon-unit'
            />
          </Col>

          <Col xs={3} lg={3} md={3} className="head-buttons-filter">
            <SelectBox
              required
              name='IdUnidadeNegocio'
              label=''
              placeholder={intl.get('manual-suggestion-order-form.unit')}
              valueKey='IdUnidadeNegocio'
              labelKey='Descricao'
              value={!!filters ? filters.IdUnidadeNegocio : 0}
              options={listaUnidadeNegocio}
              onChange={value => this.changeUnidadeNegocio(value.IdUnidadeNegocio)}
              className='select-unidade-negocio'
              searchable
              openOnFocus
            />
          </Col>
        </Row>

        <Row>
          <Col xs={12} lg={12} md={12}>
            <GradeList
              type={this.props.type}
              data={listaGrade}
              handlerUpdateList={this.handlerUpdateList}
              profileAdminOrMasterData={profileAdminOrMasterData()}
            />
          </Col>
        </Row>

        {profileAdminOrMasterData() &&
          <div className='footer-dialog-buttons'>
            <Button
              onClick={this.salvarGrade}
              type='primary'
              value={intl.get('geral.buttonsDefault.save')}
              className="btn-save-grade"
            />
          </div>
        }

        <SnackBar
          message={messageFeedBack}
          open={showFeedback}
          autoHideDuration={3000}
          onRequestClose={this.closeFeedback}
        />

      </GradeContentStyled>
    )
  }
}

GradeContent.propTypes = {
  filters: PropTypes.string.isRequired
}

export default GradeContent
