import intl from 'react-intl-universal'
import { Button } from 'components/uikit-adapter/index'
import Dialog from "material-ui/Dialog"
import { ModalText, ModalTitle, WrapperButtons } from './styled'

export const ModalConfirmAction = ({ isOpen, handleClose, handleConfirm, title, textMessage }) => (
  <Dialog
    open={isOpen}
    contentStyle={{ width: "600px" }}
    onRequestClose={handleClose}
  >
    <ModalTitle>{title}</ModalTitle>
    <ModalText>{textMessage}</ModalText>
    <WrapperButtons>
      <Button
        width="150px"
        value={intl.get('geral.modalConfirm.no')}
        onClick={handleClose}
        className="btn"
      />
      <Button
        width="150px"
        type="primary"
        value={intl.get('geral.modalConfirm.yes')}
        onClick={handleConfirm}
        className="btn margin-botao"
      />
    </WrapperButtons>
  </Dialog>
)
