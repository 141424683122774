import Feature from "components/feature";
import * as S from "./styled";
import ontimeLogo from "images/abOntimeLogoPreto.png";
import moment from "moment";
import intl from "react-intl-universal";

export const HeaderInvoicesOrder = ({ data }) => (
  <S.WrapperHeader>
    <S.Logo src={ontimeLogo} />
    <S.FlexColumn>
      <span>{data.OriginDescription}</span>
      <Feature validation={!!data.Cnpj}>
        <span>
          {intl.get("commons.tin")} - {data.Cnpj}
        </span>
      </Feature>
      <Feature validation={!!data.Address}>
        <span>
          {intl.get("commons.address")}: {data.Address}
        </span>
      </Feature>
      <Feature validation={!!data.PostalCode}>
        <span>
          {intl.get("commons.postalCode")}: {data.PostalCode}
        </span>
      </Feature>
      <Feature validation={!!data.City}>
        <span>
          {intl.get("commons.city")}: {data.City}
        </span>
      </Feature>
      <Feature validation={!!data.Country}>
        <span>
          {intl.get("commons.country")}: {data.Country}
        </span>
      </Feature>
      <Feature validation={!!data.PhoneNumber}>
        <span>
          {intl.get("commons.phoneNumber")}: {data.PhoneNumber}
        </span>
      </Feature>
    </S.FlexColumn>
    <S.OrderInfos>
      <S.FlexColumn>
        <span>
          {intl.get("master-data.general-configuration.incoterm")}{" "}
          {data.Incoterm}
        </span>
        <span>
          {intl.get("commons.sapOrder")} {data.SapOrderCode}
        </span>
      </S.FlexColumn>
      <S.FlexColumn>
        <span>
          {intl.get("commons.orderCreation")}{" "}
          {moment(data.OrderCreationDate).format("L")}
        </span>
        <span>
          {intl.get("commons.currency")} {data.Currency}
        </span>
      </S.FlexColumn>
    </S.OrderInfos>
  </S.WrapperHeader>
);
