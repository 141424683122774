import React, { useState, useEffect, useCallback } from "react"
import { Row, Col } from "react-flexbox-grid"
import StyledFilter from "../StyledFilterObsolete"
import { buscarUsuarioGrupoFornecedor } from "./service"
import * as services from "pages/stocks/stocks.service"
import StorePersist from "utils/store-persist"
import Session from 'utils/user-storage'
import { mudarValorCampo, padronizaSaidaId } from "./utils"
import * as S from "./styled"
import intl from "react-intl-universal"
import { SelectBox, Button } from "components/uikit-adapter"
import { supplierStockFilterDefault } from "pages/estoque-fornecedor/utils"


const FiltroEstoqueFornecedor = ({ emitter }) => {
  const filters = StorePersist.getValuesJSON("filtersSupplierStockMulti") ?? supplierStockFilterDefault;
  const [usuarioAmbev] = useState(() => 1)
  const [usuario] = useState(() => Session.get())

  const [IdsGrupoFornecedor, setIdsGrupoFornecedor] = useState([])
  const [grupoFornecedores, setGrupoFornecedores] = useState([])

  const [IdsCarteira, setIdsCarteira] = useState([])
  const [carteiras, setCarteiras] = useState([])

  const [IdsFamilia, setIdsFamilia] = useState([])
  const [familias, setFamilias] = useState([])

  const [IdsFamiliaRotulada, setIdsFamiliaRotulada] = useState([])
  const [familiasRotuladas, setFamiliasRotuladas] = useState([])

  const carregarFiltros = useCallback(async () => {
    const filtrarApenasMeusItens = await StorePersist.getValuesJSON("filterMyItemsOnly")
    buscarGrupoFornecedores()
    buscarCarteiras(filtrarApenasMeusItens)
    buscarFamilias(filtrarApenasMeusItens)
    buscarFamiliasRotuladas(filtrarApenasMeusItens)
  }, [])

  const buscarGrupoFornecedores = useCallback(async () => {
    const response = await buscarUsuarioGrupoFornecedor()
    setGrupoFornecedores(response)
    const _filter = response.filter(x => filters.GrupoFornecedor.includes(x.Id));
    mudarValorCampoGrupoFornecedores(_filter);
  }, [])

  const buscarCarteiras = useCallback(async (filtroPadraoUsuario) => {
    const response = await services.getWallet(filtroPadraoUsuario, usuario.Id)
    setCarteiras(response)
    const _filter = response.filter(x => filters.Carteiras.includes(x.Id));
    mudarValorCampoCarteira(_filter);
  }, [])

  const buscarFamilias = useCallback(async (filtroPadraoUsuario) => {
    const response = await services.getFamily(filtroPadraoUsuario)
    setFamilias(response)
    const _filter = response.filter(x => filters.Familias.includes(x.Id));
    mudarValorCampoFamilias(_filter);
  }, [])

  const buscarFamiliasRotuladas = useCallback(async (filtroPadraoUsuario) => {
    const response = await services.getLabeledFamily(filtroPadraoUsuario, usuario.Id)
    setFamiliasRotuladas(response);
    const _filter = response.filter(x => filters.FamiliasRotuladas.includes(x.Id));
    mudarValorCampoFamiliasRotuladas(_filter);
  }, [])

  const mudarValorCampoGrupoFornecedores = (valoresExternos) => {
    setIdsGrupoFornecedor(mudarValorCampo(valoresExternos))
  }

  const mudarValorCampoCarteira = (valoresExternos) => {
    setIdsCarteira(mudarValorCampo(valoresExternos))
  }

  const mudarValorCampoFamilias = (valoresExternos) => {
    setIdsFamilia(mudarValorCampo(valoresExternos))
  }

  const mudarValorCampoFamiliasRotuladas = (valoresExternos) => {
    setIdsFamiliaRotulada(mudarValorCampo(valoresExternos))
  }
  
  const filter = () => {
    const params = {
      GrupoFornecedor: padronizaSaidaId(IdsGrupoFornecedor),
      Familias: padronizaSaidaId(IdsFamilia),
      FamiliasRotuladas: padronizaSaidaId(IdsFamiliaRotulada),
      Carteiras: padronizaSaidaId(IdsCarteira),
    }

    emitter(params)
  }

  const clean = () => {
    setIdsGrupoFornecedor(undefined)
    setIdsCarteira(undefined)
    setIdsFamilia(undefined)
    setIdsFamiliaRotulada(undefined)
    emitter(supplierStockFilterDefault)
  }

  useEffect(() => {
    carregarFiltros()
  }, [])

  return (
    <StyledFilter>
      <S.Container className="filter-container">
        <S.Fields className="sideFilterFields">
          {
            usuario.TipoUsuario === usuarioAmbev &&
            <Row>
              <Col xs={12} lg={12} md={12}>
                <SelectBox
                  name='grupoFornecedor'
                  className="grupoFornecedorSelect"
                  label={intl.get('commons.supplierGroup')}
                  value={IdsGrupoFornecedor}
                  options={grupoFornecedores}
                  valueKey='Id'
                  labelKey='Nome'
                  onChange={(values) => mudarValorCampoGrupoFornecedores(values)}
                  placeholder={intl.get('commons.supplierGroup')}
                  searchable
                  clearable
                  openOnFocus
                  multi
                />
              </Col>
            </Row>
          }
          <Row>
            <Col xs={12} lg={12} md={12}>
              <SelectBox
                name='carteiras'
                className="carteirasSelect"
                label={intl.get('commons.wallets')}
                value={IdsCarteira}
                options={carteiras}
                valueKey='Id'
                labelKey='Description'
                onChange={(values) => mudarValorCampoCarteira(values)}
                placeholder={intl.get('commons.wallets')}
                searchable
                clearable
                openOnFocus
                multi
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg={12} md={12}>
              <SelectBox
                name='familia'
                className="familiaSelect"
                label={intl.get('commons.family')}
                value={IdsFamilia}
                options={familias}
                valueKey='Id'
                labelKey='Name'
                onChange={(values) => mudarValorCampoFamilias(values)}
                placeholder={intl.get('commons.family')}
                searchable
                clearable
                openOnFocus
                multi
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg={12} md={12}>
              <SelectBox
                name='familiaRotulada'
                className="familiaRotuladaSelect"
                label={intl.get('commons.labeledFamily')}
                value={IdsFamiliaRotulada}
                options={familiasRotuladas}
                valueKey='Id'
                labelKey='Description'
                onChange={(values) => mudarValorCampoFamiliasRotuladas(values)}
                placeholder={intl.get('commons.labeledFamily')}
                searchable
                clearable
                openOnFocus
                multi
              />
            </Col>
          </Row>
        </S.Fields>
        <S.Footer className="footer-filter">
          <Row className='footerFilterContainer no-margin'>
            <Col xs={12}>
              <Row>
                <Col xs={12} lg={12} md={12}>
                  <hr className='filter-divisor' />
                </Col>
              </Row>
              <Row>
                <Col xs={6} lg={6} md={6}>
                  <Button
                    className="filter-search"
                    onClick={filter}
                    type='primary'
                    value={intl.get('filters.search')}
                    data-testid="buscar"
                  />
                </Col>
                <Col xs={6} lg={6} md={6}>
                  <Button
                    type='secondary'
                    value={intl.get('filters.cleanSearch')}
                    onClick={clean}
                    className='clean-search'
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </S.Footer>
      </S.Container>
    </StyledFilter>
  )
}

export default FiltroEstoqueFornecedor
