import React, { useEffect, useState } from 'react'
import intl from 'react-intl-universal'
import { LogModal } from './log-modal'
import CircularProgress from '@material-ui/core/CircularProgress'
import Fetch from 'utils/fetch'
import CircleLine from 'images/checkbox-circle-line.svg'
import { useGlobalContext } from 'hooks/useGlobalContext'
import * as S from './styled'

export const OperationProgress = ({ requests, text }) => {
  const { endOperation } = useGlobalContext()
  const [inProgress, setInProgress] = useState(true)
  const [finished, setFinished] = useState(false)
  const [showTooltip, setShowTooltip] = useState(false)
  const [numberOf, setNumberOf] = useState(0)
  const [log, setLog] = useState([])
  const [showLogModal, setShowLogModal] = useState(false)
  const [progress, setProgress] = useState(0)
  const [transition, setTransition] = useState(false)

  const getAllSuccess = () =>
    numberOf === requests.length

  const getAnyFailed = () =>
    numberOf !== requests.length

  const timer = (fn, value, time) => setTimeout(() => {
    fn(value)
  }, time)

  const closeLogModal = () => {
    endOperation()
    setShowLogModal(false)
  }

  useEffect(() => {
    if (transition) {
      timer(endOperation, null, 500)
    }
    return () => clearTimeout(timer)
  }, [transition])


  useEffect(() => {
    if (getAllSuccess()) {
      setShowTooltip(true)
      timer(setTransition, true, 4500)
      return () => clearTimeout(timer)
    }
  }, [finished])

  useEffect(() => {
    if (log.length > 0 || finished) {
      setShowTooltip(true)
    }
  }, [showTooltip])

  useEffect(() => {
    setShowTooltip(true)
    timer(setShowTooltip, false, 5000)
    return () => clearTimeout(timer)
  }, [])

  useEffect(() => {
    const request = async () => {
      for (let n = 0; n < requests.length; n++) {
        const { method, API, body, errorId } = requests[n]

        try {
          await Fetch[method](API, body)
          setNumberOf(prevState => prevState + 1)

        }
        catch (exception) {
          const { response: { data } } = exception
          const errors = data.Errors.map(({ Detail }) => Detail)
          setLog(prevState => [...prevState, { id: errorId, message: errors }])
        }

        setProgress((((n + 1) / requests.length) * 100))

        if (n === (requests.length - 1)) {
          setInProgress(false)
          setFinished(true)
        }
      }
    }
    request()
  }, [])

  return (
    <React.Fragment>
      <S.Transition transition={transition}>
        <S.Wrapper
          finished={finished}
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
        >
          {
            inProgress &&
            <CircularProgress
              style={{ color: 'white' }}
              size={25}
              value={progress}
              variant="determinate"
            />
          }
          {
            finished &&
            <img
              alt="Círculo Indicando o Processamento da operação"
              src={CircleLine}
            />
          }
        </S.Wrapper>
      </S.Transition>
      <S.Container active={showTooltip}>
        <S.BeakBottom />
        <div className='text__container'>
          <p>{text}</p>
          <p>{numberOf} {intl.get('commons.table.of')} {requests.length}</p>
        </div>
        {(finished && getAnyFailed()) &&
          <S.ErrorLabel
            title={intl.get('commons.logs')}
            className='error-label'
            onClick={() => setShowLogModal(true)}
          >
            {intl.get('commons.seeMore')}
          </S.ErrorLabel>
        }
      </S.Container>
      <LogModal
        logs={log}
        open={showLogModal}
        close={() => closeLogModal()}
      />
    </React.Fragment >
  )
}
