import React, { useState } from 'react'
import intl from 'react-intl-universal'
import { TextSpan } from 'components/text-label'
import Feature from 'components/feature'
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { IconWrapper, ListWrapper, Wrapper } from './styled'
import { validatesArrayHasValue } from 'utils/custom-functions'

const PurchaseOrderList = ({ loadComposition }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  return <Wrapper onClick={() => setIsExpanded(!isExpanded)}>
    <TextSpan style={{ width: '150px', display: 'block' }}>
      {`[${loadComposition.Id}] ${loadComposition.SupplierName} - ${loadComposition.SupplierCode}`} 
      </TextSpan>
    {!!validatesArrayHasValue(loadComposition.OrderPoNumbers) &&
      <>
        <TextSpan title='' style={{ justifyContent: 'space-between' }}>
          #{intl.get('commons.po')}: {loadComposition.OrderPoNumbers[0]}
          <IconWrapper
            isExpanded={isExpanded}
          >
            <Feature validation={loadComposition.OrderPoNumbers.length > 1}>
              <ExpandMoreIcon data-testid='show-po'/>
            </Feature>
            <Feature validation={loadComposition.OrderPoNumbers.length <= 1}>
              <span style={{ height: '24px' }} />
            </Feature>
          </IconWrapper>
        </TextSpan>
        <Feature validation={isExpanded}>
          <ListWrapper>
            {loadComposition.OrderPoNumbers.slice(1).map(_po => (
              <TextSpan>{_po}</TextSpan>
            ))}
          </ListWrapper>
        </Feature>
      </>
    }
  </Wrapper>
}

export default PurchaseOrderList;
