import { formatNumber } from "utils/format";
import { LoadAccordionList } from "./load-accordion-list"
import { AccordionHeader, BlankSpace, SubTitle, Title, Warning, WrapperGeneral, WrapperAccordions, WrapperTable } from "./styled"
import intl from 'react-intl-universal'
import HintList from 'components/hint-list';
import MapToHTML from 'components/map-to-html';
import { PartialLoadsList } from "./partial-loads-list";
import { useLoadBuildingContext } from "features/load-building/context";

export const LoadBuildingFlowReviewLoads = ({ loadSettingsData }) => {
  const { loadsOptimized } = useLoadBuildingContext();

  if (!loadsOptimized?.LoadCompositions?.length && !loadsOptimized?.LoadNotFull?.length) {
    return null;
  }

  const validateTruckSize = loadsOptimized?.LoadCompositions.map((x, index) => {
    if (x.LotSize < Number(loadSettingsData?.TruckSize)) return index + 1;
  }).filter(y => y)?.join(',');

  const getTotalLotSize = () => {
    const totalLotSizeLoadCompositions = loadsOptimized?.LoadCompositions.reduce(
      (accumulator, currentValue) => accumulator + currentValue.LotSize,
      0,
    );

    const totalLotSizeLoadNotFull = loadsOptimized?.LoadNotFull.reduce(
      (accumulator, currentValue) => accumulator + currentValue.LotSize,
      0,
    );

    const totalLotSize = totalLotSizeLoadCompositions + totalLotSizeLoadNotFull;

    const unitOfMeasurementLot = loadsOptimized?.LoadCompositions[0]?.Orders[0].UnitOfMeasurementLot ?? loadsOptimized?.LoadNotFull[0]?.Orders[0].UnitOfMeasurementLot;

    return `${formatNumber(totalLotSize)} ${unitOfMeasurementLot}`;
  }

  return (
    <WrapperGeneral>
      <div className="content">
        {loadsOptimized?.LoadCompositions?.length > 0 && <>
          <Title>{intl.get('load-building.page-flow.step-one.title')}</Title>
          <SubTitle>{intl.get('load-building.page-flow.step-one.sub-title')}</SubTitle>
          {validateTruckSize && <Warning data-testid="warning-truck-not-fully">{intl.get('load-building.page-flow.step-one.warningTruckSize')} ({validateTruckSize}).</Warning>}
          <WrapperAccordions isThereLoadNotFull={loadsOptimized?.LoadNotFull?.length > 0}>
            <WrapperTable data-testid="distributed-volumes-table">
              <AccordionHeader data-testid="header">
                <tr>
                  <th />
                  <th data-testid="header-origin">{intl.get("stocks.suggestionsListItem.origin")}</th>
                  <th data-testid="header-destination">{intl.get("commons.destiny")}</th>
                  <th data-testid="header-volume">{intl.get("commons.orderVolume")}</th>
                  <th data-testid="header-material">{intl.get("commons.material")}</th>
                  <th data-testid="header-lot">{intl.get('commons.lot')}</th>
                  <th data-testid="header-quantity">{intl.get('stocks.qty')}</th>
                  <th data-testid="header-pickup-date">{intl.get("commons.pickup")}</th>
                  <th data-testid="header-delivery-date">{intl.get("commons.delivery")}</th>
                  <th data-testid="header-delivery-time">{intl.get("commons.deliveryTime")}</th>
                  <th data-testid="header-door">{intl.get("commons.door")}</th>
                  <th></th>
                  <th className="btnDelete"></th>
                </tr>
              </AccordionHeader>
              <BlankSpace height='8px' />
              {
                loadsOptimized.LoadCompositions.map((x, i) => (
                  <LoadAccordionList key={i} index={i} data={x} itemNumber={i + 1} listLabel={"LoadCompositions"} />
                ))
              }
            </WrapperTable >
          </WrapperAccordions>
        </>}

        <PartialLoadsList />
      </div>
      <div className="div-quantidade-lot" data-testid='load-building-total-quantity'>
        <HintList applyHover={false} itens={[`${intl.get('commons.lotTotal')}: ${getTotalLotSize()}`]} label={`${intl.get('commons.lotTotal')}: ${getTotalLotSize()}`}>
          <MapToHTML itens={[`${intl.get('commons.lotTotal')}: ${getTotalLotSize()}`]} />
        </HintList>
      </div>
    </WrapperGeneral>
  )
}