import React, { useState } from 'react'
import intl from 'react-intl-universal'
import { Button } from "components/uikit-adapter/index"
import IconFilterGray from 'images/icn-filter-gray.svg';
import IconFilterBlue from 'images/icn-filter-blue.svg';
import { Layout } from '@hbsis.uikit/react';
import * as S from './styled'
import Filter from '../filter';

const ButtonFilterSuggestion = ({ isFiltered, stopLoading, startLoading }) => {
  const [openForms, setOpenForm] = useState(false);
  const handleClose = () => setOpenForm(false);
  const getFilterIcon = () =>{ 
    if(localStorage.getItem("THEME") != 1 ){
      if(isFiltered) 
       return IconFilterBlue 
      else
       return IconFilterGray;
    }else
      return undefined
  }
  return (
    <S.Wrapper>
      <Button
        onClick={() => setOpenForm(prevState => !prevState)}
        type='default'
        value={intl.get('filters.filter')}
        icon={getFilterIcon()}
      />
      <Layout.Sidebar width="450px"
        visible={openForms}
        triggerClose={handleClose}
        background="var(--color-contrast-white)"
        offsetTop="96px"
        side="right"
        block
        float
      >
        <Filter
          handleClose={handleClose}
          startLoading={startLoading}
          stopLoading={stopLoading}
        />
      </Layout.Sidebar>
    </S.Wrapper>
  )
}

export default ButtonFilterSuggestion;
