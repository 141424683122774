import React from "react";
import { Row, Col } from "react-flexbox-grid";
import { CheckBox } from "@hbsis.uikit/react";
import PropTypes from "prop-types";
import iconeMais from 'images/adicionar.svg';
import iconeMenos from 'images/menos.svg';
import "./index.css";

import intl from 'react-intl-universal'
import { RoundButton } from "components/round-button";

const DualList = ({
  title1,
  title2,
  itemsList1,
  itemsList2,
  onLinking,
  onUnlink,
  checkbox,
  changeCheckbox,
  property,
}) => {

  return (
    <Row>
      <Col xs={6}>
        <div className="listagem-associacoes__table">
          <Row className="listagem-associacoes__table-header">
            <Col xs={12}>
              <strong>{title1}</strong>
            </Col>
          </Row>

          <div className="listagem-associacoes__table-body">
            {itemsList1.map((item, index) => (
              renderAssociations(item, index, property, onLinking, checkbox)
            ))}
          </div>
        </div>
      </Col>

      <Col xs={6}>
        <div className="listagem-associacoes__table">
          <Row className="listagem-associacoes__table-header">
            <Col xs={12}>
              <strong>{title2}</strong>
            </Col>
          </Row>
          <div className="listagem-associacoes__table-body">
            {itemsList2.map((item, index) => (
              renderAssociated(item, index, property, onUnlink, checkbox, changeCheckbox)
            ))}
          </div>
        </div>
      </Col>
    </Row>
  )
}

const renderAssociations = (item, index, property, onLinking, checkbox) => {
  const propertyName = generatePropertyName(item);
  return (
    <Row
      className="listagem-associacoes__table-rows"
      key={index}
      style={
        checkbox
          ? { padding: "22px" }
          : { padding: "20px" }
      }
    >
      <Col
        xs={12}
        className="listagem-associacoes__table-cols">
        <div className="text">
          <RoundButton iconHeight='15px' className="onLinking" onClick={() => onLinking(item)}>
            <img title="Vincular" className="icone" src={iconeMais} alt="add-icon" />
          </RoundButton>
          <span>{propertyName}</span>
        </div>
      </Col>
    </Row>
  )
}

const renderAssociated = (item, index, property, onUnlink, checkbox, changeCheckbox) => {
  const propertyName = generatePropertyName(item);
  return (
    <Row
      key={index}
      className="listagem-associacoes__table-rows"
    >
      <Col
        xs={12}
        className="listagem-associacoes__table-cols"
      >
        <div className="text">
          <RoundButton iconHeight='15px' className="onUnlink" onClick={() => onUnlink(item)}>
            <img title="Desvincular" className="icone" src={iconeMenos} alt="remove-icon" />
          </RoundButton>
          <span>{propertyName}</span>

          {checkbox && (
            <span style={{ float: "right" }}>
              <CheckBox
                clearable
                data-testid="change-checkbox"
                name={item.id}
                active={item.ativo}
                className="listagem-associacoes__table-checkbox"
                onChange={() =>
                  changeCheckbox(item)
                }
              />
            </span>
          )}
        </div>
      </Col>
    </Row>
  )
}

const generatePropertyName = (item) => {
  return intl.getInitOptions().currentLocale === 'en-US' ? item.translation: item.name;
}

DualList.propTypes = {
  title1: PropTypes.string,
  title2: PropTypes.string,
  checkbox: PropTypes.bool,
  itemsList1: PropTypes.array,
  itemsList2: PropTypes.array,
  onLinking: PropTypes.func,
  onUnlink: PropTypes.func,
  changeCheckbox: PropTypes.func,
};

DualList.defaultProps = {
  title1: "",
  title2: "",
  checkbox: false,
  itemsList1: [],
  itemList2: [],
};

export default DualList;
