import React from 'react'
import * as S from '../styled'
import moment from 'moment'
import { Tag } from '../tag'
import PurchaseOrderList from '../purchaseOrdersList'

const CardInNegotiation = ({ data, handleClick, idCompositionSelected }) => (
  <>
    {data.map(composicao => (
      <S.Wrapper
        key={composicao.Id}
        background='var(--color-contrast-white)'
        color='#E7712F'
        isSelected={composicao.Id === idCompositionSelected}
        data-testid={`in-negotiation-slot-${composicao.Id}`}>
        <S.Hora isSelected={composicao.Id === idCompositionSelected}>
          {composicao && <span> {moment(composicao.Hour, "HH:mm:ss").format("HH:mm")}</span>}
        </S.Hora>
        <Tag
          onClick={() => handleClick(composicao)}
          status={composicao.Situation}
        />
        <S.Content color='#E7712F'>
          <PurchaseOrderList loadComposition={composicao} />
        </S.Content>
      </S.Wrapper>
    ))}
  </>
)

export default CardInNegotiation;
