import { Component } from 'react'
import { Row, Col } from 'react-flexbox-grid'
import { Button } from 'components/uikit-adapter/index'
import moment from 'moment'
import Dialog from 'material-ui/Dialog'
import Step from 'components/steps/step'
import Steps from 'components/steps'
import Loading from 'components/center-loading'
import InputFile from 'components/input-file'
import { downloadFile } from 'utils/custom-functions'
import ImageAlert from 'images/icn-small-alert.svg'
import ImageError from 'images/icn-small-error.svg'
import ImageSuccess from 'images/icn-small-success.svg'
import intl from 'react-intl-universal'
import StyledImportExporter from './styledImportExporter'
import { formatErrorMessage } from 'utils/handle-error';
import * as services from './importer-exporter.service';

class ImportExport extends Component {

  constructor(props) {
    super(props)

    this.state = {
      stepSelected: 0,
      xls: [],
      labelStep3: intl.get('master-data.general.stockPolicyModal.update'),
      listaResultados: [],
      importadoComSucesso: false,
      botaoAnteriorAtivo: true,
      isFetching: false,
      nadaImportado: false,
    }
  }

  updateStockPolicy = async(base64) => {
    try {        
      const response = await services.updateStockPolicy(base64);
      this.tratarSucesso(response);
    }
    catch(e) {        
      this.tratarErro(e);        
    }  
  }

  atualizar = () => {
    const reader = new FileReader();

    reader.addEventListener('load', () => {            
      const base64 = window.btoa(reader.result)
      this.updateStockPolicy(base64)
    });    
    
    reader.readAsBinaryString(this.state.xls[0]);    
  }

  tratarSucesso = (data) => {
    this.setState({
      labelStep3: data.AllSuccess && data.Results.length > 0 ? intl.get('master-data.general.stockPolicyModal.updated') : intl.get('master-data.general.stockPolicy.notUpdated') ,
      listaResultados: data.Results,
      isFetching: false,
      importadoComSucesso: data.AllSuccess,
      botaoAnteriorAtivo: !data.AllSuccess || data.Results.length === 0,
      nadaImportado: data.Results.length === 0
    })
  }

  tratarErro = (erro) => {
    this.setState({
      labelStep3: intl.get('master-data.general.stockPolicyModal.notUpdated'),
      botaoAnteriorAtivo: true,
      isFetching: false,
      listaResultados: [{
        Type: 0,
        Message: `${intl.get('exportImportModal.massTrading.existError')}. ${erro}`
      }],
      nadaImportado: false,
    })
  }

  verificarStepParaAtualizar = () => {        
    if (this.state.stepSelected === 2) {      
      this.setState({
        labelStep3: intl.get('master-data.general.stockPolicyModal.updating'),
        isFetching: true,
      })
      this.atualizar()
    }
  }

  changeStepNext = () => {    
    this.setState(prevState => ({
      stepSelected: prevState.stepSelected + 1,
    }), () => this.verificarStepParaAtualizar())
  }

  changeStepPrev = () => {
    this.setState(prevState => ({
      stepSelected: prevState.stepSelected - 1,
      labelStep3: intl.get('master-data.general.stockPolicyModal.update'),
      botaoAnteriorAtivo: true,
      importadoComSucesso: false,
      listaResultados: [],
      nadaImportado: false,
    }))
  }

  close = () => {
    this.setState({
      stepSelected: 0,
      labelStep3: intl.get('master-data.general.stockPolicyModal.update'),
      importadoComSucesso: false,
      xls: [],
      botaoAnteriorAtivo: true,
      nadaImportado: false,
      listaResultados: [],
    }, () => this.props.handleClose());
  }

  downloadArquivo = async () => {
    this.startFetching()
    try{
      const data = await services.downloadStockPolicy();
      const datafile = moment(new Date()).format('YYYYMMDD_HHmmss')
      downloadFile(`${intl.get('commons.stockPolicy')}_${datafile}.csv`, 'csv', data)
    } catch (e) {
      this.proccessErro(e)
    } finally {
      this.stopFetching()
    }
  }

  startFetching = () => {
    this.setState({
      isFetching: true
    })
  }

  stopFetching = () => {
    this.setState({
      isFetching: false
    })
  }

  proccessErro = (error) => {
    this.setState({
      showMsg: true,
      textMsg: formatErrorMessage(error)
    })
  }

  getImage = (type) => {
    if (type === 0) {
      return (ImageError)
    } else if (type === 1) {
      return (ImageAlert)
    } else {
      return ImageSuccess
    }
  }  

  getColorBorder = (type) => {
    if (type === 0) {
      return 'red'
    } else if (type === 1) {
      return 'yellow'
    } else {
      return 'green'
    }
  }

  render() {
    const {
      open,
    } = this.props

    const exibirListaResultado = this.state.listaResultados.length > 0

    return (
      <Dialog
        title={intl.get('master-data.general.stockPolicyModal.title')}
        contentStyle={{ width: '700px' }}
        open={open}
      >
        <StyledImportExporter>
        <Loading isLoading={this.state.isFetching} />
          <Steps
            blockedSteps={true}
            selected={this.state.stepSelected}
            onChangeStep={() => { }}
          >
            <Step completed={this.state.stepSelected > 0} label={intl.get('master-data.general.stockPolicyModal.current')}>
              <div className="content">
                <span className="titulo">
                {intl.get('master-data.general.stockPolicyModal.currentDescription')}
                  </span>
                <Button
                  type="secondary"
                  className="button-download"
                  onClick={this.downloadArquivo}
                  value="Download"
                />
              </div>
            </Step>
            <Step completed={this.state.stepSelected > 1} label={intl.get('master-data.general.stockPolicyModal.new')}>
              <div className="content">
                <span className="titulo">
                {intl.get('master-data.general.stockPolicyModal.newDescription')}
                </span>
                <InputFile
                  name="xls"
                  placeholder="XLSe"
                  className="file-ajust"
                  label={intl.get('master-data.general.stockPolicyModal.archive')}
                  accept=".csv"
                  filename={
                    this.state.xls.length > 0 ? (
                      this.state.xls[0].name
                    ) : ("")
                  }
                  onChange={e => {                                      
                    e.preventDefault()
                    this.setState({
                      xls: [...e.target.files]
                    });                    
                  }}
                />
              </div>
            </Step>
            <Step completed={this.state.stepSelected > 2} label={this.state.labelStep3}>
              <div className="content">
                {!exibirListaResultado && !this.state.nadaImportado && <span className="titulo">
                  {intl.get('master-data.general.stockPolicyModal.messageUpdating')}
                </span>}
                {!exibirListaResultado && this.state.nadaImportado && <span className="titulo">
                 {intl.get('master-data.general.stockPolicyModal.withoutUpdate')}
                </span>}
                {exibirListaResultado &&
                  <Col xs={12} md={12} lg={12} className="lista-resultados">
                    <Row className="rowListHeader">
                      <span>{this.state.importadoComSucesso ? intl.get('master-data.general.stockPolicyModal.messageUpdatingSuccess') : intl.get('master-data.general.stockPolicyModal.logError') }</span>
                    </Row>
                    <Row className="lista-mensagens">
                      <Col xs={12} md={12} lg={12} className="itens-mensagens">
                        {this.state.listaResultados.map((value, i) => (
                          <Row key={i} className={`rowList ${this.getColorBorder(value.Type)}`} style={{ borderBottom: '1px dashed #98c1dc' }}>
                            <Col xs={1} md={1} lg={1} className="align-center"><img src={this.getImage(value.Type)} height="20px" alt="Tipo"/></Col>
                            <Col xs={11} md={11} lg={11}>{value.Message}</Col>
                          </Row>
                        ))}
                      </Col>
                    </Row>
                  </Col>}
              </div>
            </Step>
          </Steps>
          <div className="content-footer">
            <Button
              onClick={this.close}
              className="button-footer"
              type="default"
              value={intl.get('master-data.general.stockPolicyModal.close')}
            />
            <div className="btn-next-prev">
              {this.state.stepSelected > 0 && this.state.botaoAnteriorAtivo && <Button
                onClick={this.changeStepPrev}
                className="button-footer"
                type="primary"
                value={intl.get('master-data.general.stockPolicyModal.previous')}
              />}
              {this.state.stepSelected < 2 && <Button
                onClick={this.changeStepNext}
                className="button-footer btn-next"
                type="primary"
                value={intl.get('master-data.general.stockPolicyModal.next')}
                disabled={this.state.stepSelected === 1 && !this.state.xls.length}              
              />}
            </div>
          </div>
        </StyledImportExporter>
      </Dialog>
    )
  }
}

export default ImportExport
