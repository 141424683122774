import Paper from 'material-ui/Paper'
import Divisor from '../../../../components/card/body-card/card-divisor'
import intl from 'react-intl-universal'
import StatusCard from '../../../../components/card/body-card/status-card'
import * as S from './styled'
import { formatNumber, formatWithoutLeadingZeros } from 'utils/format'
import { getCardName } from '../../utils'
import { parseFloatByLocale } from "locales/utils";
import Feature from 'components/feature'
import { TextSpan } from 'components/text-label'
import moment from 'moment'
import { formatHourTimeOrDefault } from 'utils/delivery-time'
import { WarningIcon } from 'components/icons/icn-warning-status-projects.icon'

const CardOrderSuggestionIntoLoadComposition = ({ data, materialNumbers, handleClick, openModal, codeDescription }) => {
  const tipo = {
    Etapa: 1,
    Prioridade: data.IdPedido ? 1 : 0,
    Titulo: getCardName(data)
  }

  return (
    <S.Wrapper isDeleted={data.IsDeleted}>
      <Paper zDepth={1} className='card-shop-mester' style={{ height: `250px` }}>
        <div className='card-click-area' data-testid={`card-order-code-${data.IdPedido}`}>
          {materialNumbers.filter(x => x == data.NumeroMaterial).length > 1 &&
            <div className='warning-waiting-return'>
              <WarningIcon width='10px' height='10px' color='#AE00FF' />{intl.get('commons.duplicatedMaterial')}
            </div>
          }
          <div className='header-card'>
            <StatusCard
              tipo={tipo}
              material={formatWithoutLeadingZeros(data.Material)}
            />
          </div>
          <Divisor />
          <div className='body-card'>
            <div className='card-label'>
              <TextSpan>{intl.get('loadComposition.building.orderAmmount')}</TextSpan>
              <TextSpan title={`${formatNumber(data.Quantidade)}${data.QuantidadeUnidadeMedida}-${data.TotalPallets}${data.LotesUnidadeMedida}`}
                className='description-bold' data-testid={`order-code-value-${data.IdPedido}`}>
                {formatNumber(data.Quantidade)}{data.QuantidadeUnidadeMedida} {` - `}
                <TextSpan className='description-bold' style={{ color: 'var(--color-action-default)' }}>
                  {Math.ceil(parseFloatByLocale(data.TotalPallets))}{data.LotesUnidadeMedida}
                </TextSpan>
              </TextSpan>
            </div>
            <Feature validation={!!data.EstoqueAtual}>
              <div className='card-label'>
                <TextSpan>{intl.get('stocks.timeline.currentInventory')}</TextSpan>
                <TextSpan className='description-bold' data-testid={`order-current-stock-code-${data.IdPedido}`}> {formatNumber(data.EstoqueAtual)}{data.EstoqueAtualUnidadeMedida}</TextSpan>
              </div>
            </Feature>
          </div>
          <div>
            <Divisor />
            <div className='body-card'>
              {data.CodigoPedidoSap &&
                <div className='card-label'>
                  <TextSpan>{codeDescription}</TextSpan>
                  <TextSpan className='description-bold' data-testid={`order-wms-code-${data.IdPedido}`}>{data.CodigoPedidoSap}</TextSpan>
                </div>
              }
              <div className='card-label'>
                <TextSpan>{intl.get('commons.originalDeliveryTime')}</TextSpan>
                <TextSpan className='description-bold'>{formatHourTimeOrDefault(data.DeliveryTime)}</TextSpan>
              </div>
            </div>
            <Divisor />
            <div className='body-card'>
              <div className='card-label'>
                <TextSpan>{intl.get('commons.colDate')}</TextSpan>
                <TextSpan className='description-bold'> {moment(data.DataColeta).format("L")}</TextSpan>
              </div>
              <div className='card-label'>
                <TextSpan>{intl.get('commons.deliveryDate')}</TextSpan>
                <TextSpan className='description-bold'> {moment(data.DataEntrega).format("L")} </TextSpan>
              </div>
            </div>
            <div className='body-card'>
              <S.ActionsWrapper className='card-label'>
                <Feature validation={!data.IsNew}>
                  <S.ButtonEdit
                    type='primary'
                    value={intl.get('stocks.timeline.editOrder')}
                    onClick={openModal}
                    className={`edit-card-${data.IdPedido}`}
                  />
                </Feature>
                <S.Button
                  className={`remove-card-${data.IdPedido}`}
                  type='default'
                  value={intl.get('loadComposition.building.removeLoad')}
                  onClick={handleClick}
                />
              </S.ActionsWrapper >
            </div>
          </div >
        </div >
      </Paper >
    </S.Wrapper >
  )
}

export default CardOrderSuggestionIntoLoadComposition
