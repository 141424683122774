import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-flexbox-grid";
import { Button } from 'components/uikit-adapter';
import { SelectBox } from "components/uikit-adapter/index"
import Fetch from 'utils/fetch';
import Session from 'utils/user-storage';
import intl from 'react-intl-universal';
import { formatErrorMessage } from 'utils/handle-error';
import { Switcher } from "components/toggle";
import { isLoadCompositionFlowSupplierSide } from "utils/validations-general-settings";

import "./alterar-pedido.css";

class AlterarPedido extends Component {
  constructor(props) {
    super(props);

    this.state = {
      materialAtivo: true,
      openForm: false,
      materialNovo: null,
      materiais: []
    };
  }

  onChangeValue = (prop, value) => {
    this.setState({
      [prop]: value
    });
  };

  sendChange = () => {
    const user = Session.get();
    const { materialNovo } = this.state;

    if (!materialNovo) {
      this.props.handleFeedback(intl.get('stocks.timeline.feedback.selectMaterial'))
    }
    else
    {
        Fetch.post('/timeline/enviarSolicitacaoAlteracaoPedido',
        {
            IdPedido: this.props.idPedido,
            IdUsuario: user.Id,
            IdMaterialAnterior: this.props.materialAnterior,
            IdMaterialNovo: materialNovo.Id
        })
        .then(() => {
          this.props.handleFeedback(intl.get('stocks.timeline.feedback.changeOrderSend'))
        })
        .catch((retorno) => { this.props.handleFeedback(retorno.response.data.Message) })
        .finally(() => {
          this.props.handleFetch(false)
          this.props.refreshTimeLine()
        })

        this.onChangeValue("openForm", false);
     }
  };

  cancelChange = () => {
    this.onChangeValue("openForm", false);
  };

  openFormChangeOrder = () => {
    this.onChangeValue("openForm", true);
    this.searchMateriais(this.props.idPedido);
  };

  searchMateriais = (idPedido) => {
    this.props.handleFetch()
    const user = Session.get()
    Fetch.get(`/material/buscarMateriaisPorPedido/${idPedido}/${user.Id}`)
      .then((response) => this.processMateriais(response.data))
      .catch((e) => this.proccessErro(e))
      .finally(this.props.handleFetch(false));
  }

  processMateriais = (materiais) => {
    this.setState({
      materiais
    })
  }

  proccessErro = (exception) => {
    this.props.handleFeedback(intl.get('stocks.timeline.feedback.changeOrderSend'), (exception.response.status === 400) ? formatErrorMessage(exception) : intl.get('stocks.timeline.feedback.serverError'))
  }

  materialAtivo = () => {
    this.setState(prevState => ({
      materialAtivo: !prevState.materialAtivo
    }))
  }

  formNoReadOnly = () => {
    let { materialAtivo, openForm, materiais, materialNovo } = this.state;
    let materiaisFiltrados = (materialAtivo && materiais.length > 0)  ? materiais.filter(m => m.Ativo === materialAtivo) : materiais;

    return (
      <div className="alterar-pedido-negotiation">
        {openForm && (
          <div>
              <div className="alterar-pedido-negotiation-line">
                <Row>
                  <Col xs={4}>
                    <span className="alterar-pedido-label">{intl.get('stocks.timeline.onlyActive')}</span>
                  </Col>
                  <Col xs={2}>
                    <Switcher
                      name="MaterialAtivo"
                      value={materialAtivo}
                      onChange={this.materialAtivo}
                    />
                  </Col>
                </Row>
              </div>

              <div className="alterar-pedido-negotiation-line">
                <Row>
                  <Col xs={12}>
                    <SelectBox
                      name="materiais"
                      label={intl.get('stocks.suggestionsListItem.material')}
                      placeholder={intl.get('stocks.timeline.selectMaterial')}
                      value={materialNovo}
                      options={materiaisFiltrados}
                      onChange={value => this.onChangeValue("materialNovo", value)}
                      valueKey="Id"
                      labelKey="Descricao"
                      searchable
                      openOnFocus
                    />
                  </Col>
                </Row>
              </div>

              <div className="alterar-pedido-negotiation-line">
                <div className="alterar-pedido-event-action-offer">
                  <Row>
                    <Col xs={6}>
                      <Button
                        value={intl.get('geral.buttonsDefault.cancel')}
                        onClick={this.cancelChange}
                        type="secondary"
                        className="button"
                      />
                    </Col>
                    <Col xs={6}>
                      <Button
                        value={intl.get('geral.buttonsDefault.send')}
                        onClick={this.sendChange}
                        type="primary"
                        className="button"
                      />
                    </Col>
                  </Row>
                </div>
              </div>
          </div>
        )}

        {!openForm && isLoadCompositionFlowSupplierSide() && (
            <div className="alterar-pedido-negotiation-line">
              <Row>
                <Col xs={12}>
                  <Button
                    value={intl.get('stocks.timeline.changeOrder')}
                    onClick={this.openFormChangeOrder}
                    type="secondary"
                    className="button"
                  />
                </Col>
              </Row>
            </div>
        )}
      </div>
    );
  };

  render() {
    const { descricaoMaterialAnterior, descricaoMaterialNovo, readOnly, handleCancelar, idPedido } = this.props;

    return (
      <div>
        {!readOnly && this.formNoReadOnly()}
        {readOnly && (
          <div style={{ marginLeft: '40px', marginBottom: '10px' }}>
              <Row>
                <Col xs={12} className="alterar-pedido-read-column">
                  <span>{intl.get('stocks.timeline.previous')}</span>
                  <span>{descricaoMaterialAnterior}</span>
                </Col>
              </Row>

              <Row>
                <Col xs={12} className="alterar-pedido-read-column">
                  <span>{intl.get('stocks.timeline.new')}</span>
                  <span>{descricaoMaterialNovo}</span>
                </Col>
              </Row>

              <Row>
                  <Col  xs={12}>
                    <Button
                      value={intl.get('geral.buttonsDefault.cancel')}
                      onClick={() => handleCancelar(idPedido)}
                      type="secondary"
                      className="button"
                    />
                  </Col>
              </Row>
            </div>
        )}
      </div>
    );
  }
}

AlterarPedido.propTypes = {
  idPedido: PropTypes.number,
  materialAnterior: PropTypes.number,
  materialAtivo: PropTypes.bool,
  readOnly: PropTypes.bool,
  handleFetch: PropTypes.func,
  handleFeedback: PropTypes.func,
  refreshTimeLine: PropTypes.func,
  handleCancelar: PropTypes.func
};

AlterarPedido.defaultProps = {
  readOnly: false
};

export default AlterarPedido;
