import React, { useState } from 'react'
import intl from 'react-intl-universal'
import { Row, Col } from 'react-flexbox-grid'
import { Layout } from "@hbsis.uikit/react"
import { Button } from 'components/uikit-adapter'
import { getCheckboxsColumn } from './getCheckboxsColumn'
import { OptionsMedidas, OptionsPeriodFormat, OptionsPeriodTotal } from '../../../models/viewModeModel'
import OptionGroup from 'components/option-group'
import IconEmpty from "images/iconEmpty.icon"
import { useBottlesContext } from '../../../context'
import * as S from './styled'

export const FilterViewMode = ({ open, close }) => {
  const {
    viewMode,
    setViewMode,
    periodTotal,
    setPeriodTotal,
    optionsMedida,
    setOptionsMedida,
    checkboxs,
    setCheckboxs,
    setFilter,
  } = useBottlesContext()

  /**
   * Pode ser melhorado no futuro, caso hajam mais filtros
   * que necessitem atualizar a tela atráves de requisições
   * para o backend.
   */
  const checkFilters = () => {
    /**
     * Atualiza filtros para ser responsivo e real time com a tela.
     */

    setViewMode(
      optionsPeriodFormat.optionOne.isChecked ?
        optionsPeriodFormat.optionOne.value :
        optionsPeriodFormat.optionTwo.value
    )

    setPeriodTotal(
      optionsPeriodTotal.optionOne.isChecked ?
        optionsPeriodTotal.optionOne.value :
        optionsPeriodTotal.optionTwo.value
    )

    setOptionsMedida(
      optionsPeriodMedida.optionOne.isChecked ?
        optionsPeriodMedida.optionOne.value :
        optionsPeriodMedida.optionTwo.value
    )

    const actual = optionsPeriodFormat.optionOne.isChecked ? 1 : 2
    const past = viewMode

    if (actual !== past) {
      setFilter(prevState => ({
        ...prevState,
        viewMode: optionsPeriodFormat.optionOne.isChecked ? false : true
      }))
    }
    close()
  }

  const [optionsPeriodFormat, setOptionsPeriodFormat] = useState({
    id: 1,
    optionOne: {
      name: intl.get('bottles.filter.view.daily'),
      value: OptionsPeriodFormat.Daily,
      isChecked: viewMode === OptionsPeriodFormat.Daily
    },
    optionTwo: {
      name: intl.get('bottles.filter.view.monthly'),
      value: OptionsPeriodFormat.Monthly,
      isChecked: viewMode === OptionsPeriodFormat.Monthly
    }
  })

  const [optionsPeriodTotal, setOptionsPeriodTotal] = useState({
    id: 2,
    optionOne: {
      name: intl.get('bottles.filter.view.hide'),
      value: OptionsPeriodTotal.Hide,
      isChecked: periodTotal === OptionsPeriodTotal.Hide
    },
    optionTwo: {
      name: intl.get('bottles.filter.view.show'),
      value: OptionsPeriodTotal.Show,
      isChecked: periodTotal === OptionsPeriodTotal.Show
    }
  })

  const [optionsPeriodMedida, setOptionsPeriodMedida] = useState({
    id: 3,
    optionOne: {
      name: intl.get('bottles.filter.view.MedidaMilheiros'),
      value: OptionsMedidas.Milheiros,
      isChecked: optionsMedida === OptionsMedidas.Milheiros
    },
    optionTwo: {
      name: intl.get('bottles.filter.view.MedidaHectolitros'),
      value: OptionsMedidas.Hectolitros,
      isChecked: optionsMedida === OptionsMedidas.Hectolitros
    }
  })

  const setOptions = (prevState, value) => (
    {
      ...prevState,
      optionOne: {
        ...prevState.optionOne,
        isChecked: prevState.optionOne.value === parseInt(value)
      },
      optionTwo: {
        ...prevState.optionTwo,
        isChecked: prevState.optionTwo.value === parseInt(value)
      }
    }
  )

  const updateOptionsPeriodFormat = (periodFormatValue) => {
    setOptionsPeriodFormat(prevState => (setOptions(prevState, periodFormatValue)))
  }

  const updateOptionsPeriodTotal = (periodTotalValue) => {
    setOptionsPeriodTotal(prevState => (setOptions(prevState, periodTotalValue)))
  }

  const updateOptionsPeriodMedida = (periodMedidaValue) => {
    setOptionsPeriodMedida(prevState => (setOptions(prevState, periodMedidaValue)))
  }

  const updateCheckboxs = (name, value) => {
    setCheckboxs(prevState => ({
      ...prevState,
      [name]: value
    }))
  }

  return (
    <Layout.Sidebar
      width="350px"
      visible={open}
      triggerClose={close}
      background="var(--color-contrast-white)"
      offsetTop="96px"
      side="right"
      block
      float
      icon={IconEmpty}
    >
      <S.Wrapper>
        <Row>
          <Col xs={12}>
            <S.Title>{intl.get('bottles.filter.edit.viewConfig')}</S.Title>
          </Col>
        </Row>
        <Row style={{ marginTop: '22px' }}>
          <Col xs={12}>
            <S.SubTitle>{intl.get('bottles.filter.view.formatPeriod')}</S.SubTitle>
          </Col>
          <Col xs={12}>
            <OptionGroup
              handleSelect={event => updateOptionsPeriodFormat(event.currentTarget.value)}
              options={optionsPeriodFormat}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: '22px' }}>
          <Col xs={12}>
            <S.SubTitle>{intl.get('bottles.filter.view.totalPeriod')}</S.SubTitle>
          </Col>
          <Col xs={12}>
            <OptionGroup
              handleSelect={event => updateOptionsPeriodTotal(event.currentTarget.value)}
              options={optionsPeriodTotal}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: '22px' }}>
          <Col xs={12}>
            <S.SubTitle>{intl.get('bottles.filter.view.data')}</S.SubTitle>
          </Col>
          <Col xs={12}>
            {getCheckboxsColumn(checkboxs, updateCheckboxs)}
          </Col>
        </Row>
        {
          checkboxs.Consumption &&
          <Row style={{ marginTop: '22px' }}>
            <Col xs={12}>
              <S.SubTitle>{intl.get('bottles.filter.view.UnidadeMedidaConsumo')}</S.SubTitle>
            </Col>
            <Col xs={12}>
              <OptionGroup
                handleSelect={event => updateOptionsPeriodMedida(event.currentTarget.value)}
                options={optionsPeriodMedida}
              />
            </Col>
          </Row>
        }
        <Row style={{ marginTop: '22px' }}>
          <Col xs={12}>
            <Button
              type="default"
              htmlType="button"
              value={intl.get('commons.okay')}
              onClick={checkFilters}
            />
          </Col>
        </Row>
      </S.Wrapper>
    </Layout.Sidebar >
  )
}

