const DadosRelatorios = Object.freeze({
  Pedidos: Object.freeze({
    Nome: "Pedidos",
    Url: `/orders:export`,
    ReportName: "ORDERS_REPORT",
  }),
  PedidoOrigemVolume: Object.freeze({
    Nome: "Pedidos Originados Por Volume",
    Url: "/pedido/exportar/pedidoOrigemVolume",
    ReportName: "PEDIDO_ORIGEM_VOLUME",
  }),
  StatusEstoque: Object.freeze({
    Nome: "Status do Estoque",
    Url: "/stocks:export-status-report",
    ReportName: "STATUS_ESTOQUE",
  }),
  PlanejamentoProducao: Object.freeze({
    Nome: "Planejamento de Produção",
    Url: "/necessidade/productionPlanReport",
    ReportName: "PRODUCTION_FORECAST",
  }),
  Volumes: Object.freeze({
    Nome: "Volumes",
    Url: "/volume-orders:export",
    ReportName: "VOLUME_ORDERS_REPORT"
  })
});

export default DadosRelatorios;
