import {
  ClbTag
} from '@celebration/design-system-react'
import { Wrapper } from './styled';

export const TagCriticality = ({ criticality, title }) => {
  let iconType, color;
  switch (criticality) {
    case 2:
      iconType = 'positive'
      color = getComputedStyle(document.body).getPropertyValue('--color-feedback-positive-600');
      break;
    case 3:
      iconType = 'warning'
      color = getComputedStyle(document.body).getPropertyValue('--color-feedback-warning-600');
      break;
    case 4:
      iconType = 'negative'
      color = getComputedStyle(document.body).getPropertyValue('--color-feedback-danger-600');
      break;
    case 1:
    default:
      iconType = ''
      color = getComputedStyle(document.body).getPropertyValue('--color-contrast-brand');
      break;
  }

  return (
    <Wrapper color={color}>
      <ClbTag id="tag" label={title} type={iconType} />
    </Wrapper>
  )
}