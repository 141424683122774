import React, { useState } from 'react'
import { Layout, Checkbox } from "@hbsis.uikit/react"
import { Button } from 'components/uikit-adapter'
import { Row, Col } from 'react-flexbox-grid'
import intl from 'react-intl-universal'
import IconEmpty from "images/iconEmpty.icon"
import * as S from './styled'

const FilterViewMode = ({ open, close, handleFilter }) => {
  const [checkboxs, setCheckboxs] = useState({
    Unavailable: false,
    FreeSlot: true,
    ScheduledDelivery: true,
    PendingReview: true,
    InNegotiation: true,
    EmergencyNegotiation:true
  })

  const handleChangeCheckbox = (field, value) => {
    setCheckboxs(prevState => (
      {
        ...prevState,
        [field]: value
      }
    ))
  }

  const handleSaveFilter = () => {
    const cards = Object.keys(checkboxs).filter((item) => checkboxs[item]);
    handleFilter(cards);
    close();
  }

  return (
    <Layout.Sidebar
      width="350px"
      visible={open}
      triggerClose={close}
      background="var(--color-contrast-white)"
      offsetTop="96px"
      side="right"
      block
      float
      icon={IconEmpty}
    >
      <S.Wrapper>
        <S.Title>{intl.get('bottles.filter.view.data')}</S.Title>
        <S.Content>
          <Row>
            <Col xs={6}>
              <Checkbox
                text={intl.get("loadComposition.filter.unavailable")}
                name={intl.get("loadComposition.filter.unavailable")}
                checked={checkboxs.Unavailable}
                onChange={() => handleChangeCheckbox("Unavailable", !checkboxs.Unavailable)}
              />
            </Col>
            </Row>
            <Row style={{ marginTop: '16px' }}>
            <Col xs={7}>
              <Checkbox
                text={intl.get("commons.emergencyNegotiation")}
                name={intl.get("commons.emergencyNegotiation")}
                checked={checkboxs.EmergencyNegotiation}
                onChange={() => handleChangeCheckbox("EmergencyNegotiation", !checkboxs.EmergencyNegotiation)}
              />
            </Col>
            <Col xs={5}>
              <Checkbox
                text={intl.get("loadComposition.filter.freeSlot")}
                name={intl.get("loadComposition.filter.freeSlot")}
                checked={checkboxs.FreeSlot}
                onChange={() => handleChangeCheckbox("FreeSlot", !checkboxs.FreeSlot)}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: '16px' }}>
            <Col xs={7}>
              <Checkbox
                text={intl.get("loadComposition.filter.scheduledDelivery")}
                name={intl.get("loadComposition.filter.scheduledDelivery")}
                checked={checkboxs.ScheduledDelivery}
                onChange={() => handleChangeCheckbox("ScheduledDelivery", !checkboxs.ScheduledDelivery)}
              />
            </Col>
            <Col xs={5}>
              <Checkbox
                text={intl.get("loadComposition.filter.inNegotiation")}
                name={intl.get("loadComposition.filter.inNegotiation")}
                checked={checkboxs.InNegotiation}
                onChange={() => handleChangeCheckbox("InNegotiation", !checkboxs.InNegotiation)}/>
            </Col>
          </Row>
          <Row style={{ marginTop: '16px' }}>
            <Col xs={6}>
              <Checkbox
                text={intl.get("loadComposition.filter.pendingReview")}
                name={intl.get("loadComposition.filter.pendingReview")}
                checked={checkboxs.PendingReview}
                onChange={() => handleChangeCheckbox("PendingReview", !checkboxs.PendingReview)}
              />
            </Col>            
          </Row>
          <Row style={{ marginTop: '18px' }}>
            <Col xs={12}>
              <Button
                type="default"
                htmlType="button"
                value={intl.get('commons.okay')}
                onClick={handleSaveFilter}
              />
            </Col>
          </Row>
        </S.Content>
      </S.Wrapper>
    </Layout.Sidebar >
  )
}

export default FilterViewMode
