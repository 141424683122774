import React from 'react'
import intl from 'react-intl-universal'
import { Input, SelectBox } from "components/uikit-adapter/index"
import DateInput from '../../../../components/date-input';
import { Row, Col } from 'react-flexbox-grid';
import moment from 'moment'
import { showDeliveryTime } from 'utils/delivery-time';
import { generateDoorOption } from 'pages/master-data/unit-management/delivery-schedule-configuration/components/filter/utils';
import { isLoadCompositionFlowBrewerySide } from 'utils/validations-general-settings';
import { getObservationLabelAbbreviated, getObservationValue } from 'pages/load-composition/utils/observation';
import Feature from 'components/feature';

const HeaderForm = ({
  modoEdicao,
  composicao,
  unidadesNegocio,
  changeSelected,
  changeSupplierSelected,
  fornecedores,
  modoLeitura,
  SituacaoValor,
  handleChangeDate,
  handleChangeObservacao,
  doorSelected,
  showNote = false,
  hideObs,
  allowChangingDates,
  handleChangeNote
}) => {

  const getValueDoor = () => {
    if (!composicao.Door) {
      return generateDoorOption(getDoors()?.length ? intl.get('commons.undefined') : "N/A")
    }
    return { label: composicao?.Door, value: composicao?.Door };
  }

  const generalRuleDisableFields = (forceEnable) => (modoLeitura || composicao.SituacaoValor === SituacaoValor.AguardandoRevisaoFornecedor) && !forceEnable;

  const getDoors = () => unidadesNegocio.find(x => x.Id === composicao.IdUnidadeNegocio)?.Doors?.map((x, i) => ({ Id: i, Door: x }))

  const isObservationDisabled = () => generalRuleDisableFields() || isLoadCompositionFlowBrewerySide();

  return (
    <Row>
      <Col className="col-select-box" data-testid="destination-header">
        <SelectBox
          required
          disabled={modoEdicao}
          name='unidadeNegocio'
          label={intl.get('commons.destiny')}
          placeholder={intl.get('commons.destiny')}
          valueKey='Id'
          labelKey='Nome'
          value={composicao.IdUnidadeNegocio}
          options={unidadesNegocio}
          onChange={value => changeSelected(value === null ? '' : value.Id)}
          searchable
          openOnFocus
          clearable
        />
      </Col>
      {isLoadCompositionFlowBrewerySide() && <Col className="col-select-box" data-testid="door-header">
        <SelectBox
          required
          disabled={modoEdicao}
          name='door'
          label={intl.get('commons.door')}
          placeholder={'N/A'}
          valueKey='value'
          labelKey='label'
          value={getValueDoor()}
          options={getDoors()}
          onChange={value => doorSelected(value === null ? '' : value.Id)}
          searchable
          openOnFocus
          clearable
        />
      </Col>}
      <Col className="col-select-box" data-testid="source-header">
        <SelectBox
          required
          disabled={modoEdicao}
          name='fornecedor'
          label={intl.get('transports.compoCharge.actions.source')}
          placeholder={intl.get('transports.compoCharge.actions.source')}
          valueKey='Id'
          labelKey='Description'
          value={composicao.IdFornecedor}
          options={fornecedores}
          onChange={value => changeSupplierSelected(value === null ? '' : value.Id)}
          searchable
          openOnFocus
          clearable
        />
      </Col>
      <Col className="col-date" data-testid="pickup-date-header">
        <DateInput
          disabled={generalRuleDisableFields(allowChangingDates)}
          label={intl.get('commons.colDate')}
          value={composicao.DataColeta}
          onChange={(date) => handleChangeDate('DataColeta', date)}
          minDate={moment().format("YYYY-MM-DD")}
          maxDate="9999-12-31"
        />
      </Col>
      <Col className="col-date" data-testid="delivery-date-header">
        <DateInput
          disabled={generalRuleDisableFields(allowChangingDates)}
          label={intl.get('commons.deliveryDate')}
          value={composicao.DataEntrega}
          onChange={(date) => handleChangeDate('DataEntrega', date)}
          minDate={moment().format("YYYY-MM-DD")}
          maxDate="9999-12-31"
        />
      </Col>
      {
        <Feature validation={showDeliveryTime(composicao.HoraEntrega)}>
          <Col className='col-time' data-testid="delivery-time-header" style={{ paddingLeft: '2px', paddingRight: '2px' }}>
            <Input
              disabled={generalRuleDisableFields(allowChangingDates)}
              label={intl.get('commons.deliveryTime')}
              name='HoraEntrega'
              htmlType='time'
              value={composicao.HoraEntrega}
              onChange={event => handleChangeDate('HoraEntrega', event.target.value)}
              className='input'
            />
          </Col>
        </Feature>
      }

      <Feature validation={!hideObs}>
        <Col className="col-observation" data-testid="observation-header">
          <Input
            className='input'
            disabled={isObservationDisabled()}
            name="txbObservacao"
            label={getObservationLabelAbbreviated()}
            value={getObservationValue(composicao.BreweryNote, composicao.Observacao)}
            title={getObservationValue(composicao.BreweryNote, composicao.Observacao)}
            onChange={handleChangeObservacao}
            width="90%"
            style={{ textOverflow: 'ellipsis' }}
          />
        </Col>
      </Feature>
      <Feature validation={showNote}>
        <Col className="col-observation" xs={3} style={{ padding: '0px' }}>
          <Input
            className='input'
            name="txbObservacao"
            label={intl.get("composition.form.deliveryNote")}
            value={composicao?.Note}
            onChange={handleChangeNote}
          />
        </Col>
      </Feature>
    </Row>
  )
}

export default HeaderForm;
