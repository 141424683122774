import React, { useEffect, useState } from 'react'
import { SelectBox, Input } from "components/uikit-adapter/index"
import DateRange from 'components/date-range'
import { FilterActions } from './filter-actions'
import { InputMulti } from '../input-multi'
import * as S from './styled'
import {Switcher} from 'components/toggle'
import { ordersFiltersDefault } from 'features/orders/components/orders-filter/utils'
import HourRange from 'components/hour-range'

const Filter = ({ handleSearch, handleClear, selectsFilters,
  filtersDefault, inputFilters, dateRangeFilters, hourRangeFilters, tooglesFilters, inputMultiFilters }) => {
  const [filters, setFilters] = useState(filtersDefault);
  const [invalidPeriod, setInvalidPeriod] = useState(false);

  const handleClickClean = () => {
    setFilters(ordersFiltersDefault);
    handleClear();
  }

  const handleChange = (value, field) => {
    setFilters(prevState => ({
      ...prevState,
      [field]: value
    }))
  }

  const validaterangePeriods = (value) => {
    setInvalidPeriod(value)
  }

  const handleClickSearch = () => {
    handleSearch(filters);
  }

  useEffect(() => {
    setFilters(filtersDefault);
  }, [filtersDefault])

  return (
    <S.Wrapper>
      <S.WrapperFilters>
        {inputMultiFilters.map(inputMultiFilter => (
          !inputMultiFilter.hidden &&
          <InputMulti
            key={inputMultiFilter.name}
            {...inputMultiFilter}
            onChange={value => handleChange(value, inputMultiFilter.name)}
            value={filters[inputMultiFilter.name]}
          />
        ))}
        {inputFilters.map(inputFilter => (
          !inputFilter.hidden &&
          <Input
            className="input"
            key={inputFilter.name}
            {...inputFilter}
            value={filters[inputFilter.name]}
            onChange={e => handleChange(e.target.value, inputFilter.name)}
            data-testid='input-filter'
          />
        ))}
        {selectsFilters.map(selectFilter => (
          !selectFilter.hidden &&
          <SelectBox
            key={selectFilter.name}
            {...selectFilter}
            value={filters[selectFilter.name]}
            onChange={value => handleChange(value, selectFilter.name)}
          />
        ))}
        {dateRangeFilters.map((dateRangeFilter, index) => (
          !dateRangeFilter.hidden &&
          <DateRange
            {...dateRangeFilter}
            key={index}
            dataDe={filters[dateRangeFilter.dataDe]}
            dataAte={filters[dateRangeFilter.dataAte]}
            onChangeDataDe={value => handleChange(value, dateRangeFilter.dataDe)}
            onChangeDataAte={value => handleChange(value, dateRangeFilter.dataAte)}
            onRangeIsValid={value => validaterangePeriods(value)}
          />
        ))}
        {hourRangeFilters?.map((hourRangeFilter) => (
          <HourRange
            {...hourRangeFilter}
            key={hourRangeFilter.name}
            labelFrom={hourRangeFilter.labelFrom}
            labelTo={hourRangeFilter.labelTo}
            hourFrom={filters[hourRangeFilter.hourFrom]}
            hourTo={filters[hourRangeFilter.hourTo]}
            onChangeDeliveryTimeMin={value => handleChange(value, hourRangeFilter.hourFrom)}
            onChangeDeliveryTimeMax={value => handleChange(value, hourRangeFilter.hourTo)}
            onRangeIsValid={value => validaterangePeriods(value)}
          />
        ))}
        {tooglesFilters.map((toogleFilter, index) => (
          !toogleFilter.hidden &&
          <Switcher
            {...toogleFilter}
            key={index}
            value={filters[toogleFilter.name]}
            onChange={e => handleChange(e.checked, toogleFilter.name)}
          />
        ))}
      </S.WrapperFilters>
      <FilterActions
        handleClickClean={handleClickClean}
        handleClickSearch={handleClickSearch}
        disabledSearch={invalidPeriod}
      />
    </S.Wrapper >
  )
}

Filter.defaultProps = {
  tooglesFilters: [],
  dateRangeFilters: [],
  selectsFilters: [],
  inputFilters: [],
  inputMultiFilters: [],
}

export default Filter;
