import React, { useState, useEffect } from 'react'
import intl from 'react-intl-universal'
import InputPeriod from 'components/input-period'
import { SelectWithSearchAndSelectAll } from 'components/select-with-search-select-all'
import { ObterTodasComGarrafa, ObterUnidadeNegocioComGarrafa } from './filter-general.service'
import { converteDataParaMultiSelect, getFieldValue } from './utils'
import { useBottlesContext } from '../../../context'
import { ButtonSavePlanning } from '../../bottles-btn-save-planning'
import { Types } from '../../../models/types'
import * as S from './styled'
import { SelectBox } from 'components/uikit-adapter'

export const GeneralFilter = ({ type }) => {
  const { setFilter } = useBottlesContext()
  const [familias, setFamilias] = useState([])
  const [destinos, setDestinos] = useState([])
  const [familiaSelecionada, setFamiliaSelecionada] = useState()
  const [familiasSelecionadas, setFamiliasSelecionadas] = useState([])
  const [destinosSelecionados, setDestinosSelecionados] = useState([])

  const labeledFamilyString = intl.get("commons.labeledFamily")

  const changeFilter = (name, value) => {
    setFilter(prevState => ({ ...prevState, [name]: value }))
  }

  const changeSoloLabeledFamily = (field) => {
    setFamiliaSelecionada(field)
    changeFilter('labeledFamilyId', getFieldValue(field))
  }

  const changeMultiLabeledFamily = (fields) => {
    setFamiliasSelecionadas(fields)
    changeFilter('labeledFamilysId', fields)
  }

  const changeMultiDestiny = (fields) => {
    setDestinosSelecionados(fields)
    changeFilter('destinyId', fields)
  }

  const carregarFiltros = async () => {
    setDestinos(converteDataParaMultiSelect(await ObterUnidadeNegocioComGarrafa(), 'Descricao', 'IdUnidadeNegocio'))
    setFamilias(converteDataParaMultiSelect(await ObterTodasComGarrafa(), 'Description', 'Id'))
  }

  useEffect(() => {
    carregarFiltros()
  }, [])

  return (
    <S.GeneralFilterWrapper margin={type}>
      <S.GeneralFilterBlockSeparator>
        <S.GeneralFilterBlock>
          {type === Types.Visualizacao &&
            <S.GeneralFilterBlock className='select-filters'>
              <SelectWithSearchAndSelectAll
                label={intl.get('commons.destiny')}
                options={destinos}
                onChange={changeMultiDestiny}
                values={destinosSelecionados}
              />
              <SelectWithSearchAndSelectAll
                label={labeledFamilyString}
                options={familias}
                onChange={changeMultiLabeledFamily}
                values={familiasSelecionadas}
              />
            </S.GeneralFilterBlock>
          }
          {type === Types.Edicao &&
            <SelectBox
              width="300px"
              name='Familia'
              label={labeledFamilyString}
              placeholder={labeledFamilyString}
              valueKey='value'
              labelKey='label'
              value={familiaSelecionada}
              options={familias}
              onChange={changeSoloLabeledFamily}
              searchable
              clearable
              data-testid="familia"
            />
          }
        </S.GeneralFilterBlock >
        <S.GeneralFilterBlock>
          <S.GeneralFilterSelectWrapper className="last">
            <span className="label">{intl.get("bottles.exhibitionPeriod")}</span>
            <InputPeriod />
          </S.GeneralFilterSelectWrapper>
          <ButtonSavePlanning
            className='btn-save-header'
            hide={!type}
          />
        </S.GeneralFilterBlock>
      </S.GeneralFilterBlockSeparator >
    </S.GeneralFilterWrapper >
  )
}

