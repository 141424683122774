import React from 'react'
import Dialog from 'material-ui/Dialog'
import FlatButton from 'material-ui/FlatButton'
import intl from 'react-intl-universal'

const ModalDiscard = ({ open, onClose, discardChanges, message}) => (
  <Dialog
    open={open}
    title={<h2 style={{ color: 'var(--color-action-default)' }}>{intl.get('autoLoadOptimization.discardChanges')}</h2>}
    actions={
      <React.Fragment>
        <FlatButton label={intl.get("geral.buttonsDefault.cancel")} onClick={onClose} />
        <FlatButton label={intl.get('survey.confirm')} onClick={discardChanges} style={{ backgroundColor: 'var(--color-action-default)', color: 'white' }} />
      </React.Fragment>
    }
    contentStyle={{ width: '30%' }}>
    <span>{message}</span>
  </Dialog>
)

export default ModalDiscard;
