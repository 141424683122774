import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './status-card.css'
import pedidoPacoteIcon from 'images/pedido-pacote.png'
import pedidoAguardoIcon from 'images/pedido-aguardo.png'
import pedidoEntregaIcon from 'images/pedido-entrega.png'
import { TextSpan } from 'components/text-label'

class StatusCard extends Component {
  constructor(props) {
    super(props)

    this.state = {
      colorStatus: '',
      colorStatusArrow: '',
      imagePath: null
    }

    this.updateStatusPriority = this.updateStatusPriority.bind(this)
    this.updateStatusTask = this.updateStatusTask.bind(this)
  }

  componentDidMount() {
    const { tipo } = this.props
    this.updateStatusTask(tipo.Etapa)
    this.updateStatusPriority(tipo.Prioridade)
  }

  updateStatusTask(status) {
    let imagePath = ''

    switch (status) {
      case 0:
        imagePath = pedidoPacoteIcon
        break
      case 1:
        imagePath = pedidoAguardoIcon
        break
      default:
        imagePath = pedidoEntregaIcon
    }

    this.setState({ imagePath })
  }

  updateStatusPriority(status) {
    let colorStatus = ''
    let colorStatusArrow = ''

    switch (status) {
      case 0:
        colorStatus = '#ff5e53'
        colorStatusArrow = '#c64138'
        break
      case 1:
        colorStatus = '#fbba49'
        colorStatusArrow = '#c3913b'
        break
      case 2:
        colorStatus = '#9bc73b'
        colorStatusArrow = '#86ac32'
        break
      default:
        colorStatus = '#b3b3b3'
        colorStatusArrow = '#b3b3b3'
    }

    this.setState({ colorStatus, colorStatusArrow })
  }

  render() {
    const { colorStatus, colorStatusArrow, imagePath } = this.state
    const { tipo, material } = this.props

    return (
      <div className='status-container'>
        <div className='image-status' style={{ backgroundColor: colorStatus }}>
          <img src={imagePath} alt='status' />
          <div
            className='arrow-status'
            style={{ borderColor: `transparent transparent ${colorStatusArrow} transparent` }}
          />
        </div>

        <div className='insumo-desc'>
          <TextSpan data-testid='order-status' style={{ color: colorStatus, width: '280px' }}>{tipo.Titulo}</TextSpan>
          <TextSpan data-testid='material-number-name'>{material}</TextSpan>
        </div>
      </div>
    )
  }
}

StatusCard.propTypes = {
  tipo: PropTypes.shape({
    Titulo: PropTypes.string.isRequired,
    Prioridade: PropTypes.number.isRequired,
    Etapa: PropTypes.number.isRequired
  })
}

export default StatusCard
