import { Input } from 'components/uikit-adapter/index';
import { validarDadosUpdate } from "../../../../../utils/validate";
import moment from "moment"
import { updateAdvancedPackagingMaterialSchedule } from "../../../../../primary-material-schedule.service";
import { EditIcon, SaveIcn } from "components/icons/icn-index.icon";
import { errorMessage, validDefaultValue } from "../../../../../utils/format";
import intl from 'react-intl-universal'
import { useZapmslContext } from "../../../../../context/zapmslContext";
import { useGlobalContext } from 'hooks/useGlobalContext';
import { validDate } from "../../../../../../auto-load-optimization/utils/validate";
import { RoundButton } from 'components/round-button';

const BtnEditLine = ({ idx, row }) => {
  const {
    startLoading,
    stopLoading,
    zapmslData,
    updateTableWithData,
    setlastUpdated,
    setIndexForEdit
  } = useZapmslContext();

  const {
    showFeedback
  } = useGlobalContext();

  const editLine = (index, prevValues) => {
    const _data = [...zapmslData]
    const _prevValues = { ...prevValues }

    _data[index].DeliveryDate = () =>
      <Input
        id={`DeliveryDate-${index}`}
        className="drp-input-date drp-nomargin data-de-picker input"
        defaultValue={validDate(_prevValues.DeliveryDate)}
        minDate={moment().format('YYYY-MM-DD')}
        maxDate="9999-12-31"
        style={{ height: '30px', width: '110px' }}
        htmlType="date"
      />

    _data[index].DeliveryTime = () => <Input
      htmlType="time"
      className="input"
      id={`DeliveryTime-${index}`}
      defaultValue={_prevValues.DeliveryTime}
      style={{ height: '30px' }}
    />

    _data[index].Line = () => <Input
      className="input"
      id={`Line-${index}`}
      defaultValue={validDefaultValue(_prevValues.Line)}
      style={{ width: '60px', height: '30px' }}
    />

    _data[index].Pallets = () => <Input
      className="input"
      id={`PalletQuantity-${index}`}
      defaultValue={validDefaultValue(_prevValues.Pallets)}
      style={{ width: '60px', height: '30px' }}
      htmlType="number"
    />

    _data[index].Door = () => <Input
      className="input"
      id={`Door-${index}`}
      defaultValue={validDefaultValue(_prevValues.Door)}
      style={{ height: '30px' }}
    />

    _data[index].Comments = () => <Input
      className="input"
      id={`Comments-${index}`}
      defaultValue={validDefaultValue(_prevValues.Comments)}
      style={{ height: '30px' }}
    />

    _data[index].edit = () => <RoundButton
      style={{ marginRight: '4px', padding: '2px' }}
      onClick={() => saveEditLine(index, _data)}
      data-testid={`save-edit-line-${index}`}
      iconHeight="15px"
    >
      <SaveIcn key={index} width="15px" height="15px" />
    </RoundButton>

    updateTableWithData([..._data]);
  }

  const processError = (message) => {
    showFeedback(errorMessage(message))
  }

  const saveEditLine = async (index, prevValues) => {
    startLoading()
    const prevValue = prevValues[index]
    const line = {
      ...prevValue,
      Id: index,
      Material: prevValue.MaterialNumber,
      SchedulingAgreement: prevValue.ScheduleAgreement,
      PalletQuantity: document.getElementById(`PalletQuantity-${index}`).value,
      ScheduleLineCounter: prevValue.ScheduleLineCounter,
      DeliveryDate: document.getElementById(`DeliveryDate-${index}`).value,
      DeliveryTime: document.getElementById(`DeliveryTime-${index}`).value,
      Line: document.getElementById(`Line-${index}`).value,
      Door: document.getElementById(`Door-${index}`).value,
      Comments: document.getElementById(`Comments-${index}`).value,
      edit: null
    }

    setIndexForEdit(index)

    if (!validarDadosUpdate(line, processError)) {
      await updateAdvancedPackagingMaterialSchedule(line)
        .then((editedLine) => {
          editedLine.Id = index
          setlastUpdated({ ...editedLine })
          showFeedback(intl.get("feedbacks.savedRegister"))
        })
        .catch((e) => processError(e))
    }
    stopLoading()
  }

  return (
    <RoundButton
      onClick={() => editLine(idx, row)}
      style={{ marginRight: '4px', padding: '2px' }}
      data-testid={`edit-line-${idx}`}
      iconHeight="15px"
    >
      <EditIcon key={idx} width="15px" height="15px" />
    </RoundButton>
  )
}

export default BtnEditLine
