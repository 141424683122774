import React, { useState } from 'react'
import intl from 'react-intl-universal'
import { createEmergencyNegotiation, criarComposicao, editarComposicao, fetchDesvincularPedidos, fetchUpdateDate } from '../../../../load-composition-building.service'
import * as S from '../../styled'
import Session from 'utils/user-storage';
import ComposicaoCargaForm from '../../../../../composicao-carga/form'
import ModalAcceptReturn from '../../../../../primary-material-schedule/components/modalReturnSapList'
import moment from 'moment'
import { useLoadCompositionBuildingContext } from '../../../../context/load-composition-building-context';
import { ISO_DATE_FORMAT } from 'utils/format-date';
import { getEmergencyNegotiationModel, validateHasChangedInRenegotiation } from './utils';
import { ModalAlert } from '../modalAlert';
import { useToastContext } from 'hooks/useToastContext';
import { runStockProjection } from 'services/labeled-family-center.service';

const ButtonAccept = ({ isEditDateMode, data, setShowLoad, infosRoute, proccessError, pushPath, history, composicao, disabled, queryParams }) => {
  const isEdit = !!infosRoute.composicaoId;
  const [modalAcceptReturnData, setModalAcceptReturnData] = useState({
    isOpen: false,
    data: [],
    title: intl.get('commons.composition')
  });
  const [showAcceptModal, setShowAcceptModal] = useState(false)
  const [idPedidoRetorno, setIdPedidoRetorno] = useState()
  const [showLoadCompositionModal, setShowLoadCompositionModal] = useState(false)
  const [showAlertModal, setShowAlertModal] = useState(false)

  const {
    loadPage,
    isFullTruckLoad,
    breweryNote,
    ordersMetadata,
    isEmergencyNegotiation,
    selectedLoads,
    initialMetadata,
    loadComposition
  } = useLoadCompositionBuildingContext();
  const { openToast } = useToastContext();

  const handleCallbackEdit = () => {
    loadPage()
    setShowAcceptModal(false)
  }

  const handleEditDate = async () => {
    if (moment(composicao.DataEntrega).format(ISO_DATE_FORMAT) !== moment(infosRoute.dia).format(ISO_DATE_FORMAT)
      || moment(composicao.HoraEntrega, "hh:mm").format("hh:mm") !== moment(infosRoute.hora, "hh:mm").format("hh:mm")) {
      const dto = {
        LoadCompositionId: parseInt(infosRoute.composicaoId),
        NewCompositionDeliveryDate: infosRoute.dia,
        NewCompositionDeliveryTime: infosRoute.hora,
      }
      await fetchUpdateDate(infosRoute.composicaoId, dto);
    }
  }

  const closeModalAssign = () => {
    setModalAcceptReturnData({
      data: [],
      isOpen: false
    });

    if (showLoadCompositionModal) {
      setShowAcceptModal(true);
    } else {
      history.push(pushPath);
    }
  }

  const openModalAssign = (model) => {
    setModalAcceptReturnData({
      data: model,
      isOpen: true,
      title: intl.get('commons.composition')
    });
  }

  const generateModelAccept = () => {
    const format = { idsSugestoes: [], idsPedidos: [] };
    const cards = data.filter(x => !x.IsNew);

    const labeledFamilyCenterIds = cards.map(card => card.IdFamiliaRotuladaCentro);
    cards.map(card => card.IdCard)
      .filter(id => id.includes('S') ?
        format.idsSugestoes.push(parseInt(id)) :
        format.idsPedidos.push(parseInt(id)));

    return {
      idsPedidos: format.idsPedidos,
      idsSugestoes: format.idsSugestoes,
      dataEntregaComposicao: infosRoute.dia,
      horaEntregaComposicao: infosRoute.hora,
      idFornecedor: data[0] ? data[0].IdFornecedor : infosRoute.fornecedorId,
      idUnidadeNegocio: parseInt(infosRoute.destinoId),
      doorDescription: queryParams.get('doorDescription'),
      breweryNote: breweryNote,
      labeledFamilyCenterIds: [...new Set(labeledFamilyCenterIds)]
    }
  }

  const atualizarPedidosComposicao = async (idsPedidos) => {
    const dto = {
      OrdersIds: idsPedidos,
      LoadCompositionId: parseInt(infosRoute.composicaoId)
    }
    await fetchDesvincularPedidos(infosRoute.composicaoId, dto);
  }

  const handleConfirm = () => {
    setShowAlertModal(false)
    accept(generateModelAccept())
  }

  const handleValidateAccept = () => {
    const highLT = Math.max(...data.map((lci) => lci.TransitTime))
    const passLC = moment(moment(infosRoute.dia)).subtract(highLT, 'd').format("L")
    const _showAlert = passLC < moment().format("L");
    if (_showAlert) {
      setShowAlertModal(true);
    } else {
      accept(generateModelAccept());
    }
  };

  const accept = async (model) => {
    try {
      setShowLoad(true);
      let result;

      if (isEmergencyNegotiation) {
        const dataDeColeta = moment(model.dataEntregaComposicao).add(-composicao.TransitTime, 'day').format(ISO_DATE_FORMAT);
        const modelEmergencyNegotiation = getEmergencyNegotiationModel(infosRoute, model, dataDeColeta, ordersMetadata, selectedLoads);
        const hasChanges = validateHasChangedInRenegotiation(initialMetadata, loadComposition, modelEmergencyNegotiation.PedidosMetaData, infosRoute)
        if (!hasChanges) {
          openToast(intl.get('commons.noChange'), 'warning')
          setShowLoad(false);
          return;
        }
        await createEmergencyNegotiation(modelEmergencyNegotiation);
        setShowLoad(false);
        openModalAssign([intl.get('commons.renegotiationLoadCompositionSent')]);
        return;
      } else if (isEdit) {
        await atualizarPedidosComposicao(model.idsPedidos)
        const _model = {
          LoadCompositionId: parseInt(infosRoute.composicaoId),
          BreweryNote: model.breweryNote,
          DoorDescription: model.doorDescription,
          OrdersIds: model.idsPedidos,
          SuggestionsIds: model.idsSugestoes
        };

        result = await editarComposicao(infosRoute.composicaoId, _model);
        handleRunStockProjection(model.labeledFamilyCenterIds);
        await handleEditDate()
      } else {
        const _model = {
          SuggestionsIds: model.idsSugestoes,
          OrdersIds: model.idsPedidos,
          CompositionDeliveryDate: model.dataEntregaComposicao,
          CompositionDeliveryTime: model.horaEntregaComposicao,
          SupplierId: model.idFornecedor,
          BusinessUnitId: model.idUnidadeNegocio,
          BreweryNote: model.breweryNote,
          DoorDescription: model.doorDescription,
        };

        result = await criarComposicao(_model);
        handleRunStockProjection(model.labeledFamilyCenterIds);
      }

      const pedidosComErro = result.filter(x => !x.Success) ?? [];

      if (pedidosComErro.length === 0) {
        const pedidosComSucesso = result.filter(x => x.Success);
        const idsPedidosComSucesso = pedidosComSucesso.map(x => x.CreatedOrderId);
        setIdPedidoRetorno(idsPedidosComSucesso[0]);
        setShowLoadCompositionModal(true);
      }
      openModalAssign(result.map(x => x.Message));
    }
    catch (e) {
      proccessError(e);
    }
    setShowLoad(false);
  }

  const handleRunStockProjection = async (labeledFamilyCenterIds) => {
    try {
      for (const id of labeledFamilyCenterIds) {
        await runStockProjection(id);
      }
      return;
    } catch (error) {
      return error;
    }
  };

  const validateDisableButton = () => !data[0] || disabled || (isEditDateMode && !isFullTruckLoad)

  return (
    <React.Fragment>
      <S.Button
        className='load-composition-accept'
        onClick={handleValidateAccept}
        type='primary'
        value={isEmergencyNegotiation ? intl.get("commons.renegotiate") : intl.get("geral.buttonsDefault.accept")}
        disabled={validateDisableButton()}
      />
      {modalAcceptReturnData.isOpen &&
        <ModalAcceptReturn
          open={modalAcceptReturnData.isOpen}
          data={modalAcceptReturnData.data}
          closeModalAssign={closeModalAssign}
          title={modalAcceptReturnData.title}
        />
      }
      {(idPedidoRetorno && showAcceptModal) &&
        <ComposicaoCargaForm
          idPedido={idPedidoRetorno}
          idUser={Session.get().Id}
          open={showAcceptModal}
          handleClose={() => {
            setShowAcceptModal(false);
            history.push(pushPath);
          }}
          modoLeitura={true}
          handleCallbackEdit={handleCallbackEdit}
        />
      }
      <ModalAlert
        open={showAlertModal}
        handleConfirm={handleConfirm}
        handleClose={(() => setShowAlertModal(false))}
      />
    </React.Fragment>
  )
}

export default ButtonAccept;
