import { Component } from 'react'
import PropTypes from 'prop-types'
import { TableRow, TableRowColumn } from 'material-ui/Table'
import { IconButton } from 'material-ui'
import NextPageIcon from 'material-ui/svg-icons/navigation/chevron-right'
import PreviousPageIcon from 'material-ui/svg-icons/navigation/chevron-left'
import intl from 'react-intl-universal'

const styles = {
  footerText: {
    float: 'right',
    paddingTop: '16px',
    height: '100%',
    color: 'rgb(158, 158, 158)'
  }
}

class PaginationFooter extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pageSelect: 0,
      offset: 0,
      limit: props.countPerPage
    }

    this.nextPage = this.nextPage.bind(this)
    this.previousPage = this.previousPage.bind(this)
  }

  componentDidMount() {
    this.refreshData(this.state.pageSelect)
  }

  refreshData(page) {
    this.props.refreshSearch(page)
  }

  nextPage() {
    const { pageSelect, limit } = this.state;
    const _nextPage = pageSelect + 1;
    const _offset = _nextPage * limit

    this.setState({
      pageSelect: _nextPage,
      offset: _offset
    })

    this.refreshData(_nextPage)
  }

  previousPage() {
    const { pageSelect, offset, limit } = this.state;
    const _previousPage = pageSelect - 1;
    const _offset = offset - limit

    this.setState({
      pageSelect: _previousPage,
      offset: _offset
    })

    this.refreshData(_previousPage)
  }

  render() {
    let { countData, isSearching } = this.props
    let { offset, limit, pageSelect } = this.state

    return (
      <TableRow style={{ borderBottom: 'none' }}>
        <TableRowColumn style={{ float: 'right' }}>
          <IconButton
            data-testid='previousPage'
            disabled={pageSelect === 0}
            onClick={this.previousPage}>
            <PreviousPageIcon color={'rgb(158, 158, 158)'} />
          </IconButton>
          <IconButton
            data-testid='nextPage'
            disabled={offset + limit >= countData || isSearching}
            onClick={this.nextPage}>
            <NextPageIcon color={'rgb(158, 158, 158)'} />
          </IconButton>
        </TableRowColumn>

        <TableRowColumn style={styles.footerText}>
          {`${Math.min((offset + 1), countData)} - ${Math.min((offset + limit), countData)} ${intl.get('commons.table.of')} ${countData} ${intl.get('commons.table.records')}`}
        </TableRowColumn>

        <TableRowColumn style={styles.footerText}>
          {intl.get('commons.table.page')}: {pageSelect + 1}
        </TableRowColumn>
      </TableRow>
    )
  }
}

PaginationFooter.propTypes = {
  countPerPage: PropTypes.number.isRequired,
  countData: PropTypes.number.isRequired,
  refreshSearch: PropTypes.func.isRequired
}

export default PaginationFooter
