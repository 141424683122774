import { useState } from 'react'
import Select from 'react-select';
import { customStyle, OntimeSelectWrapper, OntimeSelectLabel } from './styled'
import { normalizeOptions, normalizeValue } from './utils/normalize'
import intl from 'react-intl-universal';

const SelectBox = (
  {
    dontShowLabel = false,
    label = "",
    placeholder,
    options,
    onChange,
    clearable = undefined,
    searchable = undefined,
    multi = undefined,
    disabled,
    valueKey,
    labelKey,
    isLoading = undefined,
    width = undefined,
    name,
    value,
    isPrimaryMaterial = undefined,
    limitOptions = 100,
    menuListStyle = {},
    ...rest
  }
) => {
  const [searchFilterSelect, setSearchFilterSelect] = useState('')
  const filterOptions = (list, label) => {
    if (!list) {
      return []
    }
    let _list = list
    if (searchFilterSelect) {
      _list = list.filter(x => x[label].toLowerCase().includes(searchFilterSelect))
    }
    if (_list && _list.length > limitOptions) {
      return [..._list.slice(0, limitOptions), { [label]: intl.get("commons.typeToFind"), isDisabled: true }]
    }
    return [..._list.slice(0, limitOptions)]
  }

  return (
    <OntimeSelectWrapper data-testid={rest['data-testid']} isPrimaryMaterial={isPrimaryMaterial} dontShowLabel={dontShowLabel} width={width}>
      <OntimeSelectLabel dontShowLabel={dontShowLabel} htmlFor={label} >{label}</OntimeSelectLabel>
      <Select
        inputId={label}
        name={name}
        placeholder={placeholder}
        styles={customStyle(menuListStyle)}
        isClearable={clearable}
        isSearchable={searchable}
        isMulti={multi}
        isDisabled={disabled}
        isLoading={isLoading}
        onChange={onChange}
        value={multi && !value ? [] : (normalizeValue(value, labelKey, valueKey, options, multi))}
        options={normalizeOptions(filterOptions(options, labelKey), labelKey, valueKey)}
        onInputChange={value => setSearchFilterSelect(value.toLowerCase())}
        {...rest}
      />
    </OntimeSelectWrapper>
  )
}

export { SelectBox }
