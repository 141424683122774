import { Row, Col } from 'react-flexbox-grid';
import { Input } from "components/uikit-adapter/index"
import intl from 'react-intl-universal'
import HourRangeFilterInput from './styled';
import { useEffect, useState } from 'react';

const HourRange = ({ hourFrom, hourTo, labelFrom, labelTo, onChangeDeliveryTimeMin, onChangeDeliveryTimeMax, onRangeIsValid }) => {
  const [invalidPeriod, setInvalidPeriod] = useState(false);

  const validateHoursPeriod = () => {
    if (hourFrom && hourTo && hourFrom > hourTo) {
      setInvalidPeriod(true)
    }
    else {
      setInvalidPeriod(false)
    }

    onRangeIsValid(invalidPeriod)
  }


  useEffect(() => {
    validateHoursPeriod()
  }, [hourFrom, hourTo, invalidPeriod])

  return (
    <Row>
      <Col xs={6} lg={6} md={6}>
        <HourRangeFilterInput>
          <Input
            label={labelFrom}
            name='DeliveryTimeMin'
            className='input-delivery-time'
            htmlType='time'
            value={hourFrom}
            onChange={e => onChangeDeliveryTimeMin(e.target.value, hourFrom)}
          />
        </HourRangeFilterInput>
      </Col>
      <Col xs={6} lg={6} md={6}>
        <HourRangeFilterInput>
          <Input
            label={labelTo}
            name='DeliveryTimeMax'
            className='input-delivery-time'
            htmlType='time'
            value={hourTo}
            onChange={e => onChangeDeliveryTimeMax(e.target.value, hourTo)}
          />
        </HourRangeFilterInput>
      </Col>

      {invalidPeriod &&
        <div className="range-data-mensagem">
          <span>{intl.get('commons.invalidPeriod')}</span>
        </div>
      }
    </Row>
  )
}

export default HourRange;