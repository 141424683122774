import { Label } from "./styled";

export const TextSpan = ({ color, display, children, ...rest }) =>
  <Label
    title={children}
    color={color}
    display={display}
    {...rest}
  >
    {children}
  </Label>