import React from 'react'
import intl from 'react-intl-universal'
import Dialog from 'material-ui/Dialog';
import { Button } from "components/uikit-adapter/index"
import moment from 'moment';
import { formatDeliveryTime } from 'utils/delivery-time';
import { TextSpan } from 'components/text-label';
import Loading from "components/center-loading";

import * as S from './styled'
import { formatNumber } from 'utils/format';
import { getValueForCondition } from 'utils/custom-functions';

export const ModalReviewEmergencyRenegotiate = ({
  loadComposition,
  handleSend,
  open,
  handleClose,
  isLoading,
  orders,
  ordersCached
}) => {
  const _ordersEdited = orders.filter(x => !x.IsDeleted && !x.IsNew && !x.IsAddedInNegotiation && x.IsEdited)
  const _ordersDeleted = orders.filter(x => x.IsDeleted)
  const _ordersAdded = orders.filter(x => x.IsNew || x.IsAddedInNegotiation)

  return (
    <Dialog
      open={open}
      onRequestClose={handleClose}
      contentStyle={{ width: '500px' }}
    >
      <S.Wrapper>
        <S.TitleWrapper>
          <S.Title>{intl.get('commons.emergencyNegotiation')}</S.Title>
          <S.Subtitle>{intl.get("commons.composition")} {loadComposition.IdComposicao} - {intl.get("commons.review")}</S.Subtitle>
        </S.TitleWrapper>
        <S.WrapperContent>
          <S.Row>
            <TextSpan>{intl.get('commons.colDate')}:</TextSpan>
            <TextSpan>{moment(loadComposition.DataColeta).format("L")}</TextSpan>
          </S.Row>
          <S.Row>
            <TextSpan>{intl.get('commons.deliveryDate')}:</TextSpan>
            <TextSpan>{moment(loadComposition.DataEntrega).format("L")}</TextSpan>
          </S.Row>
          <S.Row>
            <TextSpan>{intl.get('commons.deliveryTime')}:</TextSpan>
            <TextSpan>{formatDeliveryTime(loadComposition.HoraEntrega)}</TextSpan>
          </S.Row>
          <S.Row>
            <TextSpan>{intl.get("commons.Observation")}:</TextSpan>
            <TextSpan>{loadComposition.Observacao ? loadComposition.Observacao : " - "}</TextSpan>
          </S.Row>
          {_ordersDeleted.length > 0 &&
            <>
              <S.RowList>
                <label>{intl.get('commons.removedOrders')}:</label>
              </S.RowList>
              <S.RowList>
                {_ordersDeleted.map(x => (
                  <S.Flex>
                    <span className='rounded' />
                    <TextSpan>
                      {x.IdPedidoSap ? `${x.IdPedidoSap}-${x.IdItemPedidoSap}` : x.IdPedido}
                    </TextSpan>
                  </S.Flex>
                ))}
              </S.RowList>
            </>
          }
          {_ordersAdded.length > 0 &&
            <>
              <S.RowList>
                <label>{intl.get('commons.addedOrders')}:</label>
              </S.RowList>
              <S.RowList>
                {_ordersAdded.map(x => (
                  <S.Flex>
                    <span className='rounded' />
                    <TextSpan style={{ width: '230px', display: 'block' }}>
                      {getValueForCondition(x.IdPedidoSap, `${x.IdPedidoSap} - ${x.IdItemPedidoSap}`, x.MaterialSelect?.Descricao)}
                    </TextSpan>
                    <TextSpan>
                      {" - "}
                      {intl.get('stocks.timeline.qty')}
                      {formatNumber(x.Quantidade)}
                    </TextSpan>
                  </S.Flex>
                ))}
              </S.RowList>
            </>
          }
          {_ordersEdited.length > 0 &&
            <>
              <S.RowList>
                <label>{intl.get('commons.editedOrders')}:</label>
              </S.RowList>
              <S.RowList>
                {_ordersEdited.map(x => (
                  <S.Flex>
                    <span className='rounded' />
                    <TextSpan>
                      {x.IdPedidoSap && `${x.IdPedidoSap} - ${x.IdItemPedidoSap}`}
                      {" - "}
                      {intl.get('stocks.timeline.previous')}
                      {" "}
                      {formatNumber(ordersCached.find(y => y.IdPedido === x.IdPedido).Quantidade)}
                      {" - "}
                      {intl.get('stocks.timeline.new')}
                      {" "}
                      {formatNumber(x.Quantidade)}
                    </TextSpan>
                  </S.Flex>
                ))}
              </S.RowList>
            </>
          }
        </S.WrapperContent >
        <S.BottomWrapper>
          <S.Warning>
            <TextSpan>
              {intl.get("composition.actions.confirmEmergencyNegotiateWarning")}
            </TextSpan>
          </S.Warning>
          <S.Actions>
            <Button
              type="default"
              value={intl.get('geral.buttonsDefault.cancel')}
              width={"166px"}
              onClick={handleClose}
              className="button__composicao-carga"
            />
            <Button
              type="primary"
              value={intl.get('geral.buttonsDefault.send')}
              width={"166px"}
              onClick={handleSend}
              className="button__composicao-carga"
            />
          </S.Actions>
        </S.BottomWrapper>
        <Loading isLoading={isLoading} fullHeightParent />
      </S.Wrapper >
    </Dialog >
  )
}