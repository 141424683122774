import React from 'react'
import intl from 'react-intl-universal'
import { Button } from 'components/uikit-adapter/index'
import Dialog from "material-ui/Dialog"
import { TextModal, TitleModal } from './styled'

const ModalDeleteLoad = ({ open, close, onClick, showInfoReturnQuantity }) => (
  <Dialog
    open={open}
    contentStyle={{ width: "500px" }}
  >
    <TitleModal>{intl.get('load-building.page-flow.modal-delete-load.warning')}</TitleModal>
    <TextModal>{intl.get('load-building.page-flow.modal-delete-load.confirm-delete')}</TextModal>
    {showInfoReturnQuantity && <TextModal>{intl.get('load-building.page-flow.modal-delete-load.warningNotFullTruck')}</TextModal>}
    <div className="botoes-modal" style={{ marginTop: '32px' }}>
      <Button
        width="150px"
        value={intl.get('commons.cancel')}
        onClick={close}
        className="btn"
      />
      <Button
        width="150px"
        type="danger"
        value={intl.get('commons.delete')}
        onClick={onClick}
        className="btn margin-botao"
      />
    </div>
  </Dialog>
)

export default ModalDeleteLoad;