import React, {Component} from 'react'
import VisaoTarefasMesterAmbevCard from './ambev'

import './mester.css'

class VisaoTarefasMesterCard extends Component {
  render () {
    return (
      <div data-testid='card-test-id' style={{height: '100%'}}>
        {<VisaoTarefasMesterAmbevCard
          dataCard={this.props.dataCard}
          refreshCard={this.props.refreshCard}
          redirect={this.props.redirect}
        />}
      </div>
    )
  }
}

export default VisaoTarefasMesterCard
