import intl from 'react-intl-universal'
import {
  Item,
  Items,
  Button,
  Buttons,
  ItemLabel,
  SearchAndToggle,
  StyledSelect,
  SelectWrapper
} from './styled'
import Feature from 'components/feature'

const SelectWithSearchAndSelectAll = ({
  label,
  options,
  onChange,
  values,
  valueField,
  labelField,
  limitOptions,
  hideSelectAll,
  ...rest
}) => {
  const customContentRenderer = ({ props }) => (
    <div style={{ cursor: 'pointer' }}>
      {values?.length} {intl.get('commons.table.of')} {props.options.length} {intl.get('commons.selected')}
    </div>
  )

  const limitOptionsFilter = (props, state) => {
    const regexp = new RegExp(state.search, 'i');
    const optionsFiltered = props.options.filter((item) => regexp.test(item[props.searchBy] || item[props.labelField]))
    if (optionsFiltered && optionsFiltered.length > limitOptions) {
      return [...optionsFiltered.slice(0, limitOptions),
      {
        [labelField]: intl.get("commons.typeToFind"), isOnlyText: true
      }]
    }
    return optionsFiltered;
  }

  const customDropdownRenderer = ({ props, state, methods }) => {
    return (
      <div className='select'>
        <SearchAndToggle>
          <Feature validation={!hideSelectAll}>
            <Buttons>
              {methods.areAllSelected() ? (
                <Button className="clear" onClick={() => onChange([])}>
                  {intl.get('commons.clearAll')}
                </Button>
              ) : (
                <Button onClick={() => onChange([...options.map(x => x[valueField])])}>{intl.get('commons.selectAll')}</Button>
              )}
            </Buttons>
          </Feature>
          <input
            type="text"
            value={state.search}
            onChange={methods.setSearch}
            placeholder={intl.get('commons.typeAny')}
          />
        </SearchAndToggle>
        <Items>
          {limitOptionsFilter(props, state)
            .map((option) => {
              if (!props.keepSelectedInList && methods.isSelected(option)) {
                return null
              }
              return (
                option.isOnlyText ?
                  <ItemLabel>{option[props.labelField]}</ItemLabel>
                  :
                  <Item
                    disabled={option.disabled}
                    key={option[props.valueField]}
                    onClick={() => handleChangeCheckbox(option)}>
                    <input
                      type="checkbox"
                      checked={values?.indexOf(option[valueField]) !== -1}
                    />
                    <ItemLabel>{option[props.labelField]}</ItemLabel>
                  </Item>
              )
            })}
        </Items>
      </div>
    )
  }

  const handleChangeCheckbox = (option) => {
    if (option.disabled) {
      return null;
    }
    if (values.includes(option[valueField])) {
      onChange(values.filter(x => x !== option[valueField]))
    }
    else {
      onChange([...values, option[valueField]])
    }
  }

  return (
    <SelectWrapper style={{ position: 'relative', zIndex: '999' }}>
      <label htmlFor={label}>{label}</label>
      <StyledSelect
        multi
        contentRenderer={customContentRenderer}
        dropdownRenderer={customDropdownRenderer}
        options={options}
        additionalProps={{ id: label, "aria-label": label }}
        values={values}
        valueField={valueField}
        labelField={labelField}
        {...rest}
      />
    </SelectWrapper>
  )
}

SelectWithSearchAndSelectAll.defaultProps = {
  valueField: 'value',
  labelField: 'label',
  limitOptions: 200
}

export { SelectWithSearchAndSelectAll }
