import { useEffect, useState } from "react";
import { HeaderInvoicesOrder } from "./header-invoices-order";
import { MainInvoicesOrder } from "./main-invoices-order";
import { getInvoicesOrdersBySapOrderCode } from "./invoices-order.service";
import intl from "react-intl-universal";
import { OpenWindowPrint } from "./utils";

import * as S from "./styled";
import "./invoices-order.css";

export const InvoicesOrder = ({ match }) => {
  const [data, setData] = useState();

  const loadInvoices = async () => {
    const _document = await getInvoicesOrdersBySapOrderCode(
      match.params.orderSapCode
    );
    setData(_document);
  };

  useEffect(() => {
    loadInvoices();
  }, []);

  if (!data?.Orders) {
    return (
      <div className="no-records-message">
        {intl.get("commons.noRecordsFound")}
      </div>
    );
  }

  return (
    <S.Wrapper id="wrapper-invoice-orders">
      <OpenWindowPrint />
      <HeaderInvoicesOrder data={data} />
      <MainInvoicesOrder
        data={data}
      />
    </S.Wrapper>
  );
};
