import React from 'react'
import intl from 'react-intl-universal'
import { Button } from 'components/uikit-adapter/index'
import Dialog from "material-ui/Dialog"
import { ModalText, ModalTextAttention, ModalTitle } from './styled'

const ModalSuggestionsMassAcceptance = ({ open, close, onClick, suggestions, suggestionsInOver }) => (
  <Dialog
    open={open}
    contentStyle={{ width: "600px" }}
  >
    <ModalTitle>{intl.get('suggestions.messageModalConfirmation')}</ModalTitle>
    <ModalText isLast={suggestionsInOver.length == 0}>{suggestions.length} {intl.get('suggestions.messageModalMessage')} {suggestions.map((x) => x.Id).join(', ')}</ModalText>
    {(suggestionsInOver.length > 0) &&
      <ModalTextAttention> {intl.get('suggestions.messageModalAttention')} {suggestionsInOver.map((x) => x.Id).join(', ')} </ModalTextAttention>
    }
    <div className="botoes-modal">
      <Button
        width="150px"
        value={intl.get('geral.modalConfirm.no')}
        onClick={close}
        className="btn"
      />
      <Button
        width="150px"
        type="primary"
        value={intl.get('geral.modalConfirm.yes')}
        onClick={onClick}
        className="btn margin-botao"
      />
    </div>
  </Dialog>
)

export default ModalSuggestionsMassAcceptance;
