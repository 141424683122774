import React from 'react'
import * as S from '../../styled'
import { Draggable } from 'react-beautiful-dnd';
import { useLoadCompositionBuildingContext } from '../../../../context/load-composition-building-context';
import intl from 'react-intl-universal'
import { getGeneralSettingValue } from '../../../../../../services/general-settings/general-settings';
import CardOrderSuggestionIntoLoadComposition from '../../../card-into-load-composition';
import { loadCompositionBrewerySideActive } from 'services/general-settings/get-general-settings';
import { GeneralSettingParameter } from 'models/generalSetting/generalSetting';

function CardsContent({ data, providedDragPlaceholder, openModal, isEditLoadComposition }) {
  const {
    isEditDateMode,
    removeSelectedLoads
  } = useLoadCompositionBuildingContext();

  const isScheduleAgreementFlow = () => getGeneralSettingValue(GeneralSettingParameter.OrderSettings);
  const codeDescription = loadCompositionBrewerySideActive() && !isScheduleAgreementFlow() ? intl.get('requestOrders.PurchaseOrder') : intl.get('requestOrders.WmsTransactionId');

  return (
    <S.WrapperContent
      isEditDateMode={isEditDateMode}
      isEditLoadComposition={isEditLoadComposition}>
      {
        data.map((x, index) => (
          <Draggable key={x.IdCard} draggableId={x.IdCard} index={index}>
            {(providedDrag) => (
              <span ref={providedDrag.innerRef} {...providedDrag.draggableProps} {...providedDrag.dragHandleProps}>
                <CardOrderSuggestionIntoLoadComposition
                  data={x}
                  materialNumbers={data.map((x) => x.NumeroMaterial)}
                  handleClick={() => removeSelectedLoads(x.IdCard)}
                  openModal={() => openModal(x, true, true)}
                  codeDescription={codeDescription}
                />
              </span>
            )}
          </Draggable>
        ))}
      {providedDragPlaceholder}
    </S.WrapperContent>
  )
}

export default CardsContent;
