import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Loading from 'components/center-loading'
import { Row, Col } from 'react-flexbox-grid'
import { SelectBox, Button } from "components/uikit-adapter/index"

import intl from 'react-intl-universal'

import StyledFilter from './StyledFilter'

class FiltroGeral extends Component {
  constructor() {
    super()
    this.state = {
      isFetching: false,
      IdCarteira: null,
      IdFamilia: null,
      IdFamiliaRotulada: null,
      IdCentro: null,
      IdCoordenacao: null,
      filtersLoaded: false,
    }
  }

  componentDidMount() {
    this.getFilters();
  }

  getFilters() {
    const { initialData } = this.props;
    this.setState({
      IdCarteira: initialData.IdCarteira,
      IdFamilia: initialData.IdFamilia,
      IdFamiliaRotulada: initialData.IdFamiliaRotulada,
      IdCentro: initialData.IdCentro,
      IdCoordenacao: initialData.IdCoordenacao,
    })
  }

  changeValue = (name, value) => {
    this.setState({
      [name]: value,
    })
  }

  handleCleanClick = () => {
    const { handleClose } = this.props;
    const params = {
      IdCarteira: null,
      IdFamilia: null,
      IdFamiliaRotulada: null,
      IdCentro: null,
      IdCoordenacao: null,
    }
    this.setState(params, () => handleClose(params));
  }

  handleCloseSearch = () => {
    const {
      IdCarteira,
      IdFamilia,
      IdFamiliaRotulada,
      IdCentro,
      IdCoordenacao,
    } = this.state;
    const { handleClose } = this.props;
    const params = {
      IdCarteira,
      IdFamilia,
      IdFamiliaRotulada,
      IdCentro,
      IdCoordenacao,
    }
    handleClose(params);
  }

  render() {
    const { filterOptions } = this.props;
    const {
      IdCarteira,
      IdFamilia,
      IdFamiliaRotulada,
      IdCentro,
      IdCoordenacao,
      isFetching
    } = this.state
    return (
      <StyledFilter>
        <Loading isLoading={isFetching} />
        <div className="filter-container">
          <div className="sideFilterFields">
          <Row>
              <Col xs={12} lg={12} md={12}>
                <SelectBox
                  name='center'
                  label={intl.get('master-data.general-configuration.center')}
                  placeholder={intl.get('master-data.general-configuration.center')}
                  valueKey="IdUnidadeNegocio"
                  labelKey="Descricao"
                  value={IdCentro}
                  onChange={value => this.changeValue('IdCentro', value === null ? null : value.IdUnidadeNegocio)}
                  options={filterOptions.centers}
                  searchable
                  openOnFocus
                  clearable
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={12} md={12}>
                <SelectBox
                  name='coordinations'
                  label={intl.get('master-data.general-configuration.coordination')}
                  placeholder={intl.get('master-data.general-configuration.coordination')}
                  valueKey="Id"
                  labelKey="Description"
                  value={IdCoordenacao}
                  onChange={value => this.changeValue('IdCoordenacao', value === null ? null : value.Id)}
                  options={filterOptions.coordinations}
                  searchable
                  openOnFocus
                  clearable
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={12} md={12}>
                <SelectBox
                  name='wallet'
                  label={intl.get('commons.wallet')}
                  placeholder={intl.get('commons.wallet')}
                  valueKey="Id"
                  labelKey="Description"
                  value={IdCarteira}
                  onChange={value => this.changeValue('IdCarteira', value === null ? null : value.Id)}
                  options={filterOptions.wallets}
                  searchable
                  openOnFocus
                  clearable
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={12} md={12}>
                <SelectBox
                  name='family'
                  label={intl.get('commons.family')}
                  placeholder={intl.get('commons.family')}
                  valueKey="Id"
                  labelKey="Name"
                  value={IdFamilia}
                  onChange={value => this.changeValue('IdFamilia', value === null ? null : value.Id)}
                  options={filterOptions.families}
                  searchable
                  openOnFocus
                  clearable
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={12} md={12}>
                <SelectBox
                  name='labeledFamily'
                  label={intl.get('commons.labeledFamily')}
                  placeholder={intl.get('commons.labeledFamily')}
                  valueKey="Id"
                  labelKey="Description"
                  value={IdFamiliaRotulada}
                  onChange={value => this.changeValue('IdFamiliaRotulada', value === null ? null : value.Id)}
                  options={filterOptions.labeledFamilies}
                  searchable
                  openOnFocus
                  clearable
                />
              </Col>
            </Row>
          </div>
          <Row className="footerFilterContainer no-margin">
            <Col xs={12}>
              <Row>
                <Col xs={12} lg={12} md={12}>
                  <hr className="filter-divisor" />
                </Col>
              </Row>
              <Row>
                <Col xs={6} lg={6} md={6}>
                  <Button type="primary" onClick={this.handleCloseSearch} value={intl.get('filters.filter')} />
                </Col>
                <Col xs={6} lg={6} md={6}>
                  <Button type="secondary" onClick={this.handleCleanClick} value={intl.get('filters.cleanSearch')} />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </StyledFilter>
    )
  }
}

FiltroGeral.propTypes = {
  initialData: PropTypes.object,
  transitionTime: PropTypes.number,
  handleClose: PropTypes.func
}

FiltroGeral.defaultProps = {
  transitionTime: 500,
  initialData: {}
}

export default FiltroGeral
