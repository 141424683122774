import { Dialog } from '@material-ui/core'
import { Button } from "components/uikit-adapter/index";
import { formatNumber } from "utils/format";
import intl from 'react-intl-universal'
import * as S from './styled'
import { ClbButtonIcon } from '@celebration/design-system-react';

export const ModalFullTruckLoad = ({ open, handleClose, item, goToLoadCompositionBuilding }) => (
  <Dialog
    open={open}
    onClose={handleClose}
    style={{ minWidth: '620px' }}
  >
    <S.TitleWrapper>
      <span>{intl.get('modalFullTruckLoad.title')}</span>
      <ClbButtonIcon onClick={handleClose} size='lg' icon='Close' />
    </S.TitleWrapper>
    <S.ContentWrapper>
      <span>{intl.get('modalFullTruckLoad.description')}</span>
      <table>
        <tr>
          <th>{intl.get(`manual-suggestion-order-form.origin`)}</th>
          <th>{intl.get(`manual-suggestion-order-form.destination`)}</th>
          <th>{intl.get(`manual-suggestion-order-form.material`)}</th>
          <th>{intl.get(`commons.amount`)}</th>
        </tr>
        <tr>
          <td>{item.Supplier}</td>
          <td>{item.Destination}</td>
          <td>{item.Material}</td>
          <td>{formatNumber(item.Total)}</td>
        </tr>
      </table>
      <S.ActionsWrapper>
        <Button
          type="primary"
          className='label-white'
          value={intl.get('modalFullTruckLoad.btnSelectTime')}
          onClick={() => goToLoadCompositionBuilding(true)}
        />
        <Button
          type="default"
          value={intl.get('modalFullTruckLoad.btnAddMore')}
          className='tertiary'
          onClick={() => goToLoadCompositionBuilding(false)}
        />
        <Button
          type="default"
          value={intl.get('geral.buttonsDefault.cancel')}
          onClick={handleClose}
        />
      </S.ActionsWrapper>
    </S.ContentWrapper>
  </Dialog>
)
