import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import MenuIcon from 'material-ui/svg-icons/navigation/menu'
import Paper from 'material-ui/Paper'
import Header from 'components/header'
import MenuLateral from 'components/menu-lateral'
import BudgetFamily from 'pages/master-data/budget-family'
import MasterSupplier from 'pages/master-data/suppliers'
import CoordinationUser from 'pages/master-data/coordination-user'
import Material from 'pages/master-data/material'
import MaterialBusinessUnit from 'pages/master-data/general-configuration/material-business-unit'
import BusinessUnit from 'pages/master-data/unit-management/business-unit'
import General from 'pages/master-data/general-configuration/general'
import Grade from 'pages/master-data/unit-management/grade'
import SiloManagement from 'pages/master-data/unit-management/silos'
import ConfiguracoesIntegracoes from 'pages/master-data/configurations/integrations'
import UnidadesArredondamento from 'pages/master-data/configurations/rounding-unit'
import ReleaseVersion from 'pages/master-data/configurations/release-version'
import PersonIcon from 'material-ui/svg-icons/social/person'
import ActionShoppingCart from 'material-ui/svg-icons/action/shopping-cart'
import CriticalItem from 'pages/master-data/configurations/critical-items'
import DataUpload from 'pages/master-data/configurations/data-upload'
import PermissionsSettings from 'pages/master-data/configurations/permissions-settings'
import IMOManagement from 'pages/master-data/configurations/imo-management'
import DeliveryScheduleConfiguration from 'pages/master-data/unit-management/delivery-schedule-configuration'
import GeneralSettings from 'pages/master-data/configurations/general-settings'
import { white } from 'material-ui/styles/colors'
import {
  GeneralIcon,
  FactoryManagerIcon,
  FactoryIcon,
  GradeIcon,
  SiloIcon,
  WalletIcon,
  GroupUserIcon,
  ConfigurationIcon,
  IntegrationIcon,
  RoundingUnit,
  CriticalItemIcon,
  DataUploadItem,
  PermissionsProfileItem,
  GestaoIMOIcon,
  DeliveryScheduleConfigurationIcon,
  GeneralSettingsIcon
} from 'components/icons/icn-index.icon'
import {
  ReleaseVersionIcon
} from 'components/icons/release-version.icn.icon'
import Session from 'utils/user-storage'

import intl from 'react-intl-universal';

import './master-data.css'
import { getGeneralSettingValue } from '../../services/general-settings/general-settings'
import { GeneralSettingParameter } from 'models/generalSetting/generalSetting'
import { usesReturnableAssets } from 'utils/validations-general-settings'
import { AutoPilot } from 'pages/auto-pilot'
const PERFIL_ADMIN = 6

class MasterData extends Component {
  constructor() {
    super()

    this.state = {
      exibirMenu: false,
      hidden: true,
      transitionTime: 600,
      listaMenus: [
        {
          rota: 'general',
          titulo: intl.get('master-data.menus.general'),
          icone: <GeneralIcon width='25px' height='25px' color={white} />,
          selecionado: true,
        },
        {
          rota: 'budget-family',
          titulo: intl.get('master-data.menus.walletsAndFamilys'),
          icone: <WalletIcon color={white} />,
          selecionado: false,
        },
        {
          rota: 'coordination-user',
          titulo: intl.get('master-data.menus.coordinationsAndUsers'),
          icone: <PersonIcon color={white} />,
          selecionado: false,
        },
        {
          rota: 'supplier',
          titulo: intl.get('master-data.menus.providers'),
          icone: <GroupUserIcon color={white} />,
          selecionado: false,
        },
        {
          rota: 'material',
          titulo: intl.get('master-data.menus.materials'),
          icone: <ActionShoppingCart color={white} />,
          selecionado: false,
        },
        {
          rota: '',
          titulo: intl.get('master-data.menus.managementUnitys'),
          icone: <FactoryManagerIcon color={white} />,
          selecionado: false,
          dataTestId: 'master-data-menu-management-unities',
          subMenus: [
            {
              rota: 'business-unit',
              titulo: intl.get('master-data.menus.submenus.unitys'),
              icone: <FactoryIcon color={white} />,
              selecionado: false,
              visivel: true,
            },
            {
              rota: 'parametrizacao-grade',
              titulo: intl.get('master-data.menus.submenus.grid'),
              icone: <GradeIcon color={white} />,
              selecionado: false,
              visivel: true,
            },
            {
              rota: 'silo-management',
              titulo: intl.get('master-data.menus.submenus.silo'),
              icone: <SiloIcon color={white} />,
              selecionado: false,
              visivel: false,
            },
            {
              rota: 'delivery-schedule-configuration',
              titulo: intl.get('master-data.menus.submenus.deliverySchedule'),
              icone: <DeliveryScheduleConfigurationIcon />,
              selecionado: false,
              visivel:  this.isNewFlow(),
            }
          ]
        },
        {
          rota: '',
          titulo: intl.get('master-data.menus.configuration'),
          icone: <ConfigurationIcon color={white} />,
          selecionado: false,
          dataTestId: 'master-data-menu-configuration',
          subMenus: [
            {
              rota: 'general-settings',
              titulo: intl.get('master-data.menus.submenus.general-settings'),
              icone: <GeneralSettingsIcon />,
              selecionado: false,
              visivel: this.isUserAdmin()
            },
            {
              rota: 'imo-management',
              titulo: intl.get('master-data.menus.submenus.gestao-imo'),
              icone: <GestaoIMOIcon color={white} />,
              selecionado: false,
              visivel: usesReturnableAssets(),
            },
            {
              rota: 'configuracoes-integracoes',
              titulo: intl.get('master-data.menus.submenus.integrations'),
              icone: <IntegrationIcon color={white} />,
              selecionado: false,
              visivel: true,
            },
            {
              rota: 'critical-items',
              titulo: intl.get('master-data.menus.submenus.critical-items'),
              icone: <CriticalItemIcon color={white} />,
              selecionado: false,
              visivel: true,
            },
            {
              rota: 'unidades-arredondamento',
              titulo: intl.get('master-data.menus.submenus.roundingUnits'),
              icone: <RoundingUnit color={white} />,
              selecionado: false,
              visivel: true,
            },
            {
              rota: 'data-upload',
              titulo: intl.get('master-data.menus.submenus.data-upload'),
              icone: <DataUploadItem color={white} />,
              selecionado: false,
              visivel: true,
              dataTestId: 'master-data-menu-data-upload',
            },
            {
              rota: 'release-version',
              titulo: intl.get('master-data.menus.submenus.versions'),
              icone: <ReleaseVersionIcon color={white} />,
              selecionado: false,
              visivel: true
            },
            {
              rota: 'permissions-settings',
              titulo: intl.get('master-data.menus.submenus.permission-setting'),
              icone: <PermissionsProfileItem color={white} />,
              selecionado: false,
              visivel: this.isUserAdmin()
            }
          ]
        }
      ]
    }
  }

  isUserAdmin = () => Session.get().PerfilAcesso === PERFIL_ADMIN

  isNewFlow = () => getGeneralSettingValue(GeneralSettingParameter.LoadCompositionNewFlow)

  exibirMenuClick = () => {
    if (this.state.exibirMenu) {
      this.handleClickOver()
    }
    else {
      this.setState({
        exibirMenu: true,
        hidden: false
      })
    }
  }

  handleClickOver = () => {
    return new Promise((resolve) => {
      this.setState({
        exibirMenu: false,
      })

      setTimeout(() => {
        this.setState({
          hidden: true,
        })
        resolve()
      }, this.state.transitionTime)
    })
  }

  onChangeUrl = (rota) => {
    const { url } = this.props.match
    this.props.history.push(`${url}/${rota}`)
  }

  render() {
    const { exibirMenu, hidden, transitionTime, listaMenus } = this.state
    const { url } = this.props.match

    return (
      <div className='container-master-detail'>
        <div>
          <Header
            title='Master Data'
            icon={<MenuIcon color='#b3b3b3' />}
            onClickIcon={this.exibirMenuClick}
          />

          <MenuLateral
            exibirMenu={exibirMenu}
            hidden={hidden}
            onHandleClickOver={this.handleClickOver}
            transitionTime={transitionTime}
            onChangeUrl={this.onChangeUrl}
            listaMenus={listaMenus}
          />

          <Paper zDepth={0} className='master-body-container'>
            <Switch>
              <Route path={`${url}/general`} component={General} />
              <Route path={`${url}/budget-family`} component={BudgetFamily} />
              <Route path={`${url}/coordination-user`} component={CoordinationUser} />
              <Route path={`${url}/supplier`} component={MasterSupplier} />
              <Route path={`${url}/material`} component={Material} />
              <Route path={`${url}/business-unit`} component={BusinessUnit} />
              <Route path={`${url}/general-material`} component={MaterialBusinessUnit} />
              <Route path={`${url}/general-auto-pilot/:id`} component={AutoPilot} />
              <Route path={`${url}/parametrizacao-grade`} component={Grade} />
              <Route path={`${url}/silo-management`} component={SiloManagement} />
              <Route path={`${url}/configuracoes-integracoes`} component={ConfiguracoesIntegracoes} />
              <Route path={`${url}/unidades-arredondamento`} component={UnidadesArredondamento} />
              <Route path={`${url}/release-version`} component={ReleaseVersion} />
              <Route path={`${url}/critical-items`} component={CriticalItem} />
              <Route path={`${url}/data-upload`} component={DataUpload} />
              <Route path={`${url}/permissions-settings`} component={PermissionsSettings} />
              <Route path={`${url}/imo-management`} component={IMOManagement} />
              <Route path={`${url}/delivery-schedule-configuration`} component={DeliveryScheduleConfiguration} />
              <Route path={`${url}/general-settings`} component={GeneralSettings} />
            </Switch>
          </Paper>
        </div>
      </div>
    )
  }
}
export default MasterData
