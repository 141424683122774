import { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-flexbox-grid'
import { Button } from 'components/uikit-adapter/index'
import Fetch from 'utils/fetch'
import ImageAlert from 'images/icn-small-alert.svg'
import ImageError from 'images/icn-small-error.svg'
import ImageSuccess from 'images/icn-small-success.svg'
import Step from 'components/steps/step'
import Steps from 'components/steps'
import Loading from 'components/center-loading'
import InputFile from 'components/input-file'
import * as S from './styledImportExport'
import intl from 'react-intl-universal';
import IconButton from 'material-ui/IconButton'
import IconLog from "images/icn-log.svg";
import { downloadFileTxt } from 'utils/custom-functions';
import { LabelButton } from 'components/label-button'

const fullStepKeys = [
  "massTrading",
  "reviewTrading"
];

const postImportRoutes = ["labeled-family-center-materials:import", "materials:import"];

class ImportExport extends Component {
  constructor(props) {
    super(props)
    this.state = {
      stepSelected: 0,
      xls: [],
      labelStep3: intl.get(`exportImportModal.${this.props.contextModal}.step3`),
      listaResultados: [],
      importadoComSucesso: false,
      botaoAnteriorAtivo: true,
      isFetching: false,
      nadaImportado: false,
      logsList: [],
    }
  }

  componentDidMount() {
    this.setState({
      stepSelected: this.props.stepSelected ? this.props.stepSelected : 0
    }, () => this.verificarStepParaAtualizar());
  }

  atualizar = () => {
    const reader = new FileReader()

    reader.onload = () => {
      const base64 = window.btoa(reader.result)
      if (postImportRoutes.includes(this.props.importArquivo)) {
        Fetch.post(this.props.importArquivo, { Content: base64 })
          .then((response) => {
            this.tratarSucesso(response.data)
          })
          .catch((e) => {
            this.tratarErro(e)
          })
      } else {
        Fetch.put(this.props.importArquivo, { Content: base64 })
          .then((response) => {
            this.tratarSucesso(response.data)
          })
          .catch((e) => {
            this.tratarErro(e)
          })
      }
    }

    reader.readAsBinaryString(this.state.xls[0])
  }

  tratarSucesso = (data) => {
    this.setState({
      labelStep3: data.AllSuccess && data.Results.length > 0 ?
        intl.get(`exportImportModal.${this.props.contextModal}.success`) :
        intl.get(`exportImportModal.${this.props.contextModal}.noSuccess`),
      listaResultados: data.Results,
      logsList: data.Results.map(x => x.Message),
      isFetching: false,
      importadoComSucesso: data.AllSuccess,
      botaoAnteriorAtivo: !data.AllSuccess || data.Results.length === 0,
      nadaImportado: data.Results.length === 0
    })
  }

  tratarErro = (erro) => {
    this.setState({
      labelStep3: intl.get(`exportImportModal.${this.props.contextModal}.noSuccess`),
      botaoAnteriorAtivo: true,
      isFetching: false,
      listaResultados: [{
        Type: 0,
        Message: intl.get(`exportImportModal.${this.props.contextModal}.noSuccess`),
      }],
      logsList: [],
      nadaImportado: false,
    })
  }

  verificarStepParaAtualizar = () => {
    if (this.state.stepSelected === 2) {
      this.setState({
        labelStep3: intl.get(`exportImportModal.${this.props.contextModal}.processing`),
        isFetching: true,
      })
      this.atualizar()
    }
  }

  changeStepNext = () => {
    this.setState(prevState => ({
      stepSelected: prevState.stepSelected + 1,
    }), () => this.verificarStepParaAtualizar())
  }

  changeStepPrev = () => {
    this.setState(prevState => ({
      stepSelected: prevState.stepSelected - 1,
      labelStep3: intl.get(`exportImportModal.${this.props.contextModal}.step3`),
      botaoAnteriorAtivo: true,
      importadoComSucesso: false,
      listaResultados: [],
      nadaImportado: false,
    }))
  }

  close = () => {
    this.setState({
      stepSelected: 0,
      labelStep3: intl.get(`exportImportModal.${this.props.contextModal}.step3`),
      importadoComSucesso: false,
      xls: [],
      botaoAnteriorAtivo: true,
      nadaImportado: false,
      listaResultados: [],
      logsList: []
    }, () => this.props.handleClose())
  }

  getImage = (type) => {
    if (type === 0) {
      return (ImageError)
    } else if (type === 1) {
      return (ImageAlert)
    } else {
      return ImageSuccess
    }
  }

  getColorBorder = (type) => {
    if (type === 0) {
      return 'red'
    } else if (type === 1) {
      return 'yellow'
    } else {
      return 'green'
    }
  }

  stopFeching = () => {
    this.setState({
      isFetching: false
    })
  }

  startFeching = () => {
    this.setState({
      isFetching: true
    })
  }

  dowloadArquivo = () => {
    try {
      const { downloadArquivo } = this.props
      this.startFeching();
      downloadArquivo().then(() =>
        this.stopFeching()
      )
    }
    catch (e) {
      this.stopFeching();
    }
  }

  dowloadArquivoModel = () => {
    try {
      const { downloadModelo } = this.props
      this.startFeching();
      downloadModelo().then(() =>
        this.stopFeching()
      )
    }
    catch (e) {
      this.stopFeching();
    }
  }

  hasContent = (content) => content && content.hasFilter
  hasType = (type) => type ? type : "XLSX"

  downloadLogsTxt = () => {
    const formatLogs = this.state.logsList.map(x => x.toString()).join('\n');
    downloadFileTxt(`error_log_${this.state.xls[0].name}.txt`, formatLogs);
  }

  getAccept = (type) =>
    type && type.toLowerCase() === "csv"
      ? ".csv"
      : "application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

  getFileName = () =>
    this.state.xls.length > 0 ? (this.state.xls[0].name) : ("");

  getMessageForImport = () => this.state.importadoComSucesso ?
    intl.get(`exportImportModal.${this.props.contextModal}.importSuccess`)
    : intl.get(`exportImportModal.${this.props.contextModal}.notSucceedingImport`)


  render() {
    const { open, title, contextModal, hiddenButtonExport, type, noMessageExample, content } = this.props
    const exibirListaResultado = this.state.listaResultados.length > 0
    const showFullSteps = fullStepKeys.find(elem => elem === contextModal);

    return (
      contextModal &&
      <S.ImportExportModal
        bodyClassName='import-export__modal'
        title={title}
        contentStyle={{ width: '700px' }}
        open={open}
      >
        <S.StyledImportExport>
          <Loading isLoading={this.state.isFetching} fullHeightParent />
          <Steps
            blockedSteps={true}
            selected={this.state.stepSelected}
          >
            <Step completed={this.state.stepSelected > 0}
              label={intl.get(`exportImportModal.${contextModal}.step1`)}>
              {this.hasContent(content) ?
                content.component :
                <div className="content">
                  <span className="titulo">
                    {intl.get(`exportImportModal.${contextModal}.messageStep1-p1`)}
                  </span>
                  {showFullSteps &&
                    <span className="titulo">
                      {intl.get(`exportImportModal.${contextModal}.messageStep1-p2`)}
                    </span>
                  }
                  {showFullSteps &&
                    <span className="titulo last-titulo">
                      {intl.get(`exportImportModal.${contextModal}.messageStep1-p3`)}
                    </span>
                  }
                  {!hiddenButtonExport &&
                    <div className="button-download-content" data-testid='button-download-content'>
                      <Button
                        type="secondary"
                        className="button-download"
                        onClick={this.dowloadArquivo}
                        value={intl.get(`exportImportModal.butttons.download`)}
                      />
                    </div>
                  }
                </div>
              }
            </Step>
            <Step completed={this.state.stepSelected > 1} label={intl.get(`exportImportModal.${contextModal}.step2`)}>
              <div className="content">
                <span className="titulo">
                  {intl.get(`exportImportModal.${contextModal}.messageStep2`)}
                </span>
                <LabelButton className="titulo link" data-testid='download-model-import' onClick={this.dowloadArquivoModel}>
                  {!noMessageExample && intl.get(`exportImportModal.${contextModal}.downloadModelMessage`)}
                </LabelButton>
                <InputFile
                  name="xls"
                  placeholder="XLSe"
                  className="file-ajust"
                  label={`${intl.get('master-data.general.stockPolicyModal.archiveName')} ${this.hasType(type)}`}
                  accept={this.getAccept(type)}
                  filename={this.getFileName()}
                  onChange={e => {
                    e.preventDefault()
                    this.setState({
                      xls: [...e.target.files]
                    });
                  }}
                />

              </div>
            </Step>
            <Step completed={this.state.stepSelected > 2} label={this.state.labelStep3}>
              <div className="content">
                {!exibirListaResultado && !this.state.nadaImportado && <span className="titulo">
                  {intl.get(`exportImportModal.${contextModal}.messageWaitImport`)}
                </span>}
                {!exibirListaResultado && this.state.nadaImportado && <span className="titulo">
                  {intl.get(`exportImportModal.${contextModal}.noInformationFound`)}
                </span>}
                {exibirListaResultado &&
                  <Col xs={12} md={12} lg={12} className="lista-resultados">
                    <Row className="rowListHeader">
                      <span>
                        {this.getMessageForImport()}
                      </span>
                    </Row>
                    <Row className="lista-mensagens">
                      <Col xs={12} md={12} lg={12} className="itens-mensagens">
                        {this.state.listaResultados.map((value, i) => (
                          <Row key={i} className={`rowList ${this.getColorBorder(value.Type)}`} style={{ borderBottom: '1px dashed #98c1dc' }}>
                            <Col xs={1} md={1} lg={1} className="align-center"><img src={this.getImage(value.Type)} height="20px" alt="Tipo" /></Col>
                            <Col xs={11} md={11} lg={11}>{value.Message}</Col>
                          </Row>
                        ))}
                      </Col>
                    </Row>
                  </Col>}
              </div>
            </Step>
          </Steps>
          <div className="content-footer" data-testid='content-footer'>
            <Button
              onClick={this.close}
              className="button-footer"
              type="default"
              value={intl.get(`geral.buttonsDefault.close`)}
            />
            <div className="footer-right">
              {exibirListaResultado
                && !this.state.importadoComSucesso
                && (contextModal === 'masterData' || contextModal === 'stockView')
                && <IconButton
                  title={intl.get(`exportImportModal.butttons.downloadLogs`)}
                  onClick={this.downloadLogsTxt}
                  style={{ height: '40px', width: '40px', padding: '0px', marginRight: '8px' }}>
                  <img src={IconLog} className="button-footer btn-dowload-logs" />
                </IconButton>
              }
              {this.state.stepSelected > 0 && this.state.botaoAnteriorAtivo && <Button
                onClick={this.changeStepPrev}
                className="button-footer"
                type="primary"
                value={intl.get(`geral.buttonsDefault.previous`)}
              />}
              {this.state.stepSelected < 2 && <span data-testid='btn-next-import-export'>
                <Button
                  onClick={this.changeStepNext}
                  className="button-footer btn-next"
                  type="primary"
                  value={intl.get(`geral.buttonsDefault.next`)}
                  disabled={this.state.stepSelected === 1 && !this.state.xls.length}
                />
              </span>
              }
            </div>
          </div>
        </S.StyledImportExport>
      </S.ImportExportModal>
    )
  }
}

ImportExport.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  contextModal: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  downloadArquivo: PropTypes.func.isRequired,
  downloadModelo: PropTypes.func,
  importArquivo: PropTypes.string.isRequired
}

export default ImportExport
