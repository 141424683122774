import React, { useState, useEffect } from 'react'
import SuggestionsListHeader from "components/suggestions-list/suggestions-list-header"
import StyledSuggestionsListItem from "./suggestion-list-item-styled"
import { Col, Row } from "react-flexbox-grid"
import { DATA_FORMAT_TYPES, getDataFormat } from "utils/format-date"
import { formatNumber } from "utils/format"
import { Button, Input, SelectBox } from "components/uikit-adapter"
import intl from 'react-intl-universal'
import DeadlineControl from "components/deadline-control"
import InputNumber from "components/input-number"
import { Textarea } from '@hbsis.uikit/react'
import IconSaveWhite from 'images/icn-save-white.svg'
import { saveSuggestionListItem } from "features/consolidated-stocks/consolidated-stocks.service"
import { getTextAreaStyle } from 'components/suggestions-list/suggestions-list-item/suggestions-list-item.styled'
import { ClbToastBase } from '@celebration/design-system-react'
import { Incoterms } from 'models/incoterm/incoterms';
import moment from 'moment'

export const ConsolidatedStocksSuggestionListItem = ({ item, index, onSaveItem, checked, updateGraphic }) => {
  const [controlToast, setControlToast] = useState({ show: false, message: '' })
  const [updatedItem, setUpdatedItem] = useState(item)
  const [expand, setExpand] = useState(false)
  const incotermOptions = Incoterms

  const checkItem = () => {
    onSaveItem({ ...updatedItem, checked: !checked }, index)
  }
  const getClassNameWrapper = () => expand ? 'item-expanded' : ''

  const handleDates = (pickUpDate, deliveryDate) => {
    setUpdatedItem(prevState => ({
      ...prevState,
      PickUpDate: pickUpDate,
      DeliveryDate: deliveryDate
    }))
  }

  const handleItem = (prop, data) => {
    setUpdatedItem(prevState => ({
      ...prevState,
      [prop]: data
    }))
  }

  const handleSaveItem = async (newItem, index) => {
    try {
      const quantity = (updatedItem.LotSize * updatedItem.PalletQuantity)

      if ((quantity === 0 || !quantity)) {
        setControlToast({
          show: true,
          message: intl.get('stocks.timeline.feedback.attentionQuantityMustBeGreaterThanZero')
        })
      }
      else if (newItem.DeliveryDate < newItem.PickUpDate) {
        setControlToast({
          show: true,
          message: intl.get('stocks.timeline.feedback.collectionDateMustBeLessThanDeliveryDate')
        })
      }
      else if (newItem.DeliveryDate < moment().format("YYYY-MM-DD") || newItem.PickUpDate < moment().format("YYYY-MM-DD")) {
        setControlToast({
          show: true,
          message: intl.get('stocks.timeline.feedback.dateMustBeGreaterThanToday')
        })
      }
      else {
        await saveSuggestionListItem({
          IdSugestao: newItem.Id,
          IdMaterial: newItem.MaterialId,
          IdFornecedor: newItem.SupplierId,
          DataColeta: newItem.PickUpDate,
          EntregaDe: newItem.DeliveryDate,
          EntregaAte: newItem.DeliveryDate,
          QuantidadeSugeridaDe: quantity,
          QuantidadeSugeridaAte: quantity,
          HoraEntrega: newItem.DeliveryTime,
          ResponsavelEntrega: newItem.IncoTerm.value
        })
        onSaveItem(newItem, index)
        updateGraphic(intl.get('stocks.timeline.feedback.suggestionSavedSuccessfully'))
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    setControlToast({show: false})
  }, [updatedItem])

  return (
    <StyledSuggestionsListItem className={getClassNameWrapper()}>
      <SuggestionsListHeader
        isItem
        hasIcon
        isOrder={false}
        itemsExpanded={expand}
        checked={checked}
        idCheckbox={item.Id}
        checkItem={checkItem}
        highlighted={expand}
        itemDimmed={false}
        expandAllItems={() => setExpand(!expand)}
      >
        <Row middle='xs'>
          <Col xs={2}>
            <div title={updatedItem.StatusDescription} className='header-item'>
              {updatedItem.StatusAcronym}
            </div>
          </Col>
          <Col xs={2}>

            <div title={updatedItem.SuggestionType} className='header-item'>
              {updatedItem.SuggestionType}
            </div>
          </Col>
          <Col xs={2}>
            <div title={getDataFormat(updatedItem.PickUpDate, DATA_FORMAT_TYPES.WITH_YEAR)} className='header-item'>
              {getDataFormat(updatedItem.PickUpDate, DATA_FORMAT_TYPES.WITHOUT_YEAR)}
            </div>
          </Col>
          <Col xs={2}>
            <div title={getDataFormat(updatedItem.DeliveryDate, DATA_FORMAT_TYPES.WITH_YEAR)} className='header-item'>
              {getDataFormat(updatedItem.DeliveryDate, DATA_FORMAT_TYPES.WITHOUT_YEAR)}
            </div>
          </Col>
          <Col xs={2}>
            <div className='header-item'>
              {formatNumber(updatedItem.LotSize * updatedItem.PalletQuantity)}
            </div>
          </Col>
          <Col xs={2}>
            <div className='header-item'>{updatedItem.UnitOfMeasurement}</div>
          </Col>
        </Row>
      </SuggestionsListHeader>
      <div
        className='table-list-item-body'
        style={{ display: expand ? 'block' : 'none' }}
      >
        <Row>
          <Col xs={12}>
            <SelectBox
              name='material'
              label={intl.get("commons.material")}
              placeholder={intl.get('commons.material')}
              value={updatedItem.MaterialId}
              valueKey='Id'
              labelKey='MaterialName'
              options={[
                {
                  Id: updatedItem.MaterialId,
                  MaterialName: `${updatedItem.MaterialNumber} - ${updatedItem.MaterialName}`
                }
              ]}
              searchable
              openOnFocus
              disabled
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <SelectBox
              name='fornecedor'
              label={intl.get('commons.supplier')}
              placeholder={intl.get('stocks.suggestionsListItem.supplier')}
              value={updatedItem.SupplierId}
              options={[{
                Id: updatedItem.SupplierId,
                SupplierName: updatedItem.SupplierNickname
              }]}
              valueKey='Id'
              labelKey='SupplierName'
              searchable
              openOnFocus
              disabled
            />
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <DeadlineControl
              TransitTime={updatedItem.TransitTime}
              TituloColeta={intl.get('stocks.suggestionsListItem.collect')}
              TituloEntrega={intl.get('stocks.suggestionsListItem.issue')}
              Coleta={updatedItem.PickUpDate}
              DataEntregaDe={updatedItem.DeliveryDate}
              Identificador={`sugestao-` + updatedItem.Id}
              OnChange={(coleta, entrega) => handleDates(coleta, entrega)}
            />
          </Col>
        </Row>

        <Row>
          <Col xs={6}>
            <Input
              label={intl.get('commons.deliveryTime')}
              name='HoraEntrega'
              className='input'
              htmlType='time'
              value={updatedItem.DeliveryTime}
              onChange={(event) => handleItem('DeliveryTime', event.target.value)}
              data-testid='delivery-time-input'
            />
          </Col>
          <Col xs={6}>
            <SelectBox
              required
              label={intl.get('stocks.suggestionsListItem.incoterm')}
              placeholder={intl.get('stocks.suggestionsListItem.incoterm')}
              name='Incoterm'
              value={updatedItem.IncoTerm}
              valueKey='Key'
              labelKey='Value'
              openOnFocus={true}
              options={incotermOptions}
              width="100%"
              onChange={(value) => handleItem('IncoTerm', value)}
              data-testid='incoterm'
            />
          </Col>
        </Row>
        <Row>
          <Col xs={3}>
            <Input
              className='input'
              label={intl.get('stocks.suggestionsListItem.lotSize')}
              name='tamLote'
              htmlType='text'
              value={formatNumber(updatedItem.LotSize)}
              disabled
            />
          </Col>
          <Col xs={3}>
            <InputNumber
              value={updatedItem.PalletQuantity}
              className='input-received-quantity'
              label={intl.get('stocks.suggestionsListItem.quantity')}
              type='default'
              allowDecimals
              decimalsLimit={3}
              onChange={(value) => handleItem('PalletQuantity', value)}
              data-testid='pallet-quantity-input'
            />
          </Col>
          <Col xs={3}>
            <InputNumber
              type='default'
              label={intl.get('stocks.suggestionsListItem.cars')}
              name='carros'
              value={(updatedItem.TruckQuantity).toFixed(2)}
              allowDecimals
              decimalsLimit={3}
              disabled
            />
          </Col>
          <Col xs={3}>
            <InputNumber
              type='default'
              label={intl.get('stocks.suggestionsListItem.total')}
              name='total'
              value={(updatedItem.LotSize * updatedItem.PalletQuantity)}
              disabled
              allowDecimals
              decimalsLimit={3}
            />
          </Col>
          <React.Fragment>
            <Col xs={4}>
              <SelectBox
                name='Door'
                label={intl.get("commons.door")}
                className="input-door-wms"
                disabled
                value={updatedItem.WmsDoor}
                valueKey='Id'
                labelKey='DoorName'
                options={[
                  {
                    Id: updatedItem.WmsDoor,
                    DoorName: updatedItem.WmsDoor

                  }
                ]}
                openOnFocus={true}

              />
            </Col>
            <Col xs={4}>
              <SelectBox
                name='ProductionLine'
                label={intl.get("orders.productionLine")}
                placeholder={intl.get("orders.productionLine")}
                value={updatedItem.ProductionLine}
                valueKey='Id'
                labelKey='ProductionLine'
                options={[
                  {
                    Id: updatedItem.ProductionLine,
                    ProductionLine: updatedItem.ProductionLine

                  }
                ]}
                className='selectBoxProductionLine'
                clearable
                disabled
              />
            </Col>
            <Col xs={4}>
              <Input
                label={intl.get('orders.comments')}
                name="comments"
                value={updatedItem.Comments}
                maxLength='40'
                className='textAreaComments input'
                disabled
              />
            </Col>
          </React.Fragment>
        </Row>
        <Row>
          <Col xs={12}>
            <Textarea
              style={getTextAreaStyle()}
              label={intl.get('stocks.suggestionsListItem.observation')}
              name='observacao'
              value={item.Notes}
              disabled
            />
          </Col>
        </Row>
        <Row end='xs'>
          <Col xs={12} className='table-list-footer'>
            <div title={intl.get('stocks.suggestionsListItem.saveSuggestion')} data-testid='save-suggestion-btn'>
              <Button
                icon={IconSaveWhite}
                width='50px'
                value=''
                type='primary'
                onClick={() => handleSaveItem(updatedItem, index)}
              />
            </div>
          </Col>
        </Row>
      </div>
      <ClbToastBase
        style={{ position: 'fixed', zIndex: '999', top: '0', right: '10px', color: "white" }}
        type={"negative"}
        title={intl.get('commons.fail')}
        description={controlToast.message}
        open={controlToast.show}
      />
    </StyledSuggestionsListItem >
  )
}

