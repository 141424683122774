import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import moment from 'moment';
import { AccordionContent, BlankSpace, Icon, WrapperRequestsLoading } from "./styled";
import { useState } from "react";
import { formatNumber } from "utils/format";
import { TextSpan } from "components/text-label";
import Loading from "components/circular-progress";
import checkSuccess from 'images/check-circle-all-ok.svg'
import checkError from 'images/icn-small-error.svg'
import checkBlocked from 'images/icn-small-blocked.svg'
import { useLoadBuildingContext } from "features/load-building/context";
import { StatusRequest } from "features/load-building/utils";
import Feature from "components/feature";
import { Button } from "components/uikit-adapter";
import { IconTrash } from "components/icons/icn-index.icon";
import ModalDeleteLoad from "./modal-delete-load";
import { formatDeliveryTime } from "utils/delivery-time";

export const LoadAccordionList = ({ index, data, itemNumber, listLabel }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { loadsSending, handleDelete } = useLoadBuildingContext();
  const [openModal, setOpenModal] = useState(false);

  const getStatusLoad = () => loadsSending?.find(x => x.Id === itemNumber)?.Status
  const isFetching = loadsSending?.length > 0;

  const handleDeleteLoad = () => {
    handleDelete(index, listLabel);
    setOpenModal(false);
  }

  return (
    <>
      <AccordionContent isLoading={isFetching} isExpanded={isExpanded}>
        <tr
          onClick={() => setIsExpanded(!isExpanded)}
          className="clickable-line firstLine"
          data-testid={`line-accordion-${itemNumber}-${data.Orders?.map(x => x.OrderVolumeId).join('-')}`}
        >
          <td className="enumerator firstLine" data-testid={`enumerator-${itemNumber}-${data.Orders?.map(x => x.OrderVolumeId).join('-')}`}>
            {itemNumber}.
          </td>
          <td className="firstLine" data-testid={`origin-description-${itemNumber}-${data.Orders?.map(x => x.OrderVolumeId).join('-')}`}>
            <TextSpan className='limit-width'>
              {data.OriginDescription}
            </TextSpan>
          </td>
          <td className="firstLine" data-testid={`destination-description-${itemNumber}-${data.Orders?.map(x => x.OrderVolumeId).join('-')}`}>
            <TextSpan className='limit-width'>
              {data.DestinyDescription}
            </TextSpan>
          </td>
          <td className="firstLine" data-testid={`order-volume-id-${itemNumber}-${data.Orders?.map(x => x.OrderVolumeId).join('-')}`}>
            <TextSpan className='limit-width'>
              {data.Orders?.map(x => x.OrderVolumeId).join(', ')}
            </TextSpan>
          </td>
          <td className="firstLine" data-testid={`material-description-${itemNumber}-${data.Orders?.map(x => x.OrderVolumeId).join('-')}`}>
            <TextSpan className='limit-width'>
              {data.Orders?.map(x => x.MaterialDescription).join(', ')}
            </TextSpan>
          </td>
          <td className="firstLine" data-testid={`lot-size-${itemNumber}-${data.Orders?.map(x => x.OrderVolumeId).join('-')}`}>{formatNumber(data.LotSize)} {data.Orders[0]?.UnitOfMeasurementLot}</td>
          <td className="firstLine" data-testid={`quantity-unit-${itemNumber}-${data.Orders?.map(x => x.OrderVolumeId).join('-')}`}>{formatNumber(data.Quantity)} {data.Orders[0]?.QuantityUnitOfMeasurement}</td>
          <td className="firstLine" data-testid={`pickup-date-${itemNumber}-${data.Orders?.map(x => x.OrderVolumeId).join('-')}`}>{moment(data.PickupDate).format("L")}</td>
          <td className="firstLine" data-testid={`delivery-date-${itemNumber}-${data.Orders?.map(x => x.OrderVolumeId).join('-')}`}>{moment(data.DeliveryDate).format("L")}</td>
          <td className="firstLine" data-testid={`delivery-time-${itemNumber}-${data.Orders?.map(x => x.OrderVolumeId).join('-')}`}>{formatDeliveryTime(data.DeliveryTime)}</td>
          <td className="firstLine" data-testid={`door-${itemNumber}-${data.Orders?.map(x => x.OrderVolumeId).join('-')}`}>{data.Door}</td>
          <td className="firstLine" data-testid={`icon-expand-${itemNumber}-${data.Orders?.map(x => x.OrderVolumeId).join('-')}`}>
            <Icon
              isExpanded={isExpanded}
              onClick={() => setIsExpanded(!isExpanded)}
            >
              <ExpandMoreIcon />
            </Icon>
          </td>
          <td className="btnDelete" data-testid={`delete-load-composition-${itemNumber}-${data.Orders?.map(x => x.OrderVolumeId).join('-')}`} onClick={(ev) => ev.stopPropagation()}>
            <Button
              type="hidden"
              buttonIcon={<IconTrash color={'var(--color-feedback-danger-400)'} width={"20px"} height={"20px"} />}
              onClick={() => setOpenModal(true)}
            />
          </td>
        </tr>

        {
          isExpanded && data.Orders.map(x => (
            <tr data-testid={`secondary-line-accordion-${itemNumber}-${data.Orders?.map(x => x.OrderVolumeId).join('-')}`}>
              <td></td>
              <td data-testid={`sub-origin-description-${itemNumber}-${data.Orders?.map(x => x.OrderVolumeId).join('-')}`}></td>
              <td data-testid={`sub-destination-description-${itemNumber}-${data.Orders?.map(x => x.OrderVolumeId).join('-')}`}></td>
              <td data-testid={`sub-volume-id-${itemNumber}-${data.Orders?.map(x => x.OrderVolumeId).join('-')}`}>
                <TextSpan className='limit-width'>
                  {x.OrderVolumeId?.join(', ')}
                </TextSpan>
              </td>
              <td data-testid={`sub-material-description-${itemNumber}-${data.Orders?.map(x => x.OrderVolumeId).join('-')}`}>
                <TextSpan className='limit-width'>
                  {x.MaterialDescription}
                </TextSpan>
              </td>
              <td data-testid={`sub-lot-size-${itemNumber}-${data.Orders?.map(x => x.OrderVolumeId).join('-')}`}>
                <TextSpan className='limit-width'>
                  {formatNumber(x.LotSize)} {x.UnitOfMeasurementLot}
                </TextSpan>
              </td>
              <td data-testid={`sub-quantity-unit-${itemNumber}-${data.Orders?.map(x => x.OrderVolumeId).join('-')}`}>{formatNumber(x.Quantity)} {x.QuantityUnitOfMeasurement}</td>
              <td data-testid={`sub-pickup-date-${itemNumber}-${data.Orders?.map(x => x.OrderVolumeId).join('-')}`}></td>
              <td data-testid={`sub-delivery-date-${itemNumber}-${data.Orders?.map(x => x.OrderVolumeId).join('-')}`}></td>
              <td data-testid={`sub-delivery-time-${itemNumber}-${data.Orders?.map(x => x.OrderVolumeId).join('-')}`}></td>
              <td data-testid={`sub-door-${itemNumber}-${data.Orders?.map(x => x.OrderVolumeId).join('-')}`}></td>
              <td></td>
            </tr>
          ))
        }
        {isFetching &&
          < WrapperRequestsLoading >
            <Feature validation={getStatusLoad() == StatusRequest.Loading}>
              <Loading data-testid='loading' style={{ height: 'auto', width: 'auto', bottom: 'auto', top: '7px', left: "calc(50% - 36px)" }} />
            </Feature>
            <Feature validation={getStatusLoad() == StatusRequest.Success}>
              <img
                alt="Círculo Indicando o Processamento da operação"
                src={checkSuccess}
                data-testid='icn-success'
                style={{ height: '32px' }}
              />
            </Feature>
            <Feature validation={getStatusLoad() == StatusRequest.Error}>
              <img
                alt="Círculo Indicando o erro da operação"
                src={checkError}
                data-testid='icn-error'
              />
            </Feature>
            <Feature validation={getStatusLoad() == StatusRequest.NotStarted}>
              <img
                alt="Círculo Indicando operação bloqueada"
                src={checkBlocked}
                data-testid='icn-blocked'
              />
            </Feature>
          </WrapperRequestsLoading>
        }
      </AccordionContent >
      <BlankSpace />

      <ModalDeleteLoad
        open={openModal}
        close={() => setOpenModal(false)}
        onClick={handleDeleteLoad}
        showInfoReturnQuantity={listLabel === 'LoadNotFull'}
      />
    </>
  )
}