import React, { useEffect, useState } from 'react'
import intl from 'react-intl-universal'
import { isUseFilterDefault } from 'utils/user'
import { useBottlesContext } from '../../context'
import { getSuppliers } from '../../services/bottles-plan.service'
import * as S from './styled'
import { formatNameToFilter } from './utils'
import Session from "utils/user-storage";

export const BottlesInputRegisteredSupplierList = ({ handleEvent, searchTerm }) => {
  const {
    data,
    filter,
    setSuppliers,
    suppliers,
  } = useBottlesContext()

  const [isLoadingSuppliers, setIsLoadingSuppliers] = useState(false);

  const handleSupplierList = (supplier) => {
    const event = {
      simulado: false,
      key: 'Enter',
      target: { value: formatNameToFilter(supplier.Description) },
      idSupplier: supplier.Id
    }
    handleEvent(event)
  }

  const filteredData = () => {
    const selectedSuppliers = data.suppliers.map(x => x.Name.toUpperCase())

    let filteredData = suppliers
      .filter(supplier =>
        !selectedSuppliers.includes(formatNameToFilter(supplier.Description)))

    if (searchTerm !== '') {
      filteredData = filteredData
        .filter(x => x.Description.toUpperCase().includes(searchTerm.toUpperCase()))
    }

    return filteredData.slice(0, 4)
  }

  const loadSuppliersData = async () => {
    const params = {
      useDefaultFilter: isUseFilterDefault(),
      userId: Session.get().Id,
      labeledFamilyId: filter.labeledFamilyId[0]
    }
    setIsLoadingSuppliers(true)
    const response = await getSuppliers(params)
    setSuppliers(response)
    setIsLoadingSuppliers(false)
  }

  useEffect(() => {
    loadSuppliersData()
  }, [])

  if (isLoadingSuppliers) {
    return <div style={{ padding: '5px 25px' }}>{intl.get('commons.loading')}</div>
  }

  return (
    <S.SuppliersList>
      {filteredData().map((supplier) => (
        <S.SuppliersListItem
          key={supplier.Description}
          onClick={() => handleSupplierList(supplier)}>
          {supplier.Description}
        </S.SuppliersListItem>
      ))
      }
    </S.SuppliersList >
  )
}
