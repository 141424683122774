import { Route, Switch } from 'react-router-dom'
import TasksVision from '../pages/tasks-vision'
import OrdersVisionDetail from '../pages/orders-vision-detail'
import Stocks from '../pages/stocks'
import StocksDetail from '../pages/stocks-detail'
import MasterData from '../pages/master-data'
import Relatorios from '../pages/relatorios'
import ComposicaoCarga from '../pages/composicao-carga'
import OtimizacaoCarga from '../pages/optimization'
import ItensCriticos from '../pages/gerencial/itens-criticos'
import RiscosPassivos from '../pages/gerencial/riscos-passivos'
import GridVision from '../pages/grid-vision'
import EstoqueFornecedor from '../pages/estoque-fornecedor'
import AutoLoadOptimization from '../pages/auto-load-optimization'
import LoadComposition from '../pages/load-composition'
import LoadCompositionBuilding from '../pages/load-composition-building'
import PrimaryMaterialScheduleList from '../pages/primary-material-schedule'
import { LoadCompositionProvider } from '../pages/load-composition/context/loadCompositionContext'
import ErrorPage from 'pages/404'
import OrdersPage from 'pages/orders'
import BottlesPage from 'pages/bottles'
import Suggestions from 'pages/suggestions'
import OrderVolumePage from 'pages/orderVolume'
import ConsolidatedStocks from 'pages/consolidated-stocks'
import { LoadBuilding } from 'pages/load-building'
import { InvoicesOrder } from 'features/invoices-order'
import { NotificationCenter } from 'pages/notification-center'

const AppRoutes = () => {
  return (
    <Switch>
      <Route exact path="/" component={TasksVision} />
      <Route exact path="/error" component={ErrorPage} />
      <Route exact path="/orders" component={OrdersPage} />
      <Route exact path="/suggestions" component={Suggestions} />
      <Route exact path="/volume" component={OrderVolumePage} />
      <Route exact path="/bottles" component={BottlesPage} />
      <Route path={`/ordersVisionDetail/:orderId/:typeNeed`} component={OrdersVisionDetail} />
      <Route exact path={`/stocks`} component={Stocks} />
      <Route path={`/stocksDetail/:familyId/:itemId?/:typeNeed?`} component={StocksDetail} />
      <Route path={`/gridVision/:familyId?/:date?/:datetype?`} component={GridVision} />
      <Route path={`/relatorios`} component={Relatorios} />
      <Route path={`/master-data`} component={MasterData} />
      <Route exact path={`/gerencial`} component={RiscosPassivos} />
      <Route path={`/riscos-passivos`} component={RiscosPassivos} />
      <Route path={`/itens-criticos`} component={ItensCriticos} />
      <Route exact path={`/composicao`} component={ComposicaoCarga} />
      <Route path={`/composicao/:idPedido`} component={ComposicaoCarga} />
      <Route path={`/otimizacaoCarga`} component={OtimizacaoCarga} />
      <Route path={`/estoqueFornecedor`} component={EstoqueFornecedor} />
      <Route path={`/autoLoadOptimization`} component={AutoLoadOptimization} />
      <Route path={`/primaryMaterialSchedule`} component={PrimaryMaterialScheduleList} />
      <Route path={`/consolidated-stocks`} component={ConsolidatedStocks} />
      <Route exact path={`/load-building`} component={LoadBuilding} />
      <Route exact path={`/orders/:orderSapCode/invoices`} component={InvoicesOrder} />
      <Route exact path={'/notification-center'} component={NotificationCenter} />
      <LoadCompositionProvider>
        <Route exact path={`/loadComposition`} component={LoadComposition} />
        <Route path={`/loadCompositionBuilding/destino/:destinoId/:destinoName/date/:dia/:hora/:composicaoId?/:fornecedorId?/:idxSelected?`}
          component={LoadCompositionBuilding}
        />
      </LoadCompositionProvider>
    </Switch>
  )
}

export default AppRoutes
