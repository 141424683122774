import { Wrapper } from "./styled"
import { TextSpan } from "components/text-label";
import { getLoadCompositionInfosCard } from "./utils";

export const Tag = ({ status, ...rest }) => {
  const loadCompositionInfosCard = getLoadCompositionInfosCard(status);
  return <Wrapper
    {...rest}
    color={loadCompositionInfosCard.color}
    background={loadCompositionInfosCard.background}
  >
    <TextSpan
      color={loadCompositionInfosCard.color}
      display='block'
      style={{ margin: '1px 5px' }}
    >
      {loadCompositionInfosCard.title}
    </TextSpan>
  </Wrapper>
}