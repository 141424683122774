import React from 'react'
import * as S from '../styled'
import intl from 'react-intl-universal'

const CardUnavailable = ({ hour, view }) => (
  <>
    {view &&
      <S.Wrapper
        background='var(--color-contrast-white)'
        color='#D1D4D7'
        style={{ display: 'flex' }}
      >
        <S.Hora>
          {hour && <span> {hour}</span>}
        </S.Hora>
        <S.Content color='#D1D4D7'>
          <span>{intl.get("loadComposition.filter.unavailable")}</span>
        </S.Content>
      </S.Wrapper>
    }
  </>
)

export default CardUnavailable;
