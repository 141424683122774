import React, { useMemo, useState } from 'react'
import { getFormattedHours } from '../../../master-data/unit-management/delivery-schedule-configuration/components/utils';
import { useLoadCompositionContext } from '../../context/loadCompositionContext';
import { getDaysByRange, timeRange } from '../../utils';
import Card from '../card';
import CardUnavailable from '../card/cardUnavailable';
import intl from 'react-intl-universal'
import moment from 'moment'
import * as S from './styled'
import { useQuery } from 'hooks/useQuery';
import { formatWithoutSpaces } from 'utils/format';
import ModalLoadComposition from 'components/modal-load-composition';

const Table = ({ searchLoadComposition, isEditMode, params, redirectRoute, replaceRoute }) => {
  const { startDate, endDate, data, filters } = useLoadCompositionContext();
  const query = useQuery()
  const [hours] = useState(getFormattedHours(timeRange))
  const [dates] = useState(getDaysByRange(startDate, endDate));
  const idCompositionSelected = useMemo(() => query.get("code") || params?.composicaoId, []);
  const [loadCompositionForm, setLoadCompositionForm] = useState({
    isOpen: false,
    idComposicao: 0,
    modoLeitura: true
  });

  const [selectedFreeSlot, setSelectedFreeSlot] = useState();

  const validateDaysInData = (date) =>
    data.filter(_data => moment(_data.Day).format("YYYY-MM-DD") === date).length > 0;

  const validateHoursInData = (_data, hour) =>
    _data.filter(x => moment(x.Hour, "HH:mm:ss").format("HH:mm") === hour).length > 0;

  const handleRefreshTable = () => {
    const _dates = {
      de: filters.dateFrom,
      ate: filters.dateTo
    }
    searchLoadComposition(_dates, filters.center, filters.door)
  }

  const handleCloseLoadForm = () => {
    setLoadCompositionForm(prev => ({
      ...prev,
      isOpen: false
    }))
    handleRefreshTable()
  }

  const handleOpenLoadForm = (load) => {
    updateCacheFilters()
    setLoadCompositionForm({ isOpen: true, idComposicao: load.Id, modoLeitura: load.ReadingMode })
  }

  const updateCacheFilters = () => {
    const filter = {
      Center: filters.center,
      dataInicial: startDate,
      dataFinal: endDate,
      Door: filters.door,
    }

    localStorage.setItem("loadCompositionFilter", JSON.stringify(filter))
  }

  const handleClickFree = (hour, date) => {
    updateCacheFilters();
    const queryParams = !!filters.door?.value ? `?doorDescription=${filters.door?.label}` : ''
    const filtersRoute = `/loadCompositionbuilding/destino/${filters.center.IdUnidadeNegocio}/${formatWithoutSpaces(filters.center.Descricao)}/date/${date}/${hour}${queryParams}`
    redirectRoute(filtersRoute)
  }

  const handleSelectEditDate = (dia, hour, i) => {
    const filtersRoute = `${filters.center.IdUnidadeNegocio}/${formatWithoutSpaces(filters.center.Descricao)}/date/${dia}/${hour}`
    const queryParams = !!filters.door?.value ? `?doorDescription=${filters.door?.label}` : ''
    const editCompositionInfosRoute = !!params?.composicaoId ? `/${params.composicaoId}/${params.fornecedorId}` : '';
    if (isEditMode) {
      setSelectedFreeSlot(`${dia}-${hour}-${i}`)
    }
    replaceRoute(`/loadCompositionbuilding/destino/${filtersRoute}${editCompositionInfosRoute}${queryParams}`)
  }

  return (
    <S.Wrapper
      vertical={false}
    >
      <S.WrapperContent>
        {
          dates.map(date => (
            validateDaysInData(date) ?
              data.filter(_data => moment(_data.Day).format("YYYY-MM-DD") === date).map(_data => (
                <S.WrapperColumn>
                  <S.TableHeading>
                    {intl.get(`weekdays.${moment(date).weekday()}`)}
                    <br />
                    {moment(date).format("L")}
                  </S.TableHeading>
                  <S.Row>
                    {
                      hours.map(hour => (
                        <React.Fragment>
                          {validateHoursInData(_data.Data, hour) ?
                            _data.Data.filter(x => moment(x.Hour, "HH:mm:ss").format("HH:mm") === hour).map((x) => (
                              <Card
                                isEditMode={isEditMode}
                                data={x}
                                hour={hour}
                                dia={date}
                                viewScheduledDelivery={filters.viewColumns.includes("ScheduledDelivery")}
                                viewFreeSlot={filters.viewColumns.includes("FreeSlot")}
                                viewPendingReview={filters.viewColumns.includes("PendingReview")}
                                viewInNegotiation={filters.viewColumns.includes("InNegotiation")}
                                viewEmergencyNegotiation={filters.viewColumns.includes("EmergencyNegotiation")}
                                handleClickFree={() => handleClickFree(hour, date)}
                                handleOpenLoadForm={handleOpenLoadForm}
                                handleClickInNegotiation={handleOpenLoadForm}
                                handleSelectEditDate={(comp, i) => handleSelectEditDate(date, hour, i)}
                                idCompositionSelected={idCompositionSelected ? parseInt(idCompositionSelected) : null}
                                selectedFreeSlot={selectedFreeSlot}
                              />
                            ))
                            :
                            <CardUnavailable view={filters.viewColumns.includes("Unavailable")} hour={hour} />
                          }
                        </React.Fragment>
                      ))
                    }
                  </S.Row>
                </S.WrapperColumn>
              )
              ) :
              <S.WrapperColumn>
                <S.TableHeading>
                  {intl.get(`weekdays.${moment(date).weekday()}`)}
                  <br />
                  {moment(date).format("L")}
                </S.TableHeading>
                {hours.map(hour =>
                  <CardUnavailable view={filters.viewColumns.includes("Unavailable")} hour={hour} />
                )}
              </S.WrapperColumn>
          ))
        }
      </S.WrapperContent>
      {loadCompositionForm.isOpen &&
        <ModalLoadComposition
          open={loadCompositionForm.isOpen}
          compositionId={loadCompositionForm.idComposicao}
          redirectRoute={redirectRoute}
          handleRefresh={handleRefreshTable}
          readingMode={loadCompositionForm.modoLeitura}
          close={handleCloseLoadForm}
        />
      }
    </S.Wrapper>
  )
}

export default Table;
