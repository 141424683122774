import React from 'react'
import * as S from '../styled'
import moment from 'moment'
import { Tag } from '../tag'
import PurchaseOrderList from '../purchaseOrdersList'

const CardFiled = ({ data, handleClick, idCompositionSelected }) => (
  <>
    {data.map(composicao => (
      <S.Wrapper
        background='var(--color-contrast-white)'
        color='var(--color-action-default)'
        isSelected={composicao.Id === idCompositionSelected}>
        <S.Hora>
          {composicao && <span> {moment(composicao.Hour, "HH:mm:ss").format("HH:mm")}</span>}
        </S.Hora>
        <Tag
          onClick={handleClick ? () => handleClick(composicao) : undefined}
          status={composicao.Situation}
          data-testid={`delivery-scheduled-slot-${composicao.Id}`}
        />
        <S.Content color='var(--color-action-default)'>
          <PurchaseOrderList loadComposition={composicao} />
        </S.Content>
      </S.Wrapper>
    ))}
  </>
)

export default CardFiled;
