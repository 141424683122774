import React, { Component } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import './date-range.css';
import DateInput from '../date-input';
import moment from 'moment';
import intl from 'react-intl-universal';

class DateRange extends Component {
  constructor(props) {
    super(props);

    this.state = {
      periodoInvalido: false,
      dataDe: '',
      dataAte: ''
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {

    if (this.state.dataDe !== nextProps.dataDe) {
      this.setState({
        dataDe: nextProps.dataDe,
      })
    }

    if (this.state.dataAte !== nextProps.dataAte) {
      this.setState({
        dataAte: nextProps.dataAte,
      })
    }
    
    this.verificarPeriodoInvalido()
  }

  handleDataDeChange = (date) => {
    this.setState({
      dataDe: date,
    }, () => this.verificarPeriodoInvalido())
    this.props.onChangeDataDe(date)
  }

  handleDataAteChange = (date) => {
    this.setState({
      dataAte: date,
    }, () => this.verificarPeriodoInvalido())
    this.props.onChangeDataAte(date)
  }

  verificarPeriodoInvalido = () => {
    const { dataDe, dataAte } = this.state
    const periodoInvalido = (dataDe && dataAte && dataDe > dataAte)
    this.setState({
      periodoInvalido,
    }, () => this.props.onRangeIsValid && this.props.onRangeIsValid(periodoInvalido))
  }

  validateMinDate = () => this.props.noMinDate ? null : moment().format("YYYY-MM-DD");


  render() {
    const { dataDe, dataAte, labelDe, labelAte } = this.props
    const { periodoInvalido } = this.state

    return (
      <Row>
        <Col xs={6} lg={6} md={6}>
          <DateInput
            label={`${labelDe ? labelDe : intl.get('commons.dateFrom')}`}
            value={dataDe}
            onChange={this.handleDataDeChange}
            minDate={this.validateMinDate()}
            canClear
            data-testid='dateFrom'
          />
        </Col>
        <Col xs={6} lg={6} md={6}>
          <DateInput
            label={`${labelAte ? labelAte : intl.get('commons.dateTo')}`}
            value={dataAte}
            onChange={this.handleDataAteChange}
            minDate={this.validateMinDate()}
            canClear
            lastInput
            data-testid='dateTo'
          />
        </Col>
        {periodoInvalido && <div className="range-data-mensagem">
          <span>{intl.get('commons.invalidPeriod')}</span>
        </div>}
      </Row>
    )
  }
}

export default DateRange;
