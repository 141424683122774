import {
  ClbButtonIcon,
  ClbCheckbox,
  ClbTableTBodyCell,
  ClbTableTBodyRow
} from 'libs/celebration'
import moment from 'moment'
import intl from 'react-intl-universal'
import { useLoadBuildingContext } from 'features/load-building/context'
import { formatNumber, formatWithoutLeadingZeros } from 'utils/format'
import { useState } from 'react'
import { updateVolumeOrder } from 'features/load-building/load-building.service'
import InputNumber from 'components/input-number'
import DateInput from 'components/date-input'
import LoadingProgress from 'components/circular-progress'
import { formatHourTimeOrDefault } from 'utils/delivery-time'
import { Input } from 'components/uikit-adapter'
import { useToastContext } from 'hooks/useToastContext'
import { formatErrorMessage } from 'utils/handle-error'
import { ISO_DATE_FORMAT } from 'utils/format-date'
import { parseFloatByLocale } from 'locales/utils'
import { StatusVolume } from 'models/volumes/status'
import { ModalConfirmAction } from 'components/modal-confirm-action'
import Feature from 'components/feature'

export const BodyTableLine = ({ data }) => {
  const [item, setItem] = useState(data);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [modalConfirmDelete, setModalConfirmDelete] = useState({
    isOpen: false,
    title: intl.get('load-building.page-flow.modal-delete-volume.title'),
    textMessage: intl.get('load-building.page-flow.modal-delete-volume.message')
  });
  const { volumesSelected, setVolumesSelected, setFilter } = useLoadBuildingContext();
  const { openToast } = useToastContext();

  const [newValues, setNewValues] = useState({
    PickupDate: item.CollectDate,
    DeliveryDate: item.DeliveryDate,
    DeliveryTime: item.DeliveryTime,
    PendingVolume: item.PendingVolume,
    Status: null
  });

  const handleCheckboxChange = (value) => {
    setVolumesSelected(prevState => {
      if (prevState.includes(value)) {
        return prevState.filter(x => x !== value);
      } else {
        return [...prevState, value]
      }
    })
  }

  const handleUpdateModalConfirmDelete = (isOpen) => {
    setModalConfirmDelete(prevState => ({
      ...prevState,
      isOpen: isOpen
    }))
  }

  const handleClickDelete = async () => {
    handleUpdateModalConfirmDelete(false);
    try {
      setIsLoading(true);
      const _bodySaveChanges = {
        ...newValues,
        Status: StatusVolume.VolumeFinalizado,
      }
      await updateVolumeOrder(item.Id, _bodySaveChanges);
      openToast(intl.get('feedbacks.deleteRegister'));
      setFilter(prevState => ({ ...prevState }))
    } catch (e) {
      openToast(formatErrorMessage(e), 'negative')
    }
    setIsLoading(false);
  }

  const handleClickEdit = () => {
    if (!isEditing) {
      setNewValues({
        PickupDate: item.CollectDate,
        DeliveryDate: item.DeliveryDate,
        DeliveryTime: item.DeliveryTime,
        PendingVolume: item.PendingVolume
      });
      setIsEditing(true);
      return;
    }
    handleSaveChanges();
  }

  const handleSaveChanges = async () => {
    if (newValues.PendingVolume <= 0) {
      handleUpdateModalConfirmDelete(true);
      return;
    }

    try {
      setIsLoading(true);
      const _bodySaveChanges = {
        ...newValues,
        PendingVolume: parseFloatByLocale(newValues.PendingVolume)
      }
      const _response = await updateVolumeOrder(item.Id, _bodySaveChanges);
      setItem(_response);
      openToast(intl.get("feedbacks.savedRegister"));
      setIsEditing(false);
    } catch (e) {
      openToast(formatErrorMessage(e), 'negative')
    }
    setIsLoading(false);
  }

  const handleChangeValue = (field, value) => {
    setNewValues(prevState => ({
      ...prevState,
      [field]: value
    }))
  }

  return (
    <>
      <ClbTableTBodyRow>
        <ClbTableTBodyCell>
          <ClbCheckbox
            id={`checkbox-${item.Id}`}
            data-testid={`checkbox-${item.Id}`}
            checked={volumesSelected.includes(item.Id)}
            onClbChange={() => handleCheckboxChange(item.Id)}
          />
        </ClbTableTBodyCell>
        <ClbTableTBodyCell data-testid={`type-${item.Id}`}>
          {intl.get('commons.orderVolume')}
        </ClbTableTBodyCell>
        <ClbTableTBodyCell data-testid={`volume-code-${item.Id}`}>
          {item.Id}
        </ClbTableTBodyCell>
        <ClbTableTBodyCell data-testid={`supplier-${item.Id}`}>
          {item.SupplierDescription}
        </ClbTableTBodyCell>
        <ClbTableTBodyCell data-testid={`destination-${item.Id}`}>
          {item.BusinessUnitDescription}
        </ClbTableTBodyCell>
        <ClbTableTBodyCell data-testid={`input-pickup-date-${item.Id}`} style={{ width: '100px' }}>
          {isEditing ? <DateInput
            disabled={isLoading}
            value={newValues.PickupDate}
            onChange={value => handleChangeValue('PickupDate', value)}
          /> : moment(item.CollectDate).format("L")}
        </ClbTableTBodyCell>
        <ClbTableTBodyCell data-testid={`input-delivery-date-${item.Id}`} style={{ width: '100px' }}>
          {isEditing ? <DateInput
            disabled={isLoading}
            value={newValues.DeliveryDate}
            onChange={value => handleChangeValue('DeliveryDate', value)}
            minDate={moment().format(ISO_DATE_FORMAT)}
          /> : moment(item.DeliveryDate).format("L")}
        </ClbTableTBodyCell>
        <ClbTableTBodyCell data-testid={`input-delivery-time-${item.Id}`} style={{ width: '100px' }}>
          {isEditing ? <Input
            className='input'
            htmlType='time'
            disabled={isLoading}
            value={newValues.DeliveryTime}
            onChange={e => handleChangeValue('DeliveryTime', e.target.value)}
          /> : formatHourTimeOrDefault(item.DeliveryTime)}
        </ClbTableTBodyCell>
        <ClbTableTBodyCell data-testid={`material-${item.Id}`}>
          {`${formatWithoutLeadingZeros(item.MaterialNumber)} - ${item.MaterialDescription}`}
        </ClbTableTBodyCell>
        <ClbTableTBodyCell data-testid={`input-qty-volume-order-${item.Id}`} style={{ width: '100px' }}>
          {isEditing ? <InputNumber
            type='default'
            value={newValues.PendingVolume}
            onChange={value => handleChangeValue('PendingVolume', value)}
            disabled={isLoading}
          /> : formatNumber(item.PendingVolume)}
        </ClbTableTBodyCell>
        <ClbTableTBodyCell>
          <div style={{ textWrap: 'nowrap' }}>
            {isLoading ? <LoadingProgress
              style={{ position: 'relative', height: 0, transform: 'scale(0.5) translateX(-10px)' }}
            /> :
              <ClbButtonIcon
                onClbClick={handleClickEdit}
                icon={isEditing ? 'Save' : 'Edit'}
                size="sm"
                data-testid={isEditing ? `btn-icon-save-${item.Id}` : `btn-icon-edit-${item.Id}`}
              />
            }
            <Feature validation={!isLoading}>
              {isEditing ?
                <ClbButtonIcon
                  onClbClick={() => setIsEditing(false)}
                  icon='Cancel'
                  size="sm"
                  data-testid={`btn-icon-cancel-${item.Id}`}
                /> :
                <ClbButtonIcon
                  onClbClick={() => handleUpdateModalConfirmDelete(true)}
                  icon='Delete'
                  size="sm"
                  data-testid={`btn-icon-delete-${item.Id}`}
                />
              }
            </Feature>
          </div>
        </ClbTableTBodyCell>
      </ClbTableTBodyRow >
      <ModalConfirmAction
        isOpen={modalConfirmDelete.isOpen}
        handleConfirm={handleClickDelete}
        handleClose={() => handleUpdateModalConfirmDelete(false)}
        textMessage={modalConfirmDelete.textMessage}
        title={modalConfirmDelete.title}
      />
    </>
  )
}