import Header from "components/header";
import intl from 'react-intl-universal'
import { AutoPilotFullPageFlow } from "../auto-pilot-full-page-flow";

export const AutoPilot = () => {
  return (
    <>
      <Header
        title={intl.get('commons.automaticRoutine')}
        titleWidth={460}
        noBackground
      />
      {/* 
      //@ts-ignore */}
      <AutoPilotFullPageFlow />
    </>
  )
}
