import React from 'react'
import { Button } from 'components/uikit-adapter/index'
import { RefuseCheckCancel } from '../em-cancelamento.service'
import { useGlobalContext } from 'hooks/useGlobalContext';
import intl from 'react-intl-universal'
import { formatErrorMessage } from 'utils/handle-error';

const WaitingCheckRefusedButton = ({ stopLoading, startLoading, refresh, idPedido }) => {
  const { showFeedback } = useGlobalContext();

  const handleCompletCheckRefused = async () => {
    try {
      startLoading()
      await RefuseCheckCancel(idPedido);
      refresh()
      showFeedback(intl.get('stocks.timeline.feedback.scienceSuccessfullyRegistered'))
    } catch (e) {
      showFeedback(formatErrorMessage(e))
    }
    stopLoading()
  }

  return (
    <Button
      type="primary"
      value="OK"
      onClick={handleCompletCheckRefused}
      className='btn-check-cancellation'
    />
  )
}

export default WaitingCheckRefusedButton
