import { StyledButtonDS } from './styledButton'

const Button = ({ buttonIcon, buttonText, ...rest }) => {
  return (
    <StyledButtonDS
      value={<span className='button-with-icon'> {buttonIcon} {buttonText && <span> {buttonText} </span>} </span>}
      {...rest}  
    />
  )
}

export { Button };
