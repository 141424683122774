import React from 'react'
import intl from 'react-intl-universal'
import { Row, Col } from 'react-flexbox-grid'
import { Input } from 'components/uikit-adapter/index'
import { onChange } from './change'
import * as S from './styled'

const IMOManagementList = ({ data, onChangeField }) => {

  const change = (value, i, type) => {
    const {valor, tipo, posicao} = onChange(value, i, type)
    onChangeField(valor, posicao, tipo)
  }
  const handleChangeImo = e => {
    const valueOnlyNumber = e.currentTarget.value.replace(/\D/g, "");
    e.currentTarget.value = valueOnlyNumber;
  }

  return (
    <S.WrapperList>
      <Row className="rowListHeader">
        <Col xs={8} className="align-left" style={{ paddingLeft: '20px' }}>{intl.get('master-data.configurations.imo-management.table.headers.description')}</Col>
        <Col xs={2} className="align-center">{intl.get('master-data.configurations.imo-management.table.headers.imoCode')}</Col>
        <Col xs={2} className="align-center">{intl.get('master-data.configurations.imo-management.table.headers.pepCode')}</Col>
      </Row>
      <Row className="containerList">
        <Col xs={12} className="pt-0 pb-0">
          {
            data.map((value, i) =>
              <Row
                key={i}
                className="rowList"
              >
                <Col xs={8} className="align-left">{value.DescricaoCompletaMaterial}</Col>
                <Col xs={2} className="align-center">
                  <Input
                    onChange={handleChangeImo}
                    maxlength="10"
                    style={{ width: '80%' }}
                    data-testid={`input-imo-code-${i}`}
                    defaultValue={value.CodigoImobilizado}
                    onBlur={(e) => change(e.target.value, i, "CodigoImobilizado")}
                    className="input-code input"
                    type={value.type}
                    
                  />
                </Col>
                <Col xs={2} className="align-center">
                  <Input
                    style={{ width: '80%' }}
                    data-testid={`input-pep-code-${i}`}
                    defaultValue={value.Pep}
                    onBlur={(e) => change(e.target.value, i, "Pep")}
                    className="input-code input"
                  />
                </Col>
              </Row>
            )
          }
        </Col>
      </Row>
    </S.WrapperList>
  )
}

export default IMOManagementList
