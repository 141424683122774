import React, { useState } from 'react'
import { withRouter } from 'react-router'
import Header from './components/header';
import Filter from './components/filter'
import * as S from './styled'
import Table from './components/table'
import { useLoadCompositionContext } from './context/loadCompositionContext';
import { formatErrorMessage } from '../../utils/handle-error';
import { useGlobalContext } from 'hooks/useGlobalContext';
import { getData } from './load-composition.service';
import Loading from 'components/center-loading'
import moment from 'moment';

const LoadComposition = ({ history, isEditMode, params, center }) => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    data,
    setData,
    setFilters,
    setEndDate,
    setStartDate
  } = useLoadCompositionContext();

  const { showFeedback } = useGlobalContext();

  const searchLoadComposition = async (dates, centerSelected, doorSelected) => {
    setIsLoading(true);
    try {
      setData([])
      const filter = {
        dateFrom: dates.de,
        dateTo: dates.ate,
        center: centerSelected,
        door: doorSelected
      }
      setFilters(prevState => (
        {
          ...prevState,
          ...filter
        }
      ))
      setStartDate(filter.dateFrom)
      setEndDate(filter.dateTo)

      const dtoRequest = {
        IdBusinessUnit: filter.center.IdUnidadeNegocio,
        DateFrom: moment(filter.dateFrom).format("YYYY-MM-DD"),
        DateTo: moment(filter.dateTo).format("YYYY-MM-DD"),
        DoorDescription: !filter.door?.value ? '' : filter.door?.label
      }

      const _data = await getData(dtoRequest);
      setData([..._data]);
    }
    catch (e) {
      proccessErro(e)
    }
    setIsLoading(false);
  }

  const proccessErro = (e) => {
    showFeedback(formatErrorMessage(e))
  }

  const redirectRoute = (_route) => {
    history.push(_route)
  }

  const replaceRoute = (_route) => {
    history.replace(_route)
  }

  return (
    <React.Fragment>
      {!isEditMode && <Header />}
      <S.Wrapper>
        <Loading isLoading={isLoading} />
        <Filter
          searchLoadComposition={searchLoadComposition}
          proccessErro={proccessErro}
          isEditMode={isEditMode}
          center={center}
          params={params}
          replaceRoute={replaceRoute}
        />
        {data.length > 0 &&
          <Table
            replaceRoute={replaceRoute}
            searchLoadComposition={searchLoadComposition}
            isEditMode={isEditMode}
            params={params}
            redirectRoute={redirectRoute}
          />}
      </S.Wrapper>
    </React.Fragment>
  )
}

export default withRouter(LoadComposition)
