import { Component } from "react";
import PropTypes from "prop-types";
import { Happy, Surprised, Sad, UnRated } from "./emoticons";
import { CONST_PIE_SLICE, CONST_PIE_LABEL } from "./constants";
import {
  checkNps,
  saveNpsVote,
  getCategories,
  getFeedbacks,
  getQuestionPeriod,
  checkFeedback
} from "../services/nps.service";
import css from "classnames";
import moment from "moment";
import { ImageError, ImageSuccess } from "./images";
import * as S from "./styled";
import { Dislike, Like } from "../icons/like";
import { npsInternationalizationMapper } from "../utils";
import intl from 'react-intl-universal'

export const CONSOLE_ERRORS = {
  USER_SYSTEMID_MISSING: "NPS: por favor! forneça `user` e `systemId`"
};

let ACCEPTLANGUAGE = "";

const Slice = ({ value, onClickSlice, score }) => (
  <S.StyledSliceContainer
    className="slice"
    data-testid={`handler-value-${value}`}
    onClick={onClickSlice.bind(null, value)}
    style={{
      transform: `rotate(${value * CONST_PIE_SLICE}deg)`
    }}
  >
    <div
      className={css(
        {
          ok: value >= 9,
          normal: value >= 7 && value < 9,
          nok: value < 7,
          unrated: score === null || (score !== null && score < value)
        },
        "slice-inner"
      )}
    >
      <span
        className="slice-label"
        style={{
          transform: `rotate(-${CONST_PIE_LABEL + value * CONST_PIE_SLICE}deg)`
        }}
      >
        {value}
      </span>
    </div>
  </S.StyledSliceContainer>
);

const PieContainer = ({ children, emoticon }) => (
  <div className="pie-container">
    {children}
    <div className="inner-circle">{emoticon}</div>
  </div>
);

class Nps extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      surveySaved: false,
      surveyError: false,
      errorMsg: "",
      message: "",
      surveyScore: null,
      surveyComment: null,
      categories: [],
      categoryId: null,
      feedbacks: [],
      currentFeedback: {},
      renderSurvey: false,
      renderFeedback: false
    };
  }

  componentDidMount() {
    const { checkOnLoad, acceptLanguage } = this.props;
    ACCEPTLANGUAGE = acceptLanguage;
    if (checkOnLoad) {
      this.verifyIfMustBeVisible();
    }
  }

  verifyIfMustBeVisible = async () => {
    const { user, systemId } = this.props;

    if (!user || !systemId) {
      console.error(CONSOLE_ERRORS.USER_SYSTEMID_MISSING);
      return;
    }

    try {
      const responseMessage = await checkNps(user, systemId, ACCEPTLANGUAGE);
      const _message = responseMessage.replace(/[*]/gi, "");

      const periods = await getQuestionPeriod(ACCEPTLANGUAGE);

      const sort = periods.sort((item1, item2) => new Date(item2.finalDate) - new Date(item1.finalDate));
      const currentPeriod = sort.find((item) => item.active);

      let feedbacks = {};

      if (!!currentPeriod) {
        feedbacks = await getFeedbacks(user, systemId, currentPeriod.id, ACCEPTLANGUAGE)
      }

      this.setState({
        message: _message,
        visible: _message.trim().length > 0 || feedbacks.length > 0,
        renderSurvey: _message.trim().length > 0,
        renderFeedback: feedbacks.length > 0,
        feedbacks: feedbacks,
        currentFeedback: { index: 0, ...feedbacks[0] }
      });
    } catch (error) {
      console.error(error);
    }
  };

  selectScore = async score => {
    const { categories } = this.state;
    const { onClickItem } = this.props;
    this.updateState("surveyScore", score);

    if (score < 9 && !categories.length) {
      await this.getCategories();
    }

    document.getElementById("surveyComment").focus();
    onClickItem && onClickItem(score);
  };

  getCategories = async () => {
    try {
      const response = await getCategories(ACCEPTLANGUAGE);
      this.setState({ categories: response })
    } catch (error) {
      this.setState({
        surveyError: true,
        errorMsg: "Ocorreu um erro."
      });
    }
  }

  checkFeedback = async value => {
    const { currentFeedback } = this.state;

    const wasSeen = true;

    try {
      await checkFeedback(currentFeedback.id, wasSeen, value, ACCEPTLANGUAGE)
      this.nextFeedback();
    } catch (error) {
      this.setState({
        surveyError: true,
        errorMsg: "Não foi possivel salvar a resposta."
      });
    }
  }

  nextFeedback = () => {
    const { currentFeedback, feedbacks, renderSurvey } = this.state;
    const nextIndex = currentFeedback.index + 1;

    if (nextIndex < feedbacks.length) {
      this.setState({ currentFeedback: { index: nextIndex, ...feedbacks[nextIndex] } });
    } else {
      this.setState({ surveySaved: !renderSurvey, renderFeedback: false });
    }
  }

  updateState = (prop, value) => {
    this.setState({
      [prop]: value
    });
  };

  confirmSurvey = async () => {
    const { systemId, user, customParams } = this.props;
    const { surveyScore, surveyComment, categoryId } = this.state;

    if (surveyScore !== null) {
      const data = {
        ...customParams,
        createdDate: moment().format("YYYY-MM-DD[T]hh:mm:ss"),
        systemId,
        user,
        categoryId,
        score: surveyScore,
        comments: surveyComment,
        survey: 0
      };
      const result = await saveNpsVote(data, ACCEPTLANGUAGE);
      if (result.status === 200) {
        this.setState({ surveySaved: result.ok });
      } else {
        this.setState({
          surveyError: true,
          errorMsg: "Não foi possivel salvar o voto."
        });
      }
    }
  };

  renderEmoticon = () => {
    const { surveyScore } = this.state;

    if (surveyScore == null) {
      return <UnRated />;
    }

    if (surveyScore >= 9) {
      return <Happy />;
    }
    if (surveyScore >= 7) {
      return <Surprised />;
    }

    return <Sad />;
  };

  renderSurvey() {

    const { texts } = this.props;

    const { surveyScore, categoryId } = this.state;

    return (
      <div className="survey-question content">
        <div className="survey-header">
          <h2>{texts.survey.title}</h2>
          <h3>{texts.survey.message}</h3>
          <span>{texts.survey.complement}</span>
        </div>
        <div className="survey-body">
          <div className="survey-score">
            <PieContainer emoticon={this.renderEmoticon()}>
              <Slice
                value={0}
                onClickSlice={this.selectScore}
                score={surveyScore}
              />
              <Slice
                value={1}
                onClickSlice={this.selectScore}
                score={surveyScore}
              />
              <Slice
                value={2}
                onClickSlice={this.selectScore}
                score={surveyScore}
              />
              <Slice
                value={3}
                onClickSlice={this.selectScore}
                score={surveyScore}
              />
              <Slice
                value={4}
                onClickSlice={this.selectScore}
                score={surveyScore}
              />
              <Slice
                value={5}
                onClickSlice={this.selectScore}
                score={surveyScore}
              />
              <Slice
                value={6}
                onClickSlice={this.selectScore}
                score={surveyScore}
              />
              <Slice
                value={7}
                onClickSlice={this.selectScore}
                score={surveyScore}
              />
              <Slice
                value={8}
                onClickSlice={this.selectScore}
                score={surveyScore}
              />
              <Slice
                value={9}
                onClickSlice={this.selectScore}
                score={surveyScore}
              />
              <Slice
                value={10}
                onClickSlice={this.selectScore}
                score={surveyScore}
              />
            </PieContainer>
          </div>
          {surveyScore != undefined && surveyScore < 9 && (this.renderCategories())}
          <textarea
            className="survey-comment"
            data-testid="el-comment"
            onChange={event =>
              this.updateState("surveyComment", event.target.value)
            }
            id="surveyComment"
            placeholder={intl.get("survey.comment")}
            maxLength="300"
            rows="3"
          />
        </div>
        <div className="survey-footer">
          <button
            className="survey-action close"
            type="button"
            data-testid="btn-onclose"
            onClick={this.rememberLater.bind(null, false)}
          >
            {texts.survey.rememberLater}
          </button>
          <button
            type="button"
            className="survey-action confirm"
            data-testid="btn-confirm"
            onClick={this.confirmSurvey}
            disabled={surveyScore == undefined || (!categoryId && surveyScore < 9)}
          >
            {texts.survey.confirm}
          </button>
        </div>
      </div>
    );
  }

  renderCategories() {

    const { categories, categoryId } = this.state;

    return (
      <div className="categories-container">
        {categories.map((category, index) =>
          <button
            onClick={() => this.updateState("categoryId", category.id)}
            key={index}
            className="categories-button"
            disabled={categoryId === category.id}
          >
            {npsInternationalizationMapper()[category.name]}
          </button>
        )}
      </div>
    )
  }

  renderSuccessIllustration() {

    const { texts } = this.props;

    return (
      <div className="survey-confirmation content">
        <div className="survey-header">
          <h2>{texts.success.message}</h2>
        </div>
        <div className="survey-body">
          <ImageSuccess className="survey-img-response" />
        </div>
        <div className="survey-footer">
          <button
            className="survey-action confirm"
            type="button"
            data-testid="btn-finish"
            onClick={this.finishOk}
          >
            {texts.success.finalize}
          </button>
        </div>
      </div>
    );
  }

  renderError() {

    const { texts } = this.props;

    const { errorMsg } = this.state;

    return (
      <div className="survey-confirmation content">
        <div className="survey-header">
          <h2>{texts.error.title}</h2>
          <h3>{texts.error.message}</h3>
          <span>{errorMsg}</span>
          <span>{texts.error.solution}</span>
        </div>
        <div className="survey-body">
          <ImageError className="survey-img-response" />
        </div>
        <div className="survey-footer">
          <button
            className="survey-action close"
            type="button"
            data-testid="btn-onclose"
            onClick={this.closeSurvey.bind(null, true)}
          >
            {texts.error.finalize}
          </button>
        </div>
      </div>
    );
  }

  renderFeedback() {

    const { texts } = this.props;
    const { currentFeedback } = this.state;

    return (
      <div className="feedback-container">
        <div className="feedback-header">
          <h3>
            {texts.feedback.title}
          </h3>
        </div>
        <div className="feedback-content">
          <h3 className="feedback-content text">
            {texts.feedback.contentMessage}
          </h3>
          <textarea
            className="feedback-comment"
            disabled
            rows={25}
            value={currentFeedback.feedback}
          />
        </div>
        <div className="feedback-footer-title">
          {texts.feedback.question}
        </div>
        <div className="feedback-footer">
          <button
            type="button"
            className="feedback-action like"
            onClick={this.checkFeedback.bind(null, true)}
          >
            <Like width="50px" heigth="50px" />
          </button>
          <button
            className="feedback-action dislike"
            type="button"
            onClick={this.checkFeedback.bind(null, false)}
          >
            <Dislike width="50px" heigth="50px" />
          </button>
        </div>
      </div>)
  }

  finishOk = () => {
    const { onFinish } = this.props;
    const { surveyScore, surveyComment } = this.state;

    onFinish && onFinish({ score: surveyScore, comment: surveyComment });
    this.closeSurvey(true);
  };

  closeSurvey = type => {
    const { onClose } = this.props;

    this.setState(
      {
        visible: false
      },
      _ => {
        onClose && onClose(type);
      }
    );
  };

  rememberLater = () => {
    const { rememberLater } = this.props

    this.setState({ visible: false })
    rememberLater()
  }

  render() {
    const { visible, surveySaved, surveyError, renderFeedback, renderSurvey } = this.state;

    if (!visible) {
      return null;
    }

    return (
      <S.npsContainer>
        <div className="blockFrame">
          {surveySaved && this.renderSuccessIllustration()}
          {!surveySaved && !surveyError && !renderFeedback && renderSurvey && this.renderSurvey()}
          {surveyError && this.renderError()}
          {renderFeedback && this.renderFeedback()}
        </div>
      </S.npsContainer>
    );
  }
}

Nps.propTypes = {
  onFinish: PropTypes.func.isRequired,
  onClickItem: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  rememberLater: PropTypes.func.isRequired,
  checkOnLoad: PropTypes.bool,
  customParams: PropTypes.object,
  user: PropTypes.string.isRequired,
  systemId: PropTypes.string.isRequired,
  className: PropTypes.string,
  texts: PropTypes.object.isRequired,
  acceptLanguage: PropTypes.string.isRequired
};

Nps.defaultProps = {
  checkOnLoad: true,
  customParams: {}
};

export { Nps };
