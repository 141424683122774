import React from 'react'
import intl from 'react-intl-universal'
import * as S from '../../styled'
import OptionGroup from 'components/option-group'

const INTL_GERAL = 'master-data.configurations';

const GeneralSetting = ({ image, name, hasDescription, updateOptionsSide, optionsSide }) => {
	return (
		<S.GeneralSetting>
			<S.GeneralSettingsHeaderWrapper>
				<S.GeneralSettingsHeader>
					<S.GeneralSettingsWrapperImg> {image} </S.GeneralSettingsWrapperImg>
					<S.GeneralSettingsTitle>{intl.get(`${INTL_GERAL}.${name}.header.title`)}</S.GeneralSettingsTitle>
				</S.GeneralSettingsHeader>
			</S.GeneralSettingsHeaderWrapper>

			<S.GeneralSettingsContentWrapper>
				<S.GeneralSettingsContentTitle>{intl.get(`${INTL_GERAL}.${name}.content.title`)}</S.GeneralSettingsContentTitle>
				{hasDescription && <div>
					<S.GeneralSettingsContentText>
						<span className='content__span'>{intl.get(`${INTL_GERAL}.${name}.content.span01`)} </span>
						<p className='text'>- {intl.get(`${INTL_GERAL}.${name}.content.text01`)}</p>
					</S.GeneralSettingsContentText>
					<S.GeneralSettingsContentText>
						<span className='content__span'>{intl.get(`${INTL_GERAL}.${name}.content.span02`)} </span>
						<p className='text'>- {intl.get(`${INTL_GERAL}.${name}.content.text02`)}</p>
					</S.GeneralSettingsContentText>
				</div>}
				<S.OptionGroupContent>
					<OptionGroup
						handleSelect={event => updateOptionsSide(event.currentTarget.value)}
						options={optionsSide}
						dataTestId='options-group'
					/>
				</S.OptionGroupContent>
			</S.GeneralSettingsContentWrapper>
		</S.GeneralSetting>
	)
}

export default GeneralSetting;
