import {
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import Session from "utils/user-storage";

import {
  verifyFilterUser,
} from "../config/user/user.service";
import { LoadColumsTable } from "../config/user/utils/colums-tables";
import { loadOrderCancellationJustifications, loadSuggestionJustifications } from "../pages/master-data/configurations/general-settings/general-settings.service";
import ErrorPage from "pages/404";
import { GlobalProvider } from "./globalContext";
import { Navbar } from "features/navbar";
import { perfilPcpUnidadeLogado } from "../services/permissions/getPermissions";
import Feature from "components/feature";
import SurveyNps from "features/survey-nps";
import { getShowNPS } from "utils/config-environment";
import { ToastProvider } from "./toastContext";

/**
 * Contexto criado para encapsular lógica de obtenção de dados do
 * usuário pós SSO para acesso a aplicação.
 */
const UserContext = createContext();

const UserProvider = ({ children }) => {
  const [user, setUser] = useState(undefined);

  const loadConfig = async () => {
    const activeUser = await Session.get();
    setUser(activeUser);

    LoadColumsTable();

    if (!activeUser) {
      return;
    }

    try {
      const orderCancellationJustifications = await loadOrderCancellationJustifications();
      const suggestionJustifications = await loadSuggestionJustifications();

      const enumerators = {
        OrderCancellationJustifications: orderCancellationJustifications.data,
        SuggestionJustifications: suggestionJustifications.data
      };

      localStorage.setItem("enumerators", JSON.stringify(enumerators));

      if (perfilPcpUnidadeLogado()) {
        localStorage.setItem("filterMyItemsOnly", true);
      } else {
        const myItemsOnly = localStorage.getItem('filterMyItemsOnly')

        if (myItemsOnly === null) {
          const filterMyItemsOnly = await verifyFilterUser(activeUser.Id);
          localStorage.setItem("filterMyItemsOnly", filterMyItemsOnly.data);
        }
      }

    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    loadConfig();
  }, []);

  if (!user && !Session.get().Id) {
    return <ErrorPage />;
  }

  return (
    <GlobalProvider>
      <ToastProvider>
        <UserContext.Provider>
          <main className={`container-main`}>
            <Navbar />
            {children}
            <Feature validation={getShowNPS()}>
              <SurveyNps />
            </Feature>
          </main>
        </UserContext.Provider >
      </ToastProvider>
    </GlobalProvider>
  );
};

export const useUserContext = () => useContext(UserContext);
export { UserProvider, UserContext };