import { Component } from 'react'
import Loading from 'components/center-loading'
import Fetch from 'utils/fetch'
import StorePersist from 'utils/store-persist'
import SnackBar from 'material-ui/Snackbar'
import HeaderIcon from 'components/header-icon'
import IconNew from 'images/icn-add-small-blue.svg';
import { SiloIcon, FactoryIcon } from 'components/icons/icn-index.icon'
import { Button, SelectBox } from 'components/uikit-adapter/index'
import { Row, Col } from 'react-flexbox-grid'
import StyledSiloManagement from './StyledSiloManagement'
import { FiltersFamiliaRotuladaCentroClean } from 'components/filters'
import intl from 'react-intl-universal'

import SiloManagementList from './list'
import SiloManagementForm from './form'

class SiloManagement extends Component {
  constructor() {
    super()

    this.state = {
      requestCount: 0,
      showFeedback: false,
      messageFeedBack: '',
      idUnidadeNegocio: 0,
      listaUnidadeNegocio: [],
      listaSilos: [],
      openForm: false,
      idSiloSelecionado: 0,
      openEditMode: false,
    }
  }

  componentDidMount() {
    this.searchBusinessUnit();
  }

  handleFetch = (isFetching = true) => {
    this.setState(prevState => ({
      requestCount: isFetching ? prevState.requestCount + 1 : prevState.requestCount - 1,
    }))
  }

  changeUnidadeNegocio = (value) => {
    this.setState({
      idUnidadeNegocio: value
    }, () => this.searchSilos())
  }

  searchBusinessUnit = () => {
    this.handleFetch()
    Fetch.get(`/unidadeNegocio/unidadeGeral`)
      .then((response) => this.processData('listaUnidadeNegocio', response.data))
      .then(() =>
        this.setState(prevState => ({
          idUnidadeNegocio: prevState.listaUnidadeNegocio[0].IdUnidadeNegocio
        }), () => this.searchSilos()))
      .catch((e) => this.proccessErro(e))
      .finally(() => this.handleFetch(false))
  }

  processData = (property, data) => {
    this.setState({
      [property]: data
    })
  }

  searchSilos = () => {
    this.handleFetch()
    Fetch.get(`/silo/obterSilosPorUnidadeNegocio/${this.state.idUnidadeNegocio}`)
      .then((response) => this.setState({ listaSilos: response.data }))
      .catch((e) => this.proccessErro(e))
      .finally(() => this.handleFetch(false))
  }


  openForm = (idSiloSelecionado, openEditMode) => {
    this.setState({
      openForm: true,
      idSiloSelecionado,
      openEditMode
    })
  }

  closeForm = () => {
    this.setState({
      openForm: false,
      idSiloSelecionado: 0,
      openEditMode: false
    })
  }

  proccessErro = (exception) => {
    this.showFeedback(exception.response.Message)
  }

  showFeedback = (message) => {
    this.setState({
      showFeedback: true,
      messageFeedBack: message
    })
  }

  closeFeedback = () => {
    this.setState({
      showFeedback: false
    })
  }

  redirecionarGeral = (idFamiliaRotulada, idCentro) => {
    if (!idFamiliaRotulada) {
      this.showFeedback(intl.get('master-data.feedback.labeledFamilyNotRegistered'))
    }
    else {
      const params = {
        ...FiltersFamiliaRotuladaCentroClean,
        IdFamiliaRotulada: idFamiliaRotulada,
        IdCentro: idCentro,
      }

      StorePersist.setValuesJSON('filtersFamiliaRotuladaCentro', params)
      this.props.history.push(`/master-data/general`)
    }
  }

  render() {
    let {
      requestCount,
      showFeedback,
      messageFeedBack,
      listaSilos,
      listaUnidadeNegocio,
      openForm,
      openEditMode,
      idSiloSelecionado,
      idUnidadeNegocio
    } = this.state

    return (
      <StyledSiloManagement>
        <Loading isLoading={requestCount > 0} />

        <Row>
          <Col xs={7} lg={7} md={7}>
            <HeaderIcon
              title={intl.get('master-data.managementUnity.silos.header.title')}
              subtitle={intl.get('master-data.managementUnity.silos.header.subtitle')}
              icon={<SiloIcon width='45px' height='45px' color='var(--color-brand-500)' />}>
            </HeaderIcon>
          </Col>

          <Col xs={1} lg={1} md={1} className="head-buttons-icon">
            <FactoryIcon
              width='33px'
              height='33px'
              className='icon-unit'
            />
          </Col>

          <Col xs={4} lg={4} md={4} className="head-buttons-filter">
            <SelectBox
              required
              name='IdUnidadeNegocio'
              label=''
              placeholder={intl.get('manual-suggestion-order-form.unit')}
              valueKey='IdUnidadeNegocio'
              labelKey='Descricao'
              value={idUnidadeNegocio}
              options={listaUnidadeNegocio}
              onChange={value => this.changeUnidadeNegocio(value.IdUnidadeNegocio)}
              searchable
              openOnFocus
              clearable
            />

            <Button
              type='default'
              icon={IconNew}
              className="btn-add-new-silo"
              onClick={this.openForm}
            />

          </Col>

        </Row>

        <Row>
          <Col xs={12} lg={12} md={12}>
            <SiloManagementList
              data={listaSilos}
              idUnidadeNegocio={idUnidadeNegocio}
              handleOpenForm={this.openForm}
              handleRedirecionarGeral={this.redirecionarGeral}
              handleFeedback={this.showFeedback}
            />
          </Col>
        </Row>

        {openForm &&
          <SiloManagementForm
            idEdit={idSiloSelecionado}
            idUnidadeNegocio={idUnidadeNegocio}
            open={openForm}
            editMode={openEditMode}
            handleClose={this.closeForm}
            handleFeedback={this.showFeedback}
            handleRefresh={this.searchSilos}
          />
        }

        <SnackBar
          message={messageFeedBack}
          open={showFeedback}
          autoHideDuration={3000}
          onRequestClose={this.closeFeedback}
        />

      </StyledSiloManagement>
    )
  }
}

export default SiloManagement
