import { Component } from 'react'
import PropTypes from 'prop-types'
import Fetch from 'utils/fetch'
import Dialog from 'material-ui/Dialog'
import { Row, Col } from 'react-flexbox-grid'
import { Button, Input, SelectBox } from 'components/uikit-adapter/index'
import { formatErrorMessage } from 'utils/handle-error'
import Loading from 'components/center-loading'

import StyledForm from './styledForm'
import intl from 'react-intl-universal'
import { formatNumber } from 'utils/format'
import { parseFloatByLocale } from 'locales/utils'
import InputNumber from 'components/input-number'

class SiloManagementForm extends Component {

  constructor() {
    super()

    this.state = {
      requestCounter: 0,
      silo: { Status: 1 },
      statusLista: [],
      depositoLista: [],
      familiaRotuladaLista: [],
      materialLista: [],
      listaMateriaisEmSilo: []
    }
  }

  componentDidMount() {
    const editMode = this.props.editMode
    this.searchStatusSiloLista()
    this.searchDepositoLista()
    this.searchFamiliaRotuladaLista()
    if (editMode) {
      this.searchSiloById()
    }
  }

  handleFetch = (isFetching = true) => {
    this.setState(prevState => ({
      requestCounter: isFetching ? prevState.requestCounter + 1 : prevState.requestCounter - 1,
    }))
  }

  searchSiloById = () => {
    this.handleFetch()
    const id = this.props.idEdit
    Fetch.get(`/silo/${id}`)
      .then((response) => this.proccessSilo(response.data))
      .catch((e) => this.proccessErro(e))
      .finally(() => this.handleFetch(false))
  }

  proccessSilo = (data) => {
    this.setState({
      silo: {
        ...data,
        Capacidade: formatNumber(data.Capacidade),
        PontoReabastecimento: formatNumber(data.PontoReabastecimento),
        Estoque: formatNumber(data.Estoque),
      }
    }, () => {
      this.searchMaterialLista(this.state.silo.IdFamiliaRotulada)
      this.searchListaMaterialCadastroSAP(this.state.silo.IdUnidadeNegocioDeposito)
    })
  }

  searchStatusSiloLista = () => {

    this.setState({
      statusLista: [
        { Id: 1, Descricao: 'Ativo' },
        { Id: 0, Descricao: 'Desativado' },
      ]
    })
  }

  searchDepositoLista = () => {
    this.handleFetch()
    Fetch.get(`/silo/obterDepositosPorUnidade/${this.props.idUnidadeNegocio}`)
      .then((response) => { this.setState({ depositoLista: response.data }) })
      .catch((e) => this.proccessErro(e))
      .finally(() => this.handleFetch(false))
  }

  searchFamiliaRotuladaLista = () => {
    this.handleFetch()
    Fetch.get(`/familiaRotulada/ObterFamiliasRotuladasCadastradasNoGeralDePoliticaSilosPorUnidade/${this.props.idUnidadeNegocio}`)
      .then((response) => { this.setState({ familiaRotuladaLista: response.data }) })
      .catch((e) => this.proccessErro(e))
      .finally(() => this.handleFetch(false))
  }

  searchMaterialLista = (IdFamiliaRotulada) => {
    if (IdFamiliaRotulada) {
      this.handleFetch()
      Fetch.get(`/material/BuscarMateriaisDoGeralUsaEstoquePorFamiliaRotuladaCentro/${IdFamiliaRotulada}/${this.props.idUnidadeNegocio}`)
        .then((response) => { this.setState({ materialLista: response.data }) })
        .catch((e) => this.proccessErro(e))
        .finally(() => this.handleFetch(false))
    }
  }

  proccessErro = (exception) => {
    try {
      const erro = formatErrorMessage(exception)
      this.props.handleFeedback(erro)
    } catch (e) {
      this.props.handleFeedback(intl.get('feedbacks.serverError'))
    }
  }

  changeValue = (prop, value, extra) => {
    const { silo } = this.state;
    let _silo = {
      ...silo,
      [prop]: value
    }
    if (prop == 'IdUnidadeNegocioDeposito') {
      _silo.Nome = extra
      this.searchListaMaterialCadastroSAP(value);
    }

    this.setState({ silo: _silo })

    if (prop == 'IdFamiliaRotulada') {
      this.searchMaterialLista(value)
    }
  }

  searchListaMaterialCadastroSAP = (unidadeNegocioDepositoId) => {
    Fetch.get(`/silo/obterMaterialHabilitadoNoCadastroSap/${unidadeNegocioDepositoId}`)
      .then((response) => { this.setState({ listaMateriaisEmSilo: response.data }) })
      .catch((e) => this.proccessErro(e))
  }

  changeDecimalValue = (prop, value) => {
    this.setState(prevState =>({
      silo: {
        ...prevState.silo,
        [prop]: value
      }
    }))
  }

  changeDecimalValueWith3Places = (prop, value) => {
    this.setState(prevState => ({
      silo: {
        ...prevState.silo,
        [prop]: value
      }
    }))
  }

  calculaOcupacao = (capacidade, estoque) => {
    const cap = parseFloatByLocale(capacidade)
    const est = parseFloatByLocale(estoque)

    if (!cap || !est) return ''

    return formatNumber((est * 100) / cap)
  }

  save = () => {
    const silo = {
      ...this.state.silo,
      IdUnidadeNegocio: this.props.idUnidadeNegocio,
      Capacidade: parseFloatByLocale(this.state.silo.Capacidade),
      PontoReabastecimento: parseFloatByLocale(this.state.silo.PontoReabastecimento),
    }

    let camposInvalidos = []
    if (!silo.IdUnidadeNegocioDeposito) camposInvalidos.push(intl.get('feedbacks.messageDeposit'))
    if (silo.Status !== 0 && silo.Status !== 1) camposInvalidos.push(intl.get('commons.status'))
    if (!silo.Nome) camposInvalidos.push(intl.get('commons.description'))

    if (camposInvalidos.length > 0) {
      this.props.handleFeedback(`${intl.get('feedbacks.messageUserBusiness1')} ${camposInvalidos.join(', ')} ${intl.get('feedbacks.messageUserBusiness2')}`)
    } else {
      this.handleFetch()
      Fetch.post(`/silo/salvar`, silo)
        .then(this.props.handleRefresh)
        .then(() => this.props.handleFeedback(intl.get('feedbacks.savedRegister')))
        .then(() => this.props.handleClose())
        .catch((e) => this.proccessErro(e))
        .finally(() => this.handleFetch(false))
    }
  }

  render() {
    const {
      silo,
      statusLista,
      depositoLista,
      familiaRotuladaLista,
      materialLista,
      listaMateriaisEmSilo,
      requestCounter
    } = this.state
    const { open, editMode } = this.props

    return (
      <div>
        <Loading isLoading={requestCounter > 0} />
        <Dialog
          title={editMode ? intl.get('master-data.managementUnity.grid.silos.actions.editSilo') : intl.get('master-data.managementUnity.grid.silos.actions.newSilo')}
          contentStyle={{ width: '600px' }}
          open={open}
        >
          <StyledForm>
            <div className="sideFilterFields">
              <Row>
                <Col xs={12} md={12} lg={12}>
                  <SelectBox
                    required
                    label={intl.get('master-data.general-configuration.deposit')}
                    placeholder={intl.get('master-data.general-configuration.deposit')}
                    valueKey='Id'
                    labelKey='Descricao'
                    value={silo.IdUnidadeNegocioDeposito}
                    options={depositoLista}
                    onChange={value => this.changeValue('IdUnidadeNegocioDeposito', value.Id, value.NomeDeposito)}
                    searchable
                    openOnFocus
                    name='IdUnidadeNegocioDeposito'
                  />
                </Col>
              </Row>

              <Row>
                <Col xs={12} md={12} lg={12}>
                  <Input
                    disabled
                    label={intl.get('master-data.general-configuration.siloDescription')}
                    value={silo.Nome}
                    onChange={(event) => this.changeValue('Nome', event.currentTarget.value)}
                    className="input"
                  />
                </Col>
              </Row>

              <Row>
                <Col xs={12} md={12} lg={12}>
                  <SelectBox
                    required
                    label='Status'
                    placeholder='Status'
                    valueKey='Id'
                    labelKey='Descricao'
                    value={silo.Status}
                    options={statusLista}
                    onChange={value => this.changeValue('Status', value.Id)}
                    searchable
                    openOnFocus
                    name='Status'
                  />
                </Col>
              </Row>

              <Row>
                <Col xs={12} md={12} lg={12}>
                  <SelectBox
                    required
                    label={intl.get('master-data.general-configuration.labeledFamily')}
                    placeholder={intl.get('master-data.general-configuration.labeledFamily')}
                    valueKey='Id'
                    labelKey='Descricao'
                    value={silo.IdFamiliaRotulada}
                    options={familiaRotuladaLista}
                    onChange={value => this.changeValue('IdFamiliaRotulada', value.Id)}
                    searchable
                    openOnFocus
                  />
                </Col>
              </Row>

              <Row>
                <Col xs={12} md={12} lg={12}>
                  <SelectBox
                    required
                    label='Material'
                    placeholder='Material'
                    valueKey='Id'
                    labelKey='Descricao'
                    value={silo.IdMaterial}
                    options={materialLista}
                    onChange={value => this.changeValue('IdMaterial', value.Id)}
                    disabled={!silo.IdFamiliaRotulada}
                    searchable
                    openOnFocus
                  />
                </Col>
              </Row>
              <fieldset>
                <legend>{intl.get('master-data.managementUnity.grid.silos.actions.message2')}</legend>
                <Row>
                  <div className="fieldsetMateriais">
                    {listaMateriaisEmSilo.map((currentValue) => (
                      <Col xs={12}>
                        <h5>{currentValue.NumeroMaterial} - {currentValue.Material}</h5>
                      </Col>
                    ))}
                  </div>
                </Row>
              </fieldset>

              <Row>
                <Col xs={6} md={6} lg={6}>
                  <InputNumber
                    required
                    type='default'
                    label={intl.get('master-data.general-configuration.capacity')}
                    value={silo.Capacidade}
                    onChange={(_value) => this.changeDecimalValueWith3Places('Capacidade', _value)}
                    name='Capacidade'
                  />
                </Col>
                <Col xs={6} md={6} lg={6}>
                  <InputNumber
                    required
                    type='default'
                    label={intl.get('master-data.general-configuration.replenishment')}
                    value={silo.PontoReabastecimento}
                    onChange={(_value) => this.changeDecimalValue('PontoReabastecimento', _value)}
                    name='PontoReabastecimento'
                  />
                </Col>
              </Row>

              <Row style={{ marginBottom: '130px' }}>
                <Col xs={6} md={6} lg={6}>
                  <Input
                    disabled
                    label={intl.get('master-data.general-configuration.stock')}
                    value={silo.Estoque}
                    className="input"
                  />
                </Col>
                <Col xs={6} md={6} lg={6}>
                  <Input
                    disabled
                    label={intl.get('master-data.general-configuration.ocupation')}
                    value={this.calculaOcupacao(silo.Capacidade, silo.Estoque)}
                    className="input"
                  />
                </Col>
              </Row>
              <div className="filter-container">
                <Row className="footerFilterContainer no-margin">
                  <Col xs={12} lg={12} md={12}>
                    <Row>
                      <Col xs={12} lg={12} md={12}>
                        <hr className="filter-divisor" />
                      </Col>
                    </Row>
                    <Row end='xs'>
                      <Col xs={4}>
                        <Button
                          type="default"
                          value={intl.get("geral.buttonsDefault.cancel")}
                          onClick={this.props.handleClose}
                          className="button"
                        />
                      </Col>
                      <Col xs={4}>
                        <Button
                          type="primary"
                          value={intl.get("geral.buttonsDefault.save")}
                          className="button"
                          onClick={this.save}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
          </StyledForm>
        </Dialog>
      </div>
    )
  }
}

SiloManagementForm.propTypes = {
  idEdit: PropTypes.number,
  idUnidadeNegocio: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  handleRefresh: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired
}

export default SiloManagementForm
