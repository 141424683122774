import { Component } from 'react'
import Fetch from 'utils/fetch'
import { Row, Col } from "react-flexbox-grid"
import { Button, Input, Toggle, SelectBox } from 'components/uikit-adapter/index'
import Dialog from 'material-ui/Dialog'
import CenterLoading from 'components/center-loading'
import InputMultiEmail from 'components/Adapters/input-multi-email'
import Session from 'utils/user-storage'
import intl from 'react-intl-universal'
import './supplier-group-form.css'
import { UserLanguage } from './utils/config-language'
import { formatErrorMessage } from 'utils/handle-error.js'
import Feature from 'components/feature'
import { isLoadCompositionFlowSupplierSide } from 'utils/validations-general-settings'
import { searchAllCountries } from 'pages/master-data/coordination-user/user/user-form/user-form.service'

class SupplierGroupForm extends Component {
  constructor() {
    super()

    this.state = {
      user: {
        RecebeEmail: true,
        HabilitaCriacaoPedido: false,
        ReceivePendingOrdersEmail: false,
        ReceiveConfirmedOrdersEmail: false,
      },
      isSaving: false,
      errors: {
        type: '',
      },
      countries: []
    }
  }

  componentDidMount() {
    this.loadCountries();
    const editMode = this.props.editMode;
    if (editMode) {
      this.searchUserById()
    }
  }

  loadCountries = async () => {
    const countries = await searchAllCountries();
    this.setState({
      countries: countries
    })
  }

  startLoading = () => {
    this.setState({
      isSaving: true
    })
  }

  stopLoading = () => {
    this.setState({
      isSaving: false
    })
  }

  searchUserById = () => {
    this.startLoading();
    const userId = this.props.idEdit
    Fetch.get(`/usuario/${userId}`)
      .then((response) => this.proccessData(response.data))
      .catch((e) => this.proccessErro(e))
  }

  proccessErro = (exception) => {
    this.stopLoading();
    const errorMessage = formatErrorMessage(exception);
    this.props.handleFeedback(errorMessage);
  }

  proccessData = (data) => {
    this.setState({ user: data })
    this.stopLoading();
  }

  changeValue = (event) => {
    const prop = event.currentTarget.name
    const value = event.currentTarget.value

    this.setState(prevState => ({
      user: {
        ...prevState.user,
        [prop]: value
      }
    }))
  }

  changeValueEmail = (value) => {
    this.setState(prevState => ({
      user: {
        ...prevState.user,
        Email: value
      }
    }))
  }

  validateSave = () => {
    let valid = {
      isValid: true,
      messageError: ""
    };

    const user = this.state.user;

    if (!user.Nome)
      valid = { isValid: false, messageError: intl.get('feedbacks.messageUserForm4') }
    else if (!user.Pais)
      valid = { isValid: false, messageError: intl.get('feedbacks.messageUserForm6') }
    else if (!user.Idioma)
      valid = { isValid: false, messageError: intl.get('feedbacks.messageUserForm7') }

    return valid
  }

  save = () => {
    const valid = this.validateSave();
    if (!valid.isValid) {
      const ex = { response: { status: 400, data: { Errors: [{ Detail: valid.messageError }] } } };
      this.proccessErro(ex);
      return;
    }

    this.startLoading()
    let model = this.state.user
    const editMode = this.props.editMode
    const user = Session.get()

    Fetch.post(`/usuario/salvar/fornecedores/${user.Id}`, model)
      .then(this.props.handleRefresh)
      .then(() => this.props.handleFeedback(`${intl.get('feedbacks.register')} ${!editMode ? intl.get('feedbacks.saved') : intl.get('feedbacks.updated')} ${intl.get('feedbacks.withSuccess')}`))
      .then(this.stopLoading)
      .catch((e) => this.proccessErro(e))
  }

  onCloseClick = () => {
    this.props.handleClose()
  }

  changeRecebeEmail = (checked) => {
    this.setState(prevState => ({
      user: {
        ...prevState.user,
        RecebeEmail: checked
      }
    }))
  }

  changeReceivePendingOrdersEmail = (checked) => {
    this.setState(prevState => ({
      user: {
        ...prevState.user,
        ReceivePendingOrdersEmail: checked
      }
    }))
  }

  changeReceiveConfirmedOrdersEmail = (checked) => {
    this.setState(prevState => ({
      user: {
        ...prevState.user,
        ReceiveConfirmedOrdersEmail: checked
      }
    }))
  }

  changeHabilitaCriacaoPedido = (checked) => {
    this.setState(prevState => ({
      user: {
        ...prevState.user,
        HabilitaCriacaoPedido: checked
      }
    }))
  }

  changeUser = (value, prop) => {
    this.setState(prevState => ({
      user: {
        ...prevState.user,
        [prop]: value ? value : 0
      }
    }))
  }

  render() {
    const { user, isSaving } = this.state;
    const { open, editMode, profileAdminOrMasterData } = this.props;

    return (
      <div>
        <Dialog
          title={editMode ? intl.get('master-data.suppliers.usersGroupSupplier.actions.editSupplierGroup') : intl.get('master-data.suppliers.usersGroupSupplier.actions.newSupplierGroup')}
          contentStyle={{ width: '600px' }}
          autoScrollBodyContent={true}
          open={open}
        >
          <CenterLoading isLoading={isSaving} fullHeightParent />
          <Row>
            <Col xs={12} lg={12} md={12}>
              <Input
                className="input"
                required
                name='Nome'
                label={intl.get('master-data.general-configuration.name')}
                value={user.Nome}
                onChange={this.changeValue}
                disabled={!profileAdminOrMasterData}
              />
            </Col>
          </Row>

          <Row>
            <Col xs={12} lg={12} md={12}>
              <InputMultiEmail
                required
                labelText='Email'
                emails={user.Email}
                onChange={this.changeValueEmail}
                disabled={!profileAdminOrMasterData}
              />
            </Col>
          </Row>
          <label className="label-style">{intl.get('master-data.menus.configuration')}</label>
          <div className="container">
            <Row>
              <Col xs={12} lg={12} md={12}>
                <Toggle
                  name="RecebeEmail"
                  label={intl.get('master-data.suppliers.usersGroupSupplier.actions.configurations.receiveMail')}
                  value={user.RecebeEmail}
                  onChange={value => this.changeRecebeEmail(value.checked)}
                  thumbSwitchedStyle={{ backgroundColor: 'var(--color-action-default)' }}
                  trackSwitchedStyle={{ backgroundColor: 'var(--color-neutral-200)' }}
                />
                <Toggle
                  name="ReceivePendingOrdersEmail"
                  label={intl.get('master-data.suppliers.usersGroupSupplier.actions.configurations.receivePendingOrdersEmail')}
                  value={user.ReceivePendingOrdersEmail}
                  onChange={value => this.changeReceivePendingOrdersEmail(value.checked)}
                  thumbSwitchedStyle={{ backgroundColor: 'var(--color-action-default)' }}
                  trackSwitchedStyle={{ backgroundColor: 'var(--color-neutral-200)' }}
                />
                 <Toggle
                  name="ReceiveConfirmedOrdersEmail"
                  label={intl.get('master-data.suppliers.usersGroupSupplier.actions.configurations.receiveConfirmedOrdersEmail')}
                  value={user.ReceiveConfirmedOrdersEmail}
                  onChange={value => this.changeReceiveConfirmedOrdersEmail(value.checked)}
                  thumbSwitchedStyle={{ backgroundColor: 'var(--color-action-default)' }}
                  trackSwitchedStyle={{ backgroundColor: 'var(--color-neutral-200)' }}
                />
                <Feature validation={isLoadCompositionFlowSupplierSide()}>
                  <Toggle
                    name="HabilitaCriacaoPedido"
                    label={intl.get('master-data.suppliers.usersGroupSupplier.actions.configurations.ableOrders')}
                    value={user.HabilitaCriacaoPedido}
                    onChange={value => this.changeHabilitaCriacaoPedido(value.checked)}
                    thumbSwitchedStyle={{ backgroundColor: 'var(--color-action-default)' }}
                    trackSwitchedStyle={{ backgroundColor: 'var(--color-neutral-200)' }}
                  />
                </Feature>
              </Col>
            </Row>
          </div>
          <Row>
            <Col xs={6} lg={6} md={6}>
              <SelectBox
                label={intl.get('commons.country')}
                style={{ height: '45px' }}
                value={user.Pais}
                options={this.state.countries}
                valueKey="Key"
                labelKey="Value"
                onChange={e => this.changeUser(e.Key, "Pais")}
                placeholder={intl.get('commons.country')}
                openOnFocus
                disabled={!profileAdminOrMasterData}
                menuPlacement='top'
                menuListStyle={{ maxHeight: '200px' }}
              />
            </Col>
            <Col xs={6} lg={6} md={6}>
              <SelectBox
                label={intl.get('commons.language')}
                style={{ height: '45px' }}
                value={user.Idioma}
                options={UserLanguage}
                valueKey="Key"
                labelKey="Value"
                onChange={e => this.changeUser(e.Key, "Idioma")}
                placeholder={intl.get('commons.language')}
                openOnFocus
                disabled={!profileAdminOrMasterData}
                menuPlacement='top'
                menuListStyle={{ maxHeight: '200px' }}
              />
            </Col>
          </Row>

          <div className='footer-dialog-buttons' style={{ marginTop: '20px' }}>
            <Button
              type="default"
              value={intl.get('geral.buttonsDefault.close')}
              onClick={this.onCloseClick}
              className="button"
            />

            {profileAdminOrMasterData &&
              <Button
                type="primary"
                value={intl.get('geral.buttonsDefault.save')}
                className="button"
                onClick={this.save}
              />
            }
          </div>
        </Dialog>
      </div>
    )
  }
}

export default SupplierGroupForm
