import { TableRowColumn } from 'material-ui/Table'

const TableRownColumnUnclickable = ({ style, children }) => (
  <TableRowColumn style={style} onClick={e => e.stopPropagation()}>
    <div style={{ width: '100%' }} >
      {children}
    </div>
  </TableRowColumn>
)

export default TableRownColumnUnclickable
