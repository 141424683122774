import React, { Component } from "react"
import PropTypes from "prop-types"
import Message from "components/message"
import { Row, Col } from "react-flexbox-grid"
import { Textarea } from "@hbsis.uikit/react"
import { Button } from "components/uikit-adapter"

import "./recusa.css"
import { getTextAreaStyle } from "components/suggestions-list/suggestions-list-item/suggestions-list-item.styled"

class RecusaFornecedor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      observacao: "",
      showMsg: false,
      textMsg: "",
      titleMsg: "",
      isLoading: false
    };
  }

  componentDidMount() {
    this.setState({ observacao: "" })
  }

  changeValue = (event) => {
    const prop = event.target.name
    let value = event.target.value

    this.setState({
      [prop]: value
    })
  }

  confirm = (e) => {
    e.preventDefault();
    this.props.recusa(this.props.idPedido, this.state.observacao)
  }

  render() {
    let { observacao } = this.state;

    return (
      <div className="negotiation-volume">
          <div className="negotiation-line-volume">
            <Row>
              <Col xl={12}>
                <Textarea
                  required
                  name="observacao"
                  label="Justificativa"
                  value={observacao|| ''}
                  onChange={this.changeValue}
                  style={getTextAreaStyle({ width: '400px' })}
                />
              </Col>
            </Row>
          </div>
          <div className="negotiation-line-volume">
            <div className="event-action-offer-volume">
              <Row>
                <Col xl={6}>
                  <Button
                    value="Fechar"
                    onClick={this.props.handleCancel}
                    type="default"
                    className="button"
                  />
                </Col>
                <Col xl={6}>
                  <Button
                    value="Confirmar"
                    onClick={this.confirm}
                    type="primary"
                    className="button"
                  />
                </Col>
              </Row>
            </div>
          </div>
        <Message
          show={this.state.showMsg}
          text={this.state.textMsg}
          title={this.state.titleMsg}
          handleClose={() => {
            this.setState({ showMsg: false });
          }}
        />
      </div>
    );
  }
}

RecusaFornecedor.propTypes = {
  idPedido: PropTypes.number,
  refreshTimeLine: PropTypes.func,
  handleFeedback: PropTypes.func,
  handleCancel: PropTypes.func.isRequired,
  data: PropTypes.object,
}

export default RecusaFornecedor