import React, { Component } from 'react'
import Card from 'components/card'
import Header from 'components/header'
import Loading from 'components/center-loading'
import Fetch from 'utils/fetch'
import PropTypes from 'prop-types'
import Session from 'utils/user-storage'
import SnackBar from 'material-ui/Snackbar'
import Timeline from 'components/timeline'
import { Textarea } from '@hbsis.uikit/react';
import { Button } from 'components/uikit-adapter'
import * as service from './orders-vision-detail.service';

import intl from 'react-intl-universal'

import 'components/title-header'
import './orders-vision-detail.css'
import 'components/cards-obsolete/card/card.css'
import { formatErrorMessage } from '../../utils/handle-error'
import { claimSupplierHasAccessToInfo } from 'utils/supplier-access'
import { NoAccessSupplierPage } from 'components/no-access-suplier-page'
import { getTextAreaStyle } from 'components/suggestions-list/suggestions-list-item/suggestions-list-item.styled'


class OrdersVisionDetail extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isFetching: false,
      orders: [],
      showFeedback: false,
      messageFeedback: '',
      showMsgError: false,
      textMsgError: '',
      searchTimeLine: false,
      observacaoFornecedor: '',
    }
  }

  componentDidMount() {
    this.searchOrders()
  }

  showFeedback = (message) => {
    this.setState({
      showFeedback: true,
      messageFeedback: message
    })
  }

  hideFeedback = () => {
    this.setState({
      showFeedback: false
    })
  }

  refreshCard = () => {
    this.searchOrders()
    this.setState({
      searchTimeLine: true,
    })
  }

  startFetching = () => {
    this.setState({
      isFetching: true
    })
  }

  stopFetching = () => {
    this.setState({
      isFetching: false
    })
  }

  searchTimeLineFinished = () => {
    this.setState({
      searchTimeLine: false,
    })
  }

  searchOrders = () => {
    const user = Session.get()
    const { orderId, typeNeed } = this.props.match.params

    this.startFetching()
    Fetch.get(`/visaoPedido/consultaPedidosPorIdETipo/${user.Id}/${orderId}/${typeNeed}`)
      .then((response) => this.proccessData(response.data))
      .catch((e) => this.proccessErro(e))
      .finally(() => this.stopFetching())
  }

  proccessData = async (data) => {
    const newData = data.filter(Boolean)

    this.setState({
      orders: newData,
      observacaoFornecedor: newData.length > 0 ? newData[0].ObservacaoFornecedor : '',
      hasAccess: await claimSupplierHasAccessToInfo(newData[0].IdFornecedor.toString())
    })
  }

  proccessErro = (exception) => {
    console.log(exception);
  }

  getTypeUserCard = () => {
    const user = Session.get()
    return user.TipoUsuario === 1 ? 'AMBEV' : 'SUPPLIER'
  }

  isUserSupplier = () => {
    const user = Session.get()
    return user.TipoUsuario === 2
  }

  handleFetch = (isFetching = true) => {
    this.setState((prevState) => ({
      requestCount: isFetching ? prevState.requestCount + 1 : prevState.requestCount - 1,
    }))
  }

  changeValue = (event) => {
    const prop = event.target.name
    const value = event.target.value

    this.setState({ [prop]: value });
  }

  async salvarObservacoesFornecedor(idPedido) {
    const model = {
      IdPedido: idPedido,
      Observacao: this.state.observacaoFornecedor,
    }

    try {
      this.handleFetch()
      await service.postSupplierNotes(model);
      this.showFeedback(intl.get('stocks.timeline.feedback.supplierNotesSavedSuccessfully'));
    } catch (err) {
      this.showFeedback(formatErrorMessage(err))
    } finally {
      this.handleFetch(false);
    }
  }

  render() {
    const { showFeedback, messageFeedback, isFetching, orders, hasAccess } = this.state;
    const { orderId, typeNeed } = this.props.match.params;
    const idPedido = parseInt(orderId);

    if(orders.length > 0 && !hasAccess) {
      return <NoAccessSupplierPage/>
    }
    
    return ( 
      <div className='container-detail'>
        <Header title={intl.get('requestOrders.header.title')} />
        <Loading isLoading={isFetching} />

        <div className='order-detail'>
          <div className='cards-taks-detail'>
            {orders && orders.map((v, i) => (
              <div className={`${typeNeed === 'S' ? 'heigth-card' : ''}`} key={i}>
                <Card
                  infoCard={'DETAIL'}
                  userCard={this.getTypeUserCard()}
                  dataCard={v}
                  typeCard={'SHOP_SUGGESTION'}
                  typeNeed={typeNeed}
                  refreshCardDetail={this.refreshCard}
                />
              </div>
            ))}
            {this.isUserSupplier() && <div className="notes-detail">
              <Textarea
                style={getTextAreaStyle()}
                label={intl.get('commons.supplierNotes')}
                name="observacaoFornecedor"
                value={this.state.observacaoFornecedor}
                onChange={this.changeValue}
                data-testid='textarea-note-supplier'
              />
              <div className="notes-detail-button">
                <Button
                  width='200px'
                  value={intl.get('commons.saveSupplierNotes')}
                  type='secondary'
                  onClick={() => this.salvarObservacoesFornecedor(idPedido)}
                />
              </div>
            </div>
            }
          </div>
          <Timeline
            idFamiliaRotuladaCentro={orders.length > 0 ? orders[0].IdFamiliaRotuladaCentro : 0}
            searchTimeLine={this.state.searchTimeLine}
            searchTimeLineFinished={this.searchTimeLineFinished}
            idPedido={idPedido}
            isPedidoVolume={typeNeed === 'V'}
            handleFeedback={this.showFeedback}
            refreshCardDetail={this.searchOrders}
          />
        </div>

        <SnackBar
          autoHideDuration={5000}
          message={messageFeedback}
          open={showFeedback}
          onRequestClose={this.hideFeedback}
        />
      </div>
    )
  }
}

OrdersVisionDetail.propTypes = {
  typeNeed: PropTypes.string,
  orderId: PropTypes.number
}

export default OrdersVisionDetail
