import React, { Component } from "react";
import PropTypes from "prop-types";
import Dialog from "material-ui/Dialog";
import { Button } from 'components/uikit-adapter/index';
import { Row, Col } from 'react-flexbox-grid'
import SnackBar from 'material-ui/Snackbar'
import Loading from 'components/center-loading'
import moment from 'moment'
import PedidosComposicao from './composition-orders-list'
import {
  obterComposicao,
  obterPedidosComposicao
} from "./composition-orders.service";

import StyledCompositionOrders from "./composition-orders.styled"
import intl from 'react-intl-universal'
import { formatDeliveryTime, showDeliveryTime } from "utils/delivery-time"
import { formatErrorMessage } from 'utils/handle-error'

class CompositionOrders extends Component {
  constructor() {
    super();

    this.state = {
      textMsg: '',
      showMsg: false,
      isFetching: false,
      open: false,
      composicao: {},
      pedidosComposicao: [],
    };
  }

  componentDidMount() {
    this.loadPage()
  }

  loadPage = () => {
    this.startFetching()
    this.getComposition()
    this.getOrders()
    this.stopFetching()
  }

  proccessError = (error) => {
    this.setState({
      showMsg: true,
      textMsg: formatErrorMessage(error)
    })
  }

  finalizeProccessError = () => {
    this.setState({
      showMsg: false,
      textMsg: ''
    })
  }

  startFetching() {
    this.setState({
      isFetching: true
    })
  }

  stopFetching() {
    this.setState({
      isFetching: false
    })
  }

  getComposition = async () => {
    try {

      const composicao = await obterComposicao(this.props.pedido.NumeroPedido)
      this.setState({ composicao })
    } catch (error) {
      this.proccessError(error)
    }
  }

  getOrders = async () => {
    try {
      const pedidosComposicao = await obterPedidosComposicao(this.props.pedido.NumeroPedido)
      this.setState({ open: true, pedidosComposicao })
    } catch (error) {
      this.proccessError(error)
    }
  }

  handleCloseModal = () => {
    this.setState({
      open: false,
    }, () => this.props.onClose())
  }

  handleConfirm = () => {
    this.props.handleConfirm();
    this.handleCloseModal();
  }

  render() {
    const { pedido, message } = this.props
    const { open, isFetching, composicao } = this.state
    const showCompositionDeliveryTime = () => showDeliveryTime(composicao.HoraEntrega) || showDeliveryTime(pedido.HoraEntrega)

    return (
      <div with="100%" height="100%">
        <Loading isLoading={isFetching} />

        <Dialog
          open={open}
          title={intl.get('transports.compoCharge.header.title')}
          contentStyle={{ width: "750px" }}
        >
          <StyledCompositionOrders>
            <label className='label-style'>{intl.get('commons.compositionData')}</label>
            <div className='container'>
              <Row>
                <Col xs={6}>
                  <span className='span'>
                    {`${intl.get('commons.code')}: `}
                    <strong className='content'>
                      {composicao.Id}
                    </strong>
                  </span>
                </Col>

                <Col xs={6}>
                  <span className='span'>
                    {`${intl.get('stocks.timeline.sapOrder')} `}
                    <strong className='content'>
                      {!pedido.NumeroPedidoSap ? '' : `${parseInt(pedido.NumeroPedidoSap)} - ${parseInt(pedido.NumeroItemPedidoSap)}`}
                    </strong>
                  </span>
                </Col>
              </Row>

              <Row>
                <Col xs={4}>
                  <span className='span'>
                    {`${intl.get('stocks.currentCollection')}`}
                    <strong className='content'>
                      {moment(composicao.DataColeta).format('L')}
                    </strong>
                  </span>
                </Col>

                <Col xs={4}>
                  <span className='span'>
                    {`${intl.get('stocks.currentDelivery')}`}
                    <strong className='content'>
                      {moment(composicao.DataEntrega).format('L')}
                    </strong>
                  </span>
                </Col>

                {showCompositionDeliveryTime() &&
                  <Col xs={4}>
                    <span className='span'>
                      {`${intl.get('stocks.currentDeliveryTime')}`}
                      <strong className='content'>
                        {
                          composicao.HoraEntrega ?
                            formatDeliveryTime(composicao.HoraEntrega)
                            :
                            formatDeliveryTime(pedido.HoraEntrega)
                        }
                      </strong>
                    </span>
                  </Col>
                }
              </Row>

            </div>

            <div className='list-pedidos-composicao'>
              <PedidosComposicao
                pedidos={this.state.pedidosComposicao}
                enableSelected={false}
              />
            </div>

            <p className="mensagem-modal-atencao">{message}</p>

            <div className="botoes-modal">
              <Button
                width="150px"
                value={intl.get('geral.modalConfirm.no')}
                onClick={this.handleCloseModal}
                disabled={this.state.isFetching}
                className="btn btn-no-modal"
              />
              <Button
                width="150px"
                type="primary"
                value={intl.get('geral.modalConfirm.yes')}
                onClick={this.handleConfirm}
                disabled={this.state.isFetching}
                className="btn margin-botao btn-confirm-modal"
              />
            </div>

            <SnackBar
              message={this.state.textMsg}
              open={this.state.showMsg}
              autoHideDuration={4000}
              onRequestClose={this.finalizeProccessError}
              style={{ width: '100%', textAlign: 'center', display: 'block' }}
            />
          </StyledCompositionOrders>
        </Dialog>
      </div>
    );
  }
}

CompositionOrders.propTypes = {
  pedido: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired
}

export default CompositionOrders
