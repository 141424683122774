import React from 'react'
import PropTypes from 'prop-types'
import CircularProgress from 'material-ui/CircularProgress'
import StyledCenterLoading from './styledCenterLoading'

const isCrolled = () => {
  const containerMain = document.querySelector('.container-main')
  // 125 é a altura do header
  return containerMain && containerMain.scrollTop > 125
}

const Loading = ({ isLoading, fullHeight = null, fullHeightParent = false }) => (
  isLoading
    ? <StyledCenterLoading fullHeight={isCrolled() || fullHeight} fullHeightParent={fullHeightParent}>
      <CircularProgress size={80} thickness={5} />
    </StyledCenterLoading>
    : null
)

Loading.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  fullHeight: PropTypes.bool,
}

export default Loading
