import { Component } from 'react'
import { Row, Col } from 'react-flexbox-grid'
import { withRouter } from 'react-router-dom'

import {
  Input
} from '@hbsis.uikit/react'

import PropTypes from 'prop-types'
import moment from 'moment'

import SuggestionsListHeader from './../suggestions-list-header'
import StyledSuggestionsListItem from './../suggestions-list-item/suggestions-list-item.styled'
import { ShelfLifeIcon, DiscontinuedIcon } from 'components/icons/icn-index.icon'
import intl from 'react-intl-universal'
import { formatNumber } from 'utils/format'

class ShelfLifeListItem extends Component {
  constructor(props) {
    super(props)

    this.state = {
      itemsExpanded: false,
      checked: false,
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      JSON.stringify(this.props) !== JSON.stringify(nextProps) ||
      JSON.stringify(this.state) !== JSON.stringify(nextState)
    )
  }

  UNSAFE_componentWillReceiveProps(updateProps) {
    if (updateProps.expanded !== undefined && updateProps.expanded !== this.props.expanded) {
      this.setState({
        itemsExpanded: updateProps.expanded,
      })
    }

    if (updateProps.checked !== undefined && updateProps.checked !== this.props.checked) {
      this.setState({
        checked: updateProps.checked,
      })
    }

    if (updateProps.unCheckItem !== this.props.unCheckItem && updateProps.unCheckItem) {
      this.setState({
        checked: false,
      })
    }
  }

  checkItem = (event) => {
    this.setState(prevState => ({
      checked: !prevState.checked
    }), () => {
      this.props.checkShelflifeItem(this.props.item, this.state.checked)
    })
  }

  expandItems = (eventTarget) => {
    this.setState(prevState => ({
      itemsExpanded: !prevState.itemsExpanded
    }), () => this.props.expandItem(
      eventTarget,
      this.props.item,
      this.state.itemsExpanded,
      this.props.isShelfLife,
      this.props.itemIndex,
    ))
  }

  showShelfLifeImage = (item) => item.TransferenciaPorVencimento;

  showDiscontinuedImage = (item) => item.TransferenciaPorDescontinuacao;

  render() {
    const { itemsExpanded, checked } = this.state
    const { item, highlighted, itemDimmed } = this.props

    return (
      <StyledSuggestionsListItem className={`${itemsExpanded ? 'item-expanded' : ''}`}>
        <SuggestionsListHeader
          isItem
          hasIcon
          isOrder={false}
          itemsExpanded={itemsExpanded}
          checked={checked}
          idCheckbox={item.Id}
          checkItem={this.checkItem}
          highlighted={highlighted}
          itemDimmed={itemDimmed}
          expandAllItems={this.expandItems}
        >
          <Row middle="xs">
            <Col xs={2}>
              <div className="header-item" title={item.Origem}>{item.Origem}</div>
            </Col>
            <Col xs={3}>
              <div className="header-item" title={item.Label}>
                {this.showShelfLifeImage(item) && (
                  <div title="Risco de Vencimento" className="etb-image-alert-suggestion-list">
                    <ShelfLifeIcon width='15px' height='15px' />
                  </div>
                )}
                {this.showDiscontinuedImage(item) && (
                  <div title="Material Descontinuado" className="etb-image-alert-suggestion-list">
                    <DiscontinuedIcon width='15px' height='15px' />
                  </div>
                )}
                {item.Label}
              </div>
            </Col>
            <Col xs={2}>
              <div className="header-item">{formatNumber(item.QuantidadeTransferencia)}</div>
            </Col>
            <Col xs={2}>
              <div className="header-item">{item.DataVencimento ? moment(item.DataVencimento).format("L") : ' - '}</div>
            </Col>
            <Col xs={3}>
              <div className="header-item">{item.DataDescontinuado ? moment(item.DataDescontinuado).format("L") : ' - '}</div>
            </Col>
          </Row>
        </SuggestionsListHeader>
        <div className="table-list-item-body" style={{ display: itemsExpanded ? "block" : "none" }}>
          <Row>
            <Col xs={12}>
              <Input
                className='input'
                label={intl.get('stocks.itemBody.material')}
                htmlType="text"
                value={item.Material}
                disabled
              />
            </Col>
          </Row>
          <Row>
            <Col xs={4}>
              <Input
                className='input'
                label={intl.get('stocks.itemBody.origin')}
                htmlType="text"
                value={item.Origem}
                disabled
              />
            </Col>
            <Col xs={4}>
              <Input
                className='input'
                label={intl.get('stocks.itemBody.lots')}
                htmlType="text"
                value={item.Label}
                disabled
              />
            </Col>
            <Col xs={4}>
              <Input
                className='input'
                label={intl.get('stocks.itemBody.quantity')}
                htmlType="text"
                value={formatNumber(item.QuantidadeTransferencia)}
                disabled
              />
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <Input
                className='input'
                label={intl.get('stocks.itemBody.expirationDate')}
                htmlType="text"
                value={item.DataVencimento ? moment(item.DataVencimento).format("L") : ''}
                disabled
              />
            </Col>
            <Col xs={6}>
              <Input
                className='input'
                label={intl.get('stocks.itemBody.discontinuedDate')}
                htmlType="text"
                value={item.DataDescontinuado ? moment(item.DataDescontinuado).format("L") : ''}
                disabled
              />
            </Col>
          </Row>
        </div>
      </StyledSuggestionsListItem>
    )
  }
}

ShelfLifeListItem.propTypes = {
  item: PropTypes.object.isRequired,
  checkShelflifeItem: PropTypes.func.isRequired,
  expandItem: PropTypes.func.isRequired
}

export default withRouter(ShelfLifeListItem)
