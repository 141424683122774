import { Component } from 'react'
import SnackBar from 'material-ui/Snackbar'
import GeneralIcon from 'material-ui/svg-icons/action/view-module'
import Fetch from 'utils/fetch'
import Filtro from 'components/filters/filter-general-material'
import Loading from 'components/center-loading'
import HeaderIcon from 'components/header-icon'
import ButtonNewItem from 'components/button-new-item'
import DeleteMaterial from 'components/delete-dialog'
import MaterialList from './material-business-unit-list'
import MaterialForm from './material-business-unit-form'
import StorePersist from 'utils/store-persist'
import { Layout } from '@hbsis.uikit/react'
import { Button } from 'components/uikit-adapter'
import IconEmpty from 'images/iconEmpty.icon'

import intl from 'react-intl-universal';
import { obterTodosFornecedoresEmMaterial } from './material-master-data.service';
import './material-business-unit.css'
import { formatErrorMessage } from 'utils/handle-error';
import { IconFilterActive } from 'components/icons/icn-filter-active.icon'
import { IconFilterInactive } from 'components/icons/icn-filter-inactive.icon'
import { generateQueryParams } from 'utils/custom-functions'

class Material extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isFetching: false,
      materialBusinessUnits: [],
      total: 0,
      currentPage: 0,
      openForm: false,
      openEditMode: false,
      openDelete: false,
      idSelected: 0,
      horizonteProgramacao: 0,
      possuiQuebraEmEntregas: true,
      showFeedback: false,
      messageFeedBack: '',
      qtRegsPerPage: 50,
      showMsg: false,
      textMsg: '',
      labeledFamily: '',
      center: '',
      showFilter: false,
      filterOptions: null,
      filters: {
        IdFornecedor: null,
        IdFamiliaRotuladaCentro: ''
      },
    }
  }

  async UNSAFE_componentWillMount() {
    const filters = StorePersist.getValuesJSON('filtersFamiliaRotuladaCentroMaterial');

    if (filters) {
      this.setState({ filters })
    }

    this.searchDetails()

  }

  searchFilters = (filters) => {
    StorePersist.setValuesJSON('filtersFamiliaRotuladaCentroMaterial', filters);
    this.showFilter()
    this.setState({
      currentPage: 0,
      filters: filters
    }, () => this.buscarMateriais(this.state.currentPage))
  }

  showFilter = async () => {
    const { filterOptions, showFilter } = this.state;
    if (!filterOptions) await this.loadFilters();
    this.setState({
      showFilter: !showFilter,
    })
  }

  loadFilters = async () => {
    const { filters } = this.state;
    this.startFetching();
    const suppliers = await obterTodosFornecedoresEmMaterial(filters.IdFamiliaRotuladaCentro);
    this.setState({
      filterOptions: {
        suppliers,
      }
    })
    this.stopFetching();
  }

  verifyFilter = () => {
    return this.state.filters.IdFornecedor > 0
  }

  showFeedback = (message) => {
    this.setState({
      showFeedback: true,
      messageFeedBack: message
    })
  }

  closeFeedback = () => {
    this.setState({
      showFeedback: false
    })
  }

  openForm = () => {
    this.setState({
      openForm: true
    })
  }

  openNewForm = () => {
    this.setState({
      openEditMode: false
    }, () => this.openForm())
  }

  openEditForm = (row) => {
    const { materialBusinessUnits } = this.state;
    const selected = materialBusinessUnits[row]
    this.setState({
      openEditMode: true,
      idSelected: selected.Id,
      horizonteProgramacao: selected.ProgrammingHorizon,
      possuiQuebraEmEntregas: selected.HasBreakOnDeliveries
    }, () => this.openForm())
  }

  closeForm = () => {
    this.setState({
      openForm: false
    })
  }

  openDelete = (id) => {
    this.setState({
      openDelete: true,
      idSelected: id
    })
  }

  closeDelete = () => {
    this.setState({
      openDelete: false
    })
  }

  startFetching = () => {
    this.setState({
      isFetching: true
    })
  }

  stopFetching = () => {
    this.setState({
      isFetching: false
    })
  }

  refreshSearch = () => {
    this.closeForm()
    this.buscarMateriais(this.state.currentPage)
  }

  proccessData = (data) => {
    this.setState({
      materialBusinessUnits: data.Data,
      total: data.TotalRecordCount
    })
  }

  proccessDataDetails = (data) => {
    this.setState({
      labeledFamily: data.LabeledFamily,
      center: data.Destination,
      horizonteProgramacao: data.ProgrammingHorizon
    })
  }

  proccessErro = (exception) => {
    try {
      const erro = formatErrorMessage(exception)
      this.showFeedback(erro)
    } catch (e) {
      this.showFeedback(intl.get('master-data.feedback.serverErrorSavingMaterial'))
    }
  }

  searchDetails = () => {
    this.startFetching();
    const { filters } = this.state;
    if (filters?.IdFamiliaRotuladaCentro) {
      Fetch.get(`/labeled-family-centers/${filters.IdFamiliaRotuladaCentro}`)
        .then((response) => this.proccessDataDetails(response.data))
        .catch((e) => console.log(e))
        .finally(() => this.stopFetching());
    }
  }

  buscarMateriais = (page) => {
    this.startFetching();

    const { qtRegsPerPage, filters } = this.state;
    const nextPage = page == undefined ? this.state.currentPage : page;
    const _filters = { 
      LabeledFamilyCenterId: filters.IdFamiliaRotuladaCentro,
      SupplierId: filters.IdFornecedor,
      _page: nextPage, 
      _size: qtRegsPerPage 
    };
    Fetch.get(`/labeled-family-center-materials:paginated?${generateQueryParams(_filters)}`)
      .then((response) => this.proccessData(response.data))
      .then(() => this.searchDetails())
      .then(() => this.setState({ currentPage: nextPage }))
      .catch((e) => console.log(e))
      .finally(() => this.stopFetching());
  }


  deleteMaterial = () => {
    const { idSelected } = this.state
    this.startFetching()
    Fetch.delete(`/labeled-family-center-materials/${idSelected}`)
      .then(this.closeDelete)
      .then(() => this.buscarMateriais(this.state.currentPage))
      .then(() => this.showFeedback(intl.get('master-data.feedback.recordDeletedSuccessfully')))
      .catch((e) => this.proccessErro(e))
      .finally(() => {
        this.closeDelete()
        this.stopFetching()
      });
  }

  redirect = () => {
    this.props.history.push(`/master-data/general`)
  }

  render() {
    const {
      openForm,
      openEditMode,
      openDelete,
      idSelected,
      horizonteProgramacao,
      materialBusinessUnits,
      total,
      showFeedback,
      messageFeedBack,
      isFetching,
      labeledFamily,
      center,
      showFilter,
      filters,
      filterOptions,
    } = this.state

    return (
      <div className='material-business-unit-container'>
        <Loading isLoading={isFetching} />
        <div className="material-business-unit-header">
          <HeaderIcon
            title={intl.get('master-data.general.table.actions.editMaterial.header.title')}
            subtitle={
              intl.get('master-data.general.table.actions.editMaterial.header.subtitle')
              + labeledFamily + ' - ' + intl.get('master-data.general-configuration.center') + ':' + center
            }
            icon={<GeneralIcon style={{ width: 45, height: 45 }} color='var(--color-brand-500)' />}
          >
            <div className="buttons-header-container">
              <div title={intl.get('master-data.general.table.actions.backToLabeledFamily')}>
                <Button
                  type="default"
                  value={intl.get('master-data.general.actions.back')}
                  className={"button-return"}
                  onClick={() => { this.redirect() }}
                />
              </div>
              <div title={intl.get('master-data.general.table.actions.newLabeledFamilyCenterMaterial')}>
                <ButtonNewItem
                  className="general-button"
                  onClick={this.openNewForm}
                />
              </div>
              <div title={intl.get('master-data.general.table.actions.filterLabeledFamilyCenterMaterial')}>
                <Button
                  onClick={this.showFilter}
                  type='default'
                  buttonText={intl.get('filters.filter')}
                  buttonIcon={this.verifyFilter() ? <IconFilterActive /> : <IconFilterInactive />}
                  className={"button-filter"}
                />
              </div>
            </div>
          </HeaderIcon>
        </div>

        <MaterialList
          data={materialBusinessUnits}
          countData={total}
          refreshSearch={this.buscarMateriais}
          handleEdit={this.openEditForm}
          handleDelete={this.openDelete}
          qtRegsPerPage={this.state.qtRegsPerPage}
        />

        {openForm &&
          <MaterialForm
            idEdit={idSelected}
            horizonteProgramacao={horizonteProgramacao}
            open={openForm}
            editMode={openEditMode}
            handleClose={this.closeForm}
            handleRefresh={this.refreshSearch}
            handleFeedback={this.showFeedback}
            idLabeledFamilyCenter={filters.IdFamiliaRotuladaCentro}
          />
        }

        <DeleteMaterial
          open={openDelete}
          title={intl.get('master-data.general.materials.title')}
          handleClose={this.closeDelete}
          warningMessage={intl.get('master-data.general.materials.description')}
          handleConfirm={this.deleteMaterial}
        />

        <Layout.Sidebar width="450px"
          visible={showFilter}
          triggerClose={this.showFilter}
          background="var(--color-contrast-white)"
          offsetTop="96px"
          side="right"
          block
          float
          icon={<IconEmpty />}
        >
          {showFilter &&
            <Filtro
              initialData={filters}
              handleClose={this.searchFilters}
              showFilter={showFilter}
              filterOptions={filterOptions}
            />
          }
        </Layout.Sidebar>

        <SnackBar
          message={messageFeedBack}
          open={showFeedback}
          autoHideDuration={3000}
          onRequestClose={this.closeFeedback}
        />
      </div>
    )
  }
}

export default Material
