import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ContraPropostaFornecedor from './../contraproposta-fornecedor'
import Fetch from 'utils/fetch'
import { Button } from 'components/uikit-adapter/index'
import SessionUser from 'utils/user-storage'
import Loading from 'components/center-loading'
import intl from 'react-intl-universal'
import './request-accept.css'

class AceiteFornecedor extends Component {
  constructor() {
    super()

    this.state = {
      showCounterOffer: false,
      isLoading: false
    }

    this.showCounterOffer = this.showCounterOffer.bind(this)
    this.hideCounterOffer = this.hideCounterOffer.bind(this)
    this.save = this.save.bind(this)
  }

  showCounterOffer() {
    this.setState({
      showCounterOffer: true
    })
  }

  hideCounterOffer() {
    this.setState({
      showCounterOffer: false
    })
    this.props.handleUpdateActionType('')
  }

  save() {
    const {
      idPedido,
      idUser,
      quantidade,
      dataEntregaPrevista,
      dataColetaPrevista,
      horaEntrega,
      handleFeedback,
      idFornecedor
    } = this.props;

    const user = SessionUser.get();
    const usuarioAceite = user.TipoUsuario === 1 ? 'comprador' : 'fornecedor';

    this.startLoading();
    Fetch.post(`/timeline/aceite/${usuarioAceite}`, {
      IdPedido: idPedido,
      Quantidade: quantidade,
      UnidadeMedida: '',
      DataColeta: dataColetaPrevista,
      DataEntrega: dataEntregaPrevista,
      horaEntrega: horaEntrega,
      IdUsuario: idUser,
      IdFornecedor: idFornecedor

    })
      .then(() => handleFeedback(intl.get('stocks.timeline.demandAccepted')))
      .catch((e) => handleFeedback(e.response.data.Message))
      .finally(() => {
        this.stopLoading();
        this.props.refreshTimeLine()
      })
  }

  startLoading() {
    this.setState({
      isLoading: true
    })
  }

  stopLoading() {
    this.setState({
      isLoading: false
    })
  }

  render() {
    return (
      <div>
        <Loading isLoading={this.state.isLoading} />
        {this.state.showCounterOffer
          ? <ContraPropostaFornecedor
            idPedido={this.props.idPedido}
            isEmergencial={false}
            handleCancel={this.hideCounterOffer}
            {...this.props}
          />
          : <div className='event-action'>
              <Button
                value={intl.get('stocks.timeline.makeCounterproposal')}
                onClick={this.showCounterOffer}
                type="secondary"
                className="button button-counter-proposal"
                disabled={this.props.disabledCounterProposal}
              />
              <Button
                value={intl.get('stocks.timeline.acceptDemand')}
                onClick={this.save}
                type="primary"
                className="button button-accept"
                disabled={this.props.disabledAcceptDemand}
              />
          </div>
        }
      </div>
    )
  }
}

AceiteFornecedor.propTypes = {
  idPedido: PropTypes.string.isRequired,
  idUser: PropTypes.number.isRequired,
  quantidade: PropTypes.number.isRequired,
  dataEntregaPrevista: PropTypes.string.isRequired,
  dataColetaPrevista: PropTypes.string.isRequired,
  refreshTimeLine: PropTypes.func.isRequired,
  handleFeedback: PropTypes.func.isRequired,
  handleUpdateActionType: PropTypes.func.isRequired
}

export default AceiteFornecedor
