import { ClbDrawer, ClbIcon } from "@celebration/design-system-react";
import moment from "moment";
import { useEffect, useState } from "react";
import * as S from "./styled";
import intl from "react-intl-universal";
import { Button } from "@material-ui/core";
import { ContainerButton } from "../navbar-user-actions/styled";
import { useToastContext } from "hooks/useToastContext";
import { formatErrorMessage } from "utils/handle-error";
import { withRouter } from "react-router-dom";
import { getNewNotifications, getNotificationsNav, notificationsMarkAsRead } from "services/notifications.service";
import { getActionNotificationUrl } from "utils/notifications";
import { LabelButton } from "components/label-button";

const NavbarNotifications = ({ history }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [newNotifications, setNewNotifications] = useState(0);
  const [dataList, setDataList] = useState([]);
  const { openToast } = useToastContext();

  const initOnOpen = async () => {
    try {
      const _data = await getNotificationsNav();
      const _newNotifications = await getNewNotifications();
      setNewNotifications(_newNotifications);
      setDataList(_data);
    }
    catch (e) {
      console.error(e);
    }
  };

  const markAllAsRead = async () => {
    const idsNotRead = dataList.filter((x) => !x.IsRead).map((x) => x.Id);
    const bodyRequest = {
      notificationIds: idsNotRead,
    };

    if (idsNotRead.length < 1) {
      openToast(
        intl.get("notifications.allNotificationsAlreadyRead"),
        "warning"
      );
      return;
    }

    try {
      await notificationsMarkAsRead(bodyRequest);
      setNewNotifications((prevState) => prevState - idsNotRead.length);
      setIsOpen(false);

      openToast(intl.get("notifications.successOnReadNotifications"), "positive", 2000);
    } catch (e) {
      openToast(formatErrorMessage(e), "negative");
    }
  };

  const markAsRead = async (ids) => {
    const bodyRequest = { notificationIds: ids };
    try {
      await notificationsMarkAsRead(bodyRequest);
      setNewNotifications((prevState) => prevState - 1);
    } catch (e) {
      openToast(formatErrorMessage(e), "negative");
    }
  };

  const redirectToNotificationPage = () => {
    setIsOpen(false);
    history.push("/notification-center");
  };

  const init = async (isFirstTime) => {
    try {
      let timeToCheck = null;

      if (!isFirstTime) {
        timeToCheck = 10;
      }

      const _newNotifications = await getNewNotifications(timeToCheck);
      setNewNotifications((prevState) => prevState + _newNotifications);

      const minutes = 10;
      setTimeout(() => { init(false) }, minutes * 60000);
    }
    catch (e) {
      console.error(e);
    }
  };

  const clickNotificationItem = (data) => {
    const route = getActionNotificationUrl(data.MetaData);
    setIsOpen(false);
    if (!data.IsRead) {
      const ids = [data.Id];
      markAsRead(ids);
    }

    if (route == null) {
      return;
    }

    location.replace(route);
  };

  useEffect(() => {
    if (isOpen) {
      initOnOpen();
    }
  }, [isOpen]);

  useEffect(() => {
    init(true);
  }, []);

  return (
    <>
      <ContainerButton onClick={() => setIsOpen(!isOpen)} data-testid="notification-btn">
        <Button
          title={intl.get("notifications.notifications")}
        >
          <ClbIcon icon="Notification" size="sm" type="white" />
        </Button>
        <S.NewNotificationsNumber hasNewNotification={newNotifications}>
          {!!newNotifications && newNotifications}
        </S.NewNotificationsNumber>
      </ContainerButton>

      <ClbDrawer
        style={{ position: 'relative', zIndex: '9999' }}
        title={intl.get("notifications.notifications")}
        opened={isOpen}
        onClbClose={() => setIsOpen(false)}
        buttonsList={[
          {
            id: "btn-close-drawer",
            label: intl.get("master-data.general.actions.back"),
            title: intl.get("master-data.general.actions.back"),
            icon: "ChevronLeft",
            iconPosition: "left",
            dataTestid: "btn-close-drawer",
            callback: () => setIsOpen(false),
          },
          {
            id: "btn-go-notification-page",
            label: intl.get("notifications.notificationCenter"),
            title: intl.get("notifications.notificationCenter"),
            callback: () => redirectToNotificationPage(),
          },
        ]}
      >
        <LabelButton
          style={{ padding: '20px 0px' }}
          onClick={markAllAsRead}
        >
          {intl.get("notifications.markAllNotificationsAsRead")}
        </LabelButton>
        <S.ListHeader>
          {dataList.map((x) => (
            <S.ListItem key={x.Id} isRead={x.IsRead} onClick={() => clickNotificationItem(x)}>
              <S.Ball isRead={x.IsRead} />
              <S.Description>{x.Description.split('\\n').map(x => <div key={x}>{x}</div>)}</S.Description>
              <S.DateItem>{moment(x.CreatedDate).format("[DM] LT")}</S.DateItem>
            </S.ListItem>
          ))}
        </S.ListHeader>
      </ClbDrawer >
    </>
  );
};

const NavbarNotificationsWithRouter = withRouter(NavbarNotifications);
export { NavbarNotificationsWithRouter as NavbarNotifications };
