import React from 'react'
import intl from 'react-intl-universal'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'

const StyledTableCell = withStyles(() => ({
  head: {
    borderBottom: 'none',
    color: "#A2A2A2",
    fontWeight: 'var(--font-weight-semibold)',
    fontSize: 12,
    borderRight: '1px solid var(--color-contrast-white)',
    lineHeight: '10px'
  },
  body: {
    fontSize: 14,
    color: '#313131',
    fontWeight: 'var(--font-weight-regular)',
    borderRight: '1px solid #EDEDED',
    lineHeight: '14px',
    padding: '10px'
  },
  root: {
    fontFamily: 'ProximaNova',
    padding: '8px'
  }
}))(TableCell)

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    padding: '8px'
  },
}))(TableRow)

const useStyles = makeStyles({
  root: {
    width: '100%',
    boxShadow: 'none',
  },
  container: {
    maxHeight: 215
  },
  head: {
    fontWeight: 'var(--font-weight-semibold)',
    color: '#A2A2A2'
  },
  bold: {
    fontWeight: 'bold',
    textAlign: 'center'
  }
})

export const OrdersCancelDataTable = ({ data }) => {
  const classes = useStyles()

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader>
          <TableHead className={classes.head}>
            <TableRow>
              <StyledTableCell>{intl.get('commons.orderCode')}</StyledTableCell>
              <StyledTableCell>{intl.get('commons.source')}</StyledTableCell>
              <StyledTableCell>{intl.get('commons.destiny')}</StyledTableCell>
              <StyledTableCell>{intl.get('commons.material')}</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <StyledTableRow key={row.Id}>
                <StyledTableCell>{row.Id}</StyledTableCell>
                <StyledTableCell>{row.SupplierName}</StyledTableCell>
                <StyledTableCell>{`${row.BusinessUnitPlant} - ${row.BusinessUnitName}`}</StyledTableCell>
                <StyledTableCell>{row.MaterialName}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}
