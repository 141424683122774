import { Component } from 'react'
import PropTypes from 'prop-types'
import Fetch from 'utils/fetch'
import { Button, Input, SelectBox, Dialog, Toggle } from 'components/uikit-adapter/index'
import Loading from 'components/center-loading'
import intl from 'react-intl-universal'

import './supplier-form.css'
import { formatErrorMessage } from 'utils/handle-error';
import { getGeneralSettingValue } from 'services/general-settings/general-settings'
import { getSupplierCodeFormated } from 'utils/supplier-code-format';
import { GeneralSettingParameter } from 'models/generalSetting/generalSetting'

class SupplierForm extends Component {
  constructor() {
    super()

    this.state = {
      isFetching: false,
      supplier: {
        Supplier: {
          SupplierNickName: '',
          AutoAcceptLoadComposition: false
        },
        User: {}
      },
      suppliers: [],
      user: {},
      users: []
    }
  }

  componentDidMount() {
    this.loadUsers()

    const editMode = this.props.editMode
    if (editMode) {
      this.searchSupplierGoupById()
    } else {
      this.loadSuppliers()
    }
  }

  startFetching = () => {
    this.setState({
      isFetching: true
    })
  }

  stopFetching = () => {
    this.setState({
      isFetching: false
    })
  }

  loadSuppliers = () => {
    this.startFetching()
    Fetch.get(`/fornecedor/buscaFornecedores`)
      .then((response) => { this.setState({ suppliers: response.data }) })
      .finally(() => this.stopFetching())
  }

  loadUsers = () => {
    this.startFetching()
    Fetch.get(`/usuario/buscaUsuariosFornecedor`)
      .then((response) => {
        this.setState({ users: response.data })
      })
      .finally(() => this.stopFetching())
  }

  searchSupplierGoupById = () => {
    const id = this.props.idEdit
    this.startFetching()
    Fetch.get(`/supplier-users/${id}`)
      .then((response) => this.proccessData(response.data))
      .catch((e) => this.proccessErro(e))
      .finally(() => this.stopFetching())
  }

  proccessData = (data) => {
    const { Id, SupplierId, UserId, UserName, SupplierCode,  SupplierCompanyName, SupplierCnpj, SupplierNickName, SupplierCodeMdg, AutoAcceptLoadComposition, HasAuroraBusinessUnities } = data

    let supplierCodeMdg = SupplierCodeMdg;

    if (!SupplierCodeMdg) {
      supplierCodeMdg = '';
    }

    this.setState({
      supplier: {
        Id: Id,
        Supplier: {
          Id: SupplierId,
          SupplierNickName: SupplierNickName,
          SupplierCode: SupplierCode,
          SupplierCnpj: SupplierCnpj,
          SupplierCompanyName: SupplierCompanyName,
          SupplierCodeFormated: getSupplierCodeFormated(supplierCodeMdg, SupplierCode, HasAuroraBusinessUnities),
          AutoAcceptLoadComposition: AutoAcceptLoadComposition
        },
        User: {
          Id: UserId,
          Name: UserName
        }
      }
    })
  }

  proccessErro = (exception) => {
    try {
      const erro = formatErrorMessage(exception)
      this.props.handleFeedback(erro)
    } catch (e) {
      this.props.handleFeedback(intl.get('feedbacks.serverError'))
    }
  }

  changeValueNickname = (event) => {
    const value = event.currentTarget.value

    this.setState(prevState => ({
      supplier: {
        ...prevState.supplier,
        Supplier: {
          ...prevState.supplier.Supplier,
          SupplierNickName: value
        }
      }
    }))
  }

  changeAutoAcceptLoadComposition = (prop, value) => {
    this.setState(prevState => ({
      supplier: {
        ...prevState.supplier,
        Supplier: {
          ...prevState.supplier.Supplier,
          [prop]: value
        }
      }
    }))
  }

  save = () => {
    const model = this.state.supplier

    const up = {
      Id: model.Id ? model.Id : 0,
      UserId: model.User.Id,
      SupplierId: model.Supplier.Id,
      SupplierNickName: model.Supplier.SupplierNickName,
      AutoAcceptLoadComposition: model.Supplier.AutoAcceptLoadComposition
    };

    this.startFetching()
    Fetch.post(`/supplier-users`, up)
      .then(this.props.handleRefresh)
      .then(() => this.props.handleFeedback(intl.get('feedbacks.savedRegister')))
      .catch((e) => this.proccessErro(e))
      .finally(() => this.stopFetching())
  }

  updateUserSelectValue = (prop, value) => {
    this.setState(prevState => ({
      supplier: {
        ...prevState.supplier,
        User: {
          ...prevState.supplier.User,
          [prop]: value
        }
      }
    }))
  }

  updateSupplierSelectValue = (prop, value) => {
    this.setState(prevState => ({
      supplier: {
        ...prevState.supplier,
        Supplier: {
          ...prevState.supplier.Supplier,
          [prop]: value
        }
      }
    }))
  }

  render() {
    const { supplier, suppliers, users, isFetching } = this.state
    const { open, editMode, profileAdminOrMasterData } = this.props

    return (
      <div>
        <Loading isLoading={isFetching} />
        <Dialog
          title={editMode ? intl.get('master-data.suppliers.ableSupplier.actions.editSupplier') : intl.get('master-data.suppliers.ableSupplier.actions.newSupplier')}
          contentStyle={{ width: '600px' }}
          open={open}
        >

          <div className='line-1-columns'>
            <SelectBox
              required
              name='User'
              label={intl.get('master-data.suppliers.ableSupplier.actions.supplierGroupName')}
              placeholder={intl.get('master-data.suppliers.ableSupplier.actions.supplierGroupName')}
              valueKey='Id'
              labelKey='Nome'
              value={supplier.User.Id}
              options={users}
              onChange={value => this.updateUserSelectValue('Id', value.Id)}
              disabled={!profileAdminOrMasterData}
              searchable
              openOnFocus
            />
          </div>

          <div className='line-1-columns option-item'>
            {editMode &&
              <Input
                className="input"
                disabled
                label={intl.get('commons.supplier')}
                value={`${supplier.Supplier.SupplierCodeFormated} - ${supplier.Supplier.SupplierCompanyName} - ${supplier.Supplier.SupplierCnpj}`}
              />
            }
            {
              !editMode &&
              <SelectBox
                required
                name='Supplier'
                label={intl.get('commons.supplier')}
                placeholder={intl.get('commons.supplier')}
                valueKey='Id'
                labelKey='Descricao'
                value={supplier.Supplier.Id}
                options={suppliers}
                onChange={value => this.updateSupplierSelectValue('Id', value.Id)}
                disabled={!profileAdminOrMasterData}
                searchable
                openOnFocus
              />
            }
          </div>
          <div className='line-1-columns'>
            <Input
              className="input"
              required
              name='SupplierNickName'
              label={intl.get('commons.factory')}
              value={supplier.Supplier.SupplierNickName}
              onChange={this.changeValueNickname}
              disabled={!profileAdminOrMasterData}
            />
          </div>
          <div className='line-1-columns'>
            {editMode &&
              getGeneralSettingValue(GeneralSettingParameter.LoadCompositionNewFlow) &&
              profileAdminOrMasterData &&
              <Toggle
                className='toggle-accept-load-comp'
                name={"AutoAcceptLoadComposition"}
                label={intl.get('master-data.general-configuration.acceptChargeCompositionAutomatic')}
                value={supplier.Supplier.AutoAcceptLoadComposition}
                onChange={value => this.changeAutoAcceptLoadComposition("AutoAcceptLoadComposition", value.checked)}
                thumbSwitchedStyle={{ backgroundColor: 'var(--color-action-default)' }}
                trackSwitchedStyle={{ backgroundColor: 'var(--color-neutral-200)' }}
                labelStyle={{ color: 'var(--color-contrast-brand)' }}
                labelPosition="right"
              />
            }
          </div>
          <div className='footer-dialog-buttons'>
            <Button
              type="default"
              value={intl.get('geral.buttonsDefault.cancel')}
              onClick={() => { this.props.handleClose() }}
              className="button"
            />
            {profileAdminOrMasterData &&
              <Button
                type="primary"
                value={intl.get('geral.buttonsDefault.save')}
                className="button"
                onClick={this.save}
              />
            }
          </div>
        </Dialog>
      </div>
    )
  }
}

SupplierForm.propTypes = {
  idEdit: PropTypes.number,
  open: PropTypes.bool.isRequired,
  handleRefresh: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  profileAdminOrMasterData: PropTypes.func.isRequired,
}

export default SupplierForm
