import React, { Component } from 'react';
import Dialog from 'material-ui/Dialog/Dialog';
import { SelectBox, Button } from "components/uikit-adapter/index"
import { Row, Col } from 'react-flexbox-grid';
import intl from 'react-intl-universal';

import StyledPedidoEmOver from './pedido-em-over.styled.js';

class ModalPedidoEmOver extends Component {
  constructor(props) {
    super(props);

    this.state = {
      justificativasPedidoEmOver: {
        Key: 0,
        Value: ''
      }
    }
  }

  updateSelectValue = (prop, value) => {
    this.setState({
      justificativasPedidoEmOver: {
        [prop]: value
      }
    })
  }

  render() {
    const { listaJustificativasPedidoEmOver } = this.props
    const { justificativasPedidoEmOver } = this.state
    return (
      <Dialog
        title={intl.get('stocks.timeline.overMaximumStockOrder')}
        open={this.props.showModalPedidoEmOver}
        contentStyle={{ maxWidth: '600px' }}
        actionsContainerClassName="container-action-buttons-pedido-over"
        autoScrollBodyContent={false}
      >
        <StyledPedidoEmOver>
          <div className='baseModal'>
            <div className='alertMessage'>
              {intl.get(`modalPedidoEmOver.messageWarning`)}
            </div>
            <Row className='labelVision'>
              <Col xs={12}>
                <SelectBox
                  autosize={true}
                  required
                  name='JustificativasPedidoEmOver'
                  label={intl.get('stocks.timeline.justificationOrderOverMaximumStock')}
                  placeholder={intl.get('stocks.timeline.justificationOrderOverMaximumStock')}
                  value={justificativasPedidoEmOver.JustificativasPedidoEmOverOption}
                  options={listaJustificativasPedidoEmOver}
                  onChange={value => this.updateSelectValue('JustificativasPedidoEmOverOption', value.Key)}
                  valueKey='Key'
                  labelKey='Value'
                  searchable
                  openOnFocus
                />
              </Col>
            </Row>
          </div>
          <Row className="container-action-buttons-pedido-over">
            <Col>
              <Button
                type="primary"
                htmlType="button"
                className="button"
                value={intl.get('geral.buttonsDefault.cancel')}
                onClick={this.props.handleShowModalPedidoEmOver}
              />
            </Col>
            <Col>
              <Button
                type='danger'
                htmlType="button"
                className="button"
                value={intl.get('geral.buttonsDefault.save')}
                onClick={() => this.props.pedidoEmOverAceito(this.state.justificativasPedidoEmOver)}
              />
            </Col>
          </Row>
        </StyledPedidoEmOver>
      </Dialog>
    );
  }
}

export default ModalPedidoEmOver;
