import React from 'react'
import Dialog from 'material-ui/Dialog'
import intl from 'react-intl-universal'
import PedidosForm from './pedidos-form'
import { useGlobalContext } from 'hooks/useGlobalContext'

const ModalConfiguration = ({ open, handleClose }) => {

  const { showFeedback } = useGlobalContext()

  return (
    <>
      <Dialog
        title={intl.get('master-data.menus.configuration')}
        contentStyle={{ width: '600px' }}
        open={open}
        onRequestClose={handleClose}
        autoScrollBodyContent
      >
        <PedidosForm
          open
          handleClose={handleClose}
          handleToast={(message) => showFeedback(message)}
        />
      </Dialog>
    </>
  )
}

export default ModalConfiguration
